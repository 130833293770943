
const STORAGE_KEY = '_temp';

function getStorage(): Record<string, any> {
  const storedData = localStorage.getItem(STORAGE_KEY);
  return storedData ? JSON.parse(storedData) : {};
}

function setStorage(data: Record<string, any>): void {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
}

export function setItem(key: string, value: any): void {
  const currentData = getStorage();
  currentData[key] = value;
  setStorage(currentData);
}

export function getItem(key: string): any {
  const currentData = getStorage();
  return currentData[key] ?? null;
}

export function removeItem(key: string): void {
  const currentData = getStorage();
  if (currentData[key]) {
    delete currentData[key];
    setStorage(currentData);
  }
  if (Object.keys(currentData).length === 0) {
      clearStorage();
  }
}

export function clearStorage(): void {
  localStorage.removeItem(STORAGE_KEY);
}

