<template>
  <img v-bind="$attrs" decoding="async" :src="currentSource" :onerror="fallbackError" :alt="props.alt" :style="style" :loading="loading">
</template>

<script lang="ts" setup>
import {computed, defineProps, ref, StyleValue, watch} from 'vue';
import { CSSProperties } from '@vue/runtime-dom';
import useGetImages from "@/composable/controller/images/useGetImages";

const props = defineProps({
  src: { type: String, default: '' },
  alt: String,
  height: { type: [String, Number], default: '100%' },
  maxHeight: { type: [String, Number], default: '100%' },
  width: { type: [String, Number], default: '100%' },
  maxWidth: { type: [String, Number], default: '100%' },
  minHeight: { type: [String, Number], default: undefined },
  disableHeight: { type: Boolean, default: false },
  disableWidth: { type: Boolean, default: false },
  fallbackSrc: { type: String, default: 'noimg_l.jpg' },
  fit: { type: String as () => CSSProperties['objectFit'] },
  loading: {
    type: String as () => 'lazy' | 'eager',
    default: 'lazy',
  },
});

const { request: requestImage } = useGetImages();

const emit = defineEmits(['imageFound', 'imageNotFound']);

async function resolveSource(src: string) {
  if (src.startsWith('http')) return await requestImage(src);
  return new URL(`/src/assets/img/${src}`, import.meta.url).href;
}

const currentSource = ref("");

watch(()=> props.src, async ()=> {
  currentSource.value = await resolveSource(props.src);
  if (new URL(`/src/assets/img/noimg_l.jpg`, import.meta.url).href !== currentSource.value) {
    emit('imageFound');
  }
}, { immediate: true })


const style = computed((): StyleValue => ({
  maxWidth: typeof props.maxWidth === 'number' ? `${props.maxWidth}px` : props.maxWidth,
  maxHeight: typeof props.maxHeight === 'number' ? `${props.maxHeight}px` : props.maxHeight,
  minHeight: typeof props.minHeight === 'number' ? `${props.minHeight}px` : props.minHeight,
  height: props.disableHeight ? '' : typeof props.height === 'number' ? `${props.height}px` : props.height,
  width: props.disableWidth? '' : typeof props.width === 'number' ? `${props.width}px` : props.width,
  objectFit: props.fit ? props.fit : 'initial',
}));

async function fallbackError(this: HTMLImageElement) {
  if (props.fallbackSrc) {
    const src = await resolveSource(props.fallbackSrc);
    if (this.src !== src) this.src = src;
  } emit('imageNotFound');
}
</script>

<style scoped>

</style>
