<template>
  <component
    :class="{'circled': circled, 'circled-disabled': disabled}"
    :style="[circledStyle, widthStyle, heightStyle]"
    :is="icon"
    v-bind="attrs"
  />
</template>

<script setup lang="ts">
import { computed, CSSProperties, shallowRef, watch } from 'vue';
import { useTheme } from '@/composable/ui/useTheme';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  stroke: {
    type: String,
    required: false,
  },
  fill: {
    type: String,
    required: false,
  },
  justify: {
    type: String as () => 'start' | 'center' | 'end' | 'between' | 'around',
    default: 'start',
  },
  align: {
    type: String as () => 'start' | 'center' | 'end' | 'between' | 'around',
    default: 'start',
  },
  circled: {
    type: Boolean,
    default: false,
  },
  circledBackgroundColor: {
    type: String,
    default: 'white',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  width: {
    type: String,
    required: false,
  },
  height: {
    type: String,
    required: false,
  },
});

const theme = useTheme();

const icon = shallowRef(null);

const attrs = computed(() => {
  const result: { stroke?: string; fill?: string } = {};
  if (props.stroke) result.stroke = theme.resolveColor(props.stroke);
  if (props.fill) result.fill = theme.resolveColor(props.fill);
  return result;
});

const iconStyle = computed((): CSSProperties => ({
  'justify-content': props.justify,
  'align-items': props.align,
  position: 'static',
}));

const circledStyle = computed((): CSSProperties => ({
  backgroundColor: props.circled && !props.disabled ? props.circledBackgroundColor : '',
}));

const widthStyle = computed((): CSSProperties => {
  if (!props.width) {
    return {};
  }
  return { width: props.width };
});

const heightStyle = computed((): CSSProperties => {
  if (!props.height) {
    return {};
  }
  return { height: props.height };
});

watch(() => props.name, async () => {
  const res = await import(`../../assets/icons/icon-${props.name}.svg`).then((_) => _.default).catch((_) => null);
  if (typeof res !== 'string') icon.value = res;
}, { immediate: true });
</script>
<style scoped>
.circled {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid;
  border-radius: 70%;
  height: 22px;
  width: 22px;
  padding-left: 5px;
}

.circled-disabled {
    cursor: not-allowed !important;
    background-color: var(--white) !important;
    border-color: var(--white) !important;
    fill: var(--grayDarker) !important;
}

</style>
