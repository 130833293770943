import axios from 'axios';
import {DateTime} from 'luxon';
import {DoNetworkAPI} from '@/services/http/index';
import {
  ApartmentDetails,
  Estate,
  EstateDetail,
  EstateEquipments,
  EstateDetailImageType,
  EstateMapTypes,
  EstateStatus,
  EstateType,
  GarageType,
  Ownership,
  StateOfUse,
  UrbanPlanning,
  VacateType,
  HouseAndLandDetails,
  Access,
  BuildingRestriction,
  GarageAvailability,
  ConstructionCompletionType,
  MeasureType,
  LandAreaType,
  EstateOwnership,
  StructureType,
  Direction,
  LandShape,
  RoadType,
  TradeTransactionType, AdditionalPRType, RoomLayoutType, ManagementModeType, PrivateRoadType
} from '@/domain/estate.entity';
import NotFoundError from '@/domain/errors/NotFoundError';
import {Pref} from '@/domain/pref.entity';
import {City} from '@/domain/city.entity';
import {Town} from '@/domain/town.entity';
import {Station} from '@/domain/station.entity';
import {Rail} from '@/domain/rail.entity';
import {Shop} from "@/domain/fcShop.entity";
import {groupBy} from "lodash";
import useEstateTextFormatter from "@/composable/ui/useEstateFormatter";

// eslint-disable-next-line no-shadow
export enum EstateOrder {
  new = 'new_order',
  priceAsc = 'price_asc',
  priceDesc = 'price_desc',
  completionAsc = 'completion_asc',
  completionDesc = 'completion_desc',
  floorMansionAsc = 'floor_mansion_asc',
  floorMansionDesc = 'floor_mansion_desc',
  floorTotalAsc = 'floor_total_asc',
  floorTotalDesc = 'floor_total_asc',
  landSpaceAsc = 'land_space_asc',
  landSpaceDesc = 'land_space_desc',
}

export interface PrefCity {
  prefId: number;
  cityId: number;
}

type KanjiEstateType = 'マンション' | '一戸建て' | '収益物件' | '事業用物件' | '土地';

export type RoomTypes = '2K' | '2LDK' | '3K' | '3LDK' | '4K' | '4LDK' | '5K' | '5LDK';

type LayoutTypes = 'R' | 'K' | 'DK' | 'LK' | 'LDK' | 'K+S' | 'DK+S' | 'LK+S' | 'LDK+S';

export type ListEstateType =
  'favoriteEstate'
  | 'newEstate'
  | 'openHouseEstate'
  | 'openHouseEventEstate'
  | 'shinyabinEstate'
  | 'mypageEstate';

export type UrbanPlanningType = '市街化区域' | '調整区域' | '非線引区域' | '区域外' | '準都市区域'

export enum EstateStatusAPI {
  Published = 'PUBLISHED',
  NegotiatingThirdParty = 'NEGOTIATING_THIRD_PARTY',
  Negotiation = 'NEGOTIATION',
  Reserved = 'RESERVED',
  SoldThirdParty = 'SOLD_THIRD_PARTY',
  Sold = 'SOLD',
  Canceled = 'CANCELED',
  Reviewing = 'REVIEWING',
}

const toRoomLayout: Record<'01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | '09', RoomLayoutType> = {
  '01': RoomLayoutType.DK,
  '02': RoomLayoutType.LDK,
  '03': RoomLayoutType.R,
  '04': RoomLayoutType.K,
  '05': RoomLayoutType["K+S"],
  '06': RoomLayoutType["DK+S"],
  '07': RoomLayoutType.LK,
  '08': RoomLayoutType["LK+S"],
  '09': RoomLayoutType["LDK+S"],
}

const directionIdToDirection: Record<'01' | '02' | '03' | '04' | '05' | '06' | '07' | '08', Direction> = {
  '01': Direction.North,
  '02': Direction.South,
  '03': Direction.East,
  '04': Direction.West,
  '05': Direction.Northwest,
  '06': Direction.Northeast,
  '07': Direction.Southwest,
  '08': Direction.Southeast,
}

const toDirectionText: Record<Direction, string> = {
  [Direction.North]: '北',
  [Direction.South]: '南',
  [Direction.East]: '東',
  [Direction.West]: '西',
  [Direction.Northwest]: '北西',
  [Direction.Northeast]: '北東',
  [Direction.Southwest]: '南西',
  [Direction.Southeast]: '南東',
};

const managementTypeIdToManagementModeType: Record<'01' | '02' | '03', ManagementModeType> = {
  '01': ManagementModeType.SELF_MANAGEMENT,
  '02': ManagementModeType.PARTIAL_MANAGEMENT,
  '03': ManagementModeType.FULL_DELEGATION
}

const managementModeTypeToString: Record<ManagementModeType, string> = {
  [ManagementModeType.SELF_MANAGEMENT]: '自主管理',
  [ManagementModeType.PARTIAL_MANAGEMENT]: '一部管理',
  [ManagementModeType.FULL_DELEGATION]: '全部委託'
}

const roadTypeIdToRoadType: Record<'01' | '02', RoadType> = {
  '01': RoadType.Public,
  '02': RoadType.Private
}

const toRoadTypeText: Record<RoadType, string> = {
  [RoadType.Public]: '公道',
  [RoadType.Private]: '私道'
}

const privateRoadTypeIdToPrivateRoadType: Record<'01' | '02' | '03', PrivateRoadType> = {
  '01': PrivateRoadType.NONE,
  '02': PrivateRoadType.SEPARATE,
  '03': PrivateRoadType.INCLUDED
}

const privateRoadTypeToText: Record<PrivateRoadType, string> = {
  [PrivateRoadType.NONE]: '無',
  [PrivateRoadType.SEPARATE]: '別',
  [PrivateRoadType.INCLUDED]: '含'
}

type RealEstateTypeId  =
| '0101' | '0102' | '0103' | '0201' | '0202'
| '0301' | '0302' | '0303' | '0304' | '0305'
| '0401' | '0402' | '0403' | '0404' | '0405'
| '0406' | '0407' | '0408' | '0409' | '0410'
| '0411' | '0412' | '0413' | '0414' | '0415'
| '0416' | '0417' | '0418' | '0419' | '0420'
| '0421' | '0422' | '0423' | '0424';

const estateTypeIdToEstateType: Record<RealEstateTypeId, string> = {
  '0101': '売地',
  '0102': '売地借地権譲渡',
  '0103': '売地底地権譲渡',
  '0201': '戸建',
  '0202': 'テラスハウス',
  '0301': 'マンション',
  '0302': '公団',
  '0303': '公社',
  '0304': 'タウンハウス',
  '0305': 'リゾートマンション',
  '0401': '店舗',
  '0402': '店舗付住宅',
  '0403': '住宅付店舗',
  '0404': '事務所',
  '0405': '店舗・事務所',
  '0406': 'ビル',
  '0407': '工場',
  '0408': 'マンション',
  '0409': '倉庫',
  '0410': 'アパート',
  '0411': '寮',
  '0412': '旅館',
  '0413': 'ホテル',
  '0414': '別荘',
  '0415': 'リゾートマンション',
  '0416': 'モーテル',
  '0417': '医院',
  '0418': 'ガソリンスタンド',
  '0419': '特殊浴場',
  '0420': 'サウナ',
  '0421': '保養所',
  '0422': '作業所',
  '0423': '駐車場',
  '0424': 'その他'
};

export interface ListDoNetworkEstateForm {
  estateIds?: number[],
  estateType?: EstateType,
  condition?: 'new' | 'used'
  prefCities?: PrefCity[]
  order?: EstateOrder
  openHouseId?: number
  ohEventId?: number
  ohEventTypeId?: number
  typeId?: ListEstateType
  fcShopId?: number
  sendDate?: Date
  limit?: number
  page?: number
  onlyMe?: boolean
  roomTypes?: (RoomTypes)[]
  minPrice?: number
  maxPrice?: number
  stationDistance?: number
  landMinArea?: number
  landMaxArea?: number
  squareMeterPrice?: number
  buildingMinArea?: number
  buildingMaxArea?: number
  propertyConstructMinArea?: number
  propertyConstructMaxArea?: number
  floors?: number
  years?: number
  searchForTypes?: {
    land: boolean,
    newHouse: boolean,
    usedHouse: boolean,
    newApartment: boolean,
    usedApartment: boolean,
    business: boolean,
  }
  railId?: number
  stationId?: number | number[]
  parking?: number
}

interface GetEstateDetailImageForm {
  estateId: number
}

export interface EstateDetailImage {
  path: string,
  pathL: string,
  estateId: number,
  divisionGroupId: EstateDetailImageType,
  divisionName: string,
  divisionId?: EstateMapTypes,
  pr: string,
  localDistance: string,
  localWalkMinutes: string,
  outputOrder: string,
  sellerColumn: boolean,
  title: string,
  alt?: string,
  referencePoint: {
    walkingMinutes: number,
    distanceMeters: number,
  },
}

export interface NearbPlace {
    title: string,
    referencePoint: {
    walkingMinutes: { min: number, max: number},
    distanceMeters: { min: number, max: number}
  },
}

export interface EstateListImage {
  path: string,
}

export interface LandLotDetails {
  estateId: number,
  shop: {
    id: number,
  },
  newUsedTypeId: number,
  estateTypeId: number,
  packagePartTypeId: number,
  businessFlag: boolean,
  estateTypeDisp: string,
  icon: string,
  roomLayoutImage: string,
  exteriorImage1: string,
  estatePriceFormatted: string,
  estatePrice: string,
  priceFormatted: string,
  priceFormattedUnit: string,
  roomLayout: string,
  floorSpaceTotal: string,
  landSpaceM2Public: string,
  floorSpaceMansion: string,
}

export interface OhEventEstateDetail {
  estateId: number,
  ohEventId: number,
  ohEventTypeId: number,
  ohEventType: string,
  ohEventTitle: string,
  ohEventStatus: string,
  holidayPgFlag: number,
  eventImage1: string,
  mapImage: string,
  ohAddress: string,
  ohDate: string,
  ohTime: string,
  ohEventApproach: string,
  sortNo: string,
  pr: string,
  biko: string
}

export interface PropertyLayoutDetails {
  propertyLayout: string,
  floorSpace: string,
  roomType: string | null,
  outputOrder: string,
}

export interface EstateDetailOuterImage {
  imageServiceId: number,
  path: string,
  tag: string,
  imageServiceTypeId: number,
  divisionId: number,
  divisionName: string,
  divisionOrder: string,
  pr: string,
  outputOrder: string,
}

export type EstateListNewsResponse = {
  fcId: number,
  shop: {
    name: string,
  },
  estateId: number,
  status: EstateStatus,
  estateNameWeb: string,
  roomLayoutImage: string,
  exteriorImage1: string,
  access: {
    railName: string,
    stationName: string,
    stationAccess: string,
  }[],
  priceFormatted: string,
  price: number,
  floorSpaceTotal: string,
  floorSpaceMansion: string,
  landSpaceM2Public: string,
  elementarySchool: string,
  juniorHighSchool: string,
}

export interface EstateDetailForm {
  estateId: number,
  urlType: string,
}

export interface FavoriteEstateForm {
  id: number,
}

export interface UnfavoriteEstateForm {
  id: number,
}

export interface ListDoNetworkEstateResponse {
  FC_ID: string,
  FC_SHOP_ID: string,
  ESTATE_ID: string,
  PHOTO_FLAG: string,
  PRICE_DOWN_FLAG: string,
  PRICE_DOWN_REGIST_DATETIME: string | null,
  CHECK_PARKING_FLAG: string,
  REFORM_EXECUTION_ID: string | null,
  RENOVATION_FLAG: string,
  PREF_ID: string,
  PREF_NAME: string,
  PREF_ALPHABET: string,
  CITY_ID: string,
  CITY_NAME: string,
  TOWN_ID: string,
  TOWN_NAME: string,
  ESTATE_ADDRESS: string,
  NEW_USED_TYPE_NAME: string | null,
  NEW_USED_TYPE_ID: StateOfUse | null,
  ESTATE_TYPE_NAME: KanjiEstateType,
  ESTATE_TYPE_ID: string,
  ESTATE_TYPE_ID1: string,
  ESTATE_NAME: string,
  ESTATE_NAME_WEB: string, // TODO Remove this field
  SHUEKI_FLAG: string,
  ESTATE_USEA: string,
  ESTATE_USEB: string,
  YEARLY_INCOME: string | null,
  YIELD_RATE: string | null,
  ELEMENTARYSCHOOL_NAME: string,
  ELEMENTARYSCHOOL_DISTANCE: string,
  JUNIORHIGHSCHOOL_NAME: string,
  structureName: string,
  ESTATE_PRICE1: string,
  ESTATE_PRICE: string,
  HOUSE_ESTATE_PRICE_FORMAT?: string,
  HOUSE_ESTATE_PRICE_FORMAT_UNIT?: string,
  mr1rn: string | null,
  ms1sn: string | null,
  BUS_MINUTES1: string | null,
  BUS_STOP_NAME1: string | null,
  WALK_MINUTES1: string | null,
  WALK_MINUTES: string,
  mr2rn: string | null,
  ms2sn: string | null,
  BUS_MINUTES2: string | null,
  BUS_STOP_NAME2: string | null,
  WALK_MINUTES2: string | null,
  mr3rn: string | null,
  ms3sn: string | null,
  BUS_MINUTES3: string | null,
  BUS_STOP_NAME3: string | null,
  WALK_MINUTES3: string | null,
  TRAFFIC_ETC: string,
  WALK_MINUTES_ETC: string | null,
  YUSEN_KOTSU_KBN_1: string | null,
  YUSEN_KOTSU_KBN_2: string | null,
  YUSEN_KOTSU_KBN_3: string | null,
  BUS_MINUTES: string | null,
  FLYER_CATCH_COPY1: string,
  FLYER_CATCH_COPY2: string,
  FLYER_CATCH_COPY3: string,
  FLYER_CATCH_COPY4: string,
  ROOM_LAYOUT_IMAGE_NAME: string,
  EXTERIOR_IMAGE_NAME1: string,
  EXTERIOR_IMAGE_NAME2: string,
  EXTERIOR_IMAGE_NAME3: string,
  EXTERIOR_IMAGE_NAME4: string,
  EXTERIOR_IMAGE_NAME5: string,
  EXTERIOR_IMAGE_NAME6: string,
  EXTERIOR_IMAGE_NAME7: string,
  EXTERIOR_IMAGE_NAME8: string,
  EXTERIOR_IMAGE_NAME9: string,
  EXTERIOR_IMAGE_NAME10: string,
  FLOOR_SPACE_MANSION: string,
  ROOM_QUANTITY: null | string,
  ROOM_LAYOUT_TYPE_NAME: string,
  FLOOR_ABOVE_QUANTITY: `${number}階` | `${number}`,
  FLOOR: string | null,
  COMPLETION_TYPE_NAME: string | null,
  COMPLETION_YEAR: string,
  COMPLETION_MONTH: string,
  CHIKUNENGETSU: string,
  CHIKUNENGETSU1: string,
  FLOOR_SPACE_TOTAL: string,
  LAND_SPACE_M2_PUBLIC: string,
  TSUBO: string,
  BUILDING_COVERAGE: string,
  PLOT_RATIO: string,
  TSUBO_TANKA: string,
  REGIST_DATETIME: string,
  USE_DISTRICT_NAME: string,
  BUILDING_RESTRICT_TYPE_NAME: string,
  LAND_RIGHT_TYPE_NAME: string,
  SHINCHAKU: string,
  PACKAGE_PART_TYPE_ID: string | null,
  REAL_ESTATE_TYPE_NAME: string,
  OUTER_MOVIE_URL: string | null,
  CATCH: string,
  MV_IMAGE_ID: string,
  PANORAMA_IMAGE_ID: string,
  VR_PREVIEW_FLAG: string,
  OH_EVENT_ID: string | null,
  FRAME_NO: string | null,
  LOW_MODIFY_DATETIME: string,
  MODIFY_DATETIME: string,
  PARKING_FLAG: string,
  PARKING_2_FLAG: string,
  PARKING_3_FLAG: string,
  PARKING_MINI_FLAG: string,
  GARAGE_TYPE_ID: string | null,
  OPEN_PARKING_FLAG: string,
  PARKING_LOT_FLAG: string,
  BOX_1_PARKING_FLAG: string,
  USER_PR_HP: string,
  USER_ID: string,
  USER_NAME: string,
  USER_NAME_ALTERNATIVE: string,
  PROFILE_IMAGE: string,
  ESTATE_STATUS_ID: string,
  HP_FLAG: string,
  PORTAL_ESTATE_NAME: string,
  MYPAGE_FLAG: string,
  latitude: string,
  longitude: string,
  STATION_ACCESS1: string | null,
  STATION_ACCESS2: string | null,
  STATION_ACCESS3: string | null,

  // TODO so adicionei para fazer o deploy
  ESTATE_TYPE_DETAIL: string
  BALCONY: string
  MAIN_LIGHTING: string
  SPACE_DETAIL: string
  STRUCTURE: string
  FLOOR_QUANTITY: string
  building: {
    completionType: ConstructionCompletionType | null,
    builtAt: string | null,
  },

  land: {
    area: number,
    publicAreaType: LandAreaType | null,
    restriction: {
      type: BuildingRestriction | null,
      other: string,
      suumo: string, // Suumo it is a site that sell houses in Japan
    } | null,
    wallMeasureMethod: MeasureType | null,
  },
  garages: {
    car: {
      availability: GarageAvailability,
      price: number | { min: number, max: number } | null,
      distance: number,
      quantity: { normal: number, mini: number } | null,
    } | null,
  },

  surroundings: {
    trainStations: Array<{
      id: number,
      name: string,
      rail: { id: number; name: string; } | null,
      access: Access | null,
    } | {
      name: string,
      rail: { name: string } | null,
      access: Access | null,
    }>
  }

  images: {
    layout: string[],
    exterior: string[]
  }

  areas: {
    floor: {
      area: { value: number, measureType: MeasureType | null },
      buildAreaType: LandAreaType | null,
    }
  }

  rights: {
    ownership: EstateOwnership | null,
  }

  sellContract: {
    sellerId: number | null,
    sellerPassword: string | null,
    sellEstateId: number | null,
    transactionTypeId: TradeTransactionType | null,
    sellerRegisterDate: string | null,
    mediateContractRegisterDate: string | null,
    finalMediationContractDate: string | null,
    renewalMediationContractDate: string | null,
  },
}

interface EstateListForm {
  ids?: number[],
  searchForTypes: {
    land: boolean,
    newHouse: boolean,
    oldHouse: boolean,
    newApartment: boolean,
    oldApartment: boolean,
    business: boolean,
    rentProperty: boolean,
  },
  minPrice?: number,
  maxPrice?: number,
  prefId?: number,
  cityId?: number,
  stationId?: number,
  stationDistance?: undefined | number,
  roomTypes?: (RoomTypes)[],
  landMinArea?: number,
  landMaxArea?: number,
  buildingMinArea?: number,
  buildingMaxArea?: number,
  propertyConstructMinArea?: number,
  propertyConstructMaxArea?: number,
  years?: number,
  parkingSpace?: number,
}

interface EstimateEstatePriceForm {
  estateType: EstateType,
  zipCode: string,
  prefId: number,
  cityId: number,
  townId: number,
  address: string,
  isResident: boolean,
  builtAt: Date | undefined,
  landArea: number,
  apartmentArea: number,
  buildingArea: number,
  structure: StructureType | undefined,
  room: {
    quantity: number,
    layout: LayoutTypes,
  } | undefined,
  floorQuantity: number | undefined,
  parkingQuantity: number | undefined,
  estateDirection: Direction | undefined,
  landShape: LandShape | undefined,
  mainAccessRoad: {
    direction: Direction,
    type: RoadType,
  } | undefined,
  roadWidth: number | undefined,
  entranceWidth: number | undefined,
}

// eslint-disable-next-line no-shadow
export enum SellType {
  SellAtHighPrice = 1,
  SellQuickly = 2,
  SellWhileLiveInProperty = 3,
}

export interface RegisterCustomerEstateToSellForm {
  estateType: EstateType,
  zipCode: string,
  prefId: number,
  cityId: number,
  townId: number,
  address: string,
  isResident: boolean,
  builtAt: Date | undefined,
  landArea: number,
  apartmentArea: number,
  buildingArea: number,
  structure: StructureType | undefined,
  room: {
    quantity: number,
    layout: LayoutTypes,
  } | undefined,
  floorQuantity: number | undefined,
  parkingQuantity: number | undefined,
  estateDirection: Direction | undefined,
  landShape: LandShape | undefined,
  mainAccessRoad: {
    direction: Direction,
    type: RoadType,
  } | undefined,
  roadWidth: number | undefined,
  entranceWidth: number | undefined,
  highSellPriceMax: number,
  highSellPriceMin: number
  quickSellPriceMax: number,
  quickSellPriceMin: number,
  sellType: SellType | undefined,
  shopIds: number[]
}

export interface UpdateCustomerEstateToSellForm {
  id: number,
  estateType: EstateType,
  zipCode: string,
  prefId: number,
  cityId: number,
  townId: number,
  address: string,
  isResident: boolean,
  builtAt: Date | undefined,
  landArea: number,
  apartmentArea: number,
  buildingArea: number,
  structure: StructureType | undefined,
  room: {
    quantity: number,
    layout: LayoutTypes,
  } | undefined,
  floorQuantity: number | undefined,
  parkingQuantity: number | undefined,
  estateDirection: Direction | undefined,
  landShape: LandShape | undefined,
  mainAccessRoad: {
    direction: Direction,
    type: RoadType,
  } | undefined,
  roadWidth: number | undefined,
  entranceWidth: number | undefined,
  highSellPriceMax: number,
  highSellPriceMin: number
  quickSellPriceMax: number,
  quickSellPriceMin: number,
  isAvailableToSell: 1 | 0,
  sellType: SellType | undefined,
  shopIds: number[]
}

interface EstimateEstatePriceResult {
  highSellPrices: {
    min: number,
    max: number,
  },
  quickSellPrices: {
    min: number,
    max: number,
  },
  highSellPricesFormatted: {
    min: string,
    max: string,
  },
  quickSellPricesFormatted: {
    min: string,
    max: string,
  },
  highSellPricesUnit: {
    min: string,
    max: string,
  },
  quickSellPricesUnit: {
    min: string,
    max: string,
  },
  coordinates: {
    lat: number,
    lng: number,
  },
}

type RomajiEstateType = 'mansion' | 'ikkodate' | 'shueki' | 'tochi';

const romajiEstateTypeMap: Record<EstateType, RomajiEstateType | ''> = {
  [EstateType.apartment]: 'mansion',
  [EstateType.house]: 'ikkodate',
  [EstateType.business]: 'shueki',
  [EstateType.land]: 'tochi',
};

const urbanPlanningMap: Record<UrbanPlanningType, UrbanPlanning> = {
  市街化区域: UrbanPlanning.urbanArea,
  調整区域: UrbanPlanning.regulatedArea,
  非線引区域: UrbanPlanning.unplannedArea,
  区域外: UrbanPlanning.outsideTheArea,
  準都市区域: UrbanPlanning.semiUrbanArea,
};

const kanjiToEstateTypeMap: Record<KanjiEstateType, EstateType> = {
  マンション: EstateType.apartment,
  一戸建て: EstateType.house,
  収益物件: EstateType.business,
  事業用物件: EstateType.business,
  土地: EstateType.land,
};

const stringToMeasureMethod: Record<string, MeasureType> = {
  '01': MeasureType.BetweenCenterWall,
  '02': MeasureType.BetweenSurfaceWall,
};

const stringToEstateOwnership: Record<string, EstateOwnership> = {
  FULL: EstateOwnership.Full,
  PARTIAL: EstateOwnership.Partial,
};

export interface GetEstatesListAreasResult {
  estateCounts: {
    homepage: number,
    myPage: number,
  },
  prefectures: Pref[],
  cities: City[],
  towns: Town[],
  estatePrice: { min: number, max: number },
  landArea: { min: number, max: number },
  floorArea: { min: number, max: number },
  floorAreaApartment: { min: number, max: number },
}

export type GetEstatesListStationsResult = (Station & { rails: Rail[] })[];

export enum EstateListRoomTypes {
  Kitchen = 'K',
  DiningKitchen = 'DK', // DK
  LivingDiningKitchen = 'LDK',
  LivingDiningKitchenAndStorage = 'LDK+S',
}

export interface EstateListQuantityOfRoomTypes {
  quantity: number,
  layout: EstateListRoomTypes
}

export interface ListEstateForm {
  estateIds?: number[],
  towns?: Town[],
  prefectures?: Pref[],
  estateType?: EstateType,
  condition?: 'new' | 'used'
  prefCities?: PrefCity[]
  order?: EstateOrder
  openHouseId?: number
  ohEventId?: number
  ohEventTypeId?: number
  typeId?: ListEstateType
  fcShopId?: number
  sendDate?: Date
  limit?: number
  page?: number
  onlyMe?: boolean
  room?: EstateListQuantityOfRoomTypes[]
  minPrice?: number
  maxPrice?: number
  stationDistance?: number
  landMinArea?: number
  landMaxArea?: number
  squareMeterPrice?: number
  buildingMinArea?: number
  buildingMaxArea?: number
  propertyConstructMinArea?: number
  propertyConstructMaxArea?: number
  floors?: number
  years?: number
  searchForTypes?: {
    land: boolean,
    newHouse: boolean,
    usedHouse: boolean,
    newApartment: boolean,
    usedApartment: boolean,
    business: boolean,
  }
  stations?: Array<{ id: number, railId: number | undefined }>,
  parking?: number,
  isRentable?: boolean,
}

export type EstateTypeGroup = 'HOUSE' | 'APARTMENT' | 'LAND';

export enum StructureMaterialApi {
  Wooden = 'WOODEN',
  Block = 'BLOCK',
  Steel = 'STEEL',
  RC = 'RC',
  SRC = 'SRC',
  LightweightSteel = 'LIGHT_WEIGHT_STEEL',
}

export enum RoadTypeApi { // 道路向
  PublicRoad = 'PUBLIC_ROAD', // 公道
  Driveway = 'DRIVEWAY', // 私道
}

export interface GetCustomerEstateToSellListApiResponse {
  id: number,
  estateType: EstateTypeGroup,
  zipCode: string | null,
  pref: {
    id: number;
    name: string;
    kanaName: string;
  } | null,
  city: {
    id: number;
    name: string;
    kanaName: string;
    prefId: number;
  } | null,
  town: {
    id: number,
    name: string,
    prefId: number,
    cityId: number,
    zipCode: string
  } | null,
  address: string | null,
  isResident: boolean,
  builtAt: Date | null,
  landArea: number | null,
  apartmentArea: number | null,
  buildingArea: number | null,
  structure: StructureMaterialApi | null,
  room: {
    quantity: number,
    layout: LayoutTypes,
  } | null,
  floorQuantity: number | null,
  parkingQuantity: number | null,
  estateDirection: Direction | null,
  landShape: LandShape | null,
  mainAccessRoad: {
    direction: Direction,
    type: RoadTypeApi,
  } | null,
  roadWidth: number | null,
  entranceWidth: number | null,
  highSellPrices: { min: number, max: number },
  quickSellPrices: { min: number, max: number },
  highSellPricesFormatted: { min: string, max: string },
  quickSellPricesFormatted: { min: string, max: string },
  highSellPricesUnit: { min: string, max: string },
  quickSellPricesUnit: { min: string, max: string },
  sellType: SellType,
  shops: {
    id: number;
    franchiseId: number;
    name: string;
    email: string | null;
    url: string | null;
    licenceNumber: string | null;
    tel: {
      main: string,
      freeDial: string | null,
    };
    fax: string;
    address: {
      pref: Pref & { kanaName: string },
      city: City & { kanaName: string },
      street: string | null,
      zipCode: string | null,
    };
  }[],
}

export interface GetCustomerEstateToSellListResult {
  id: number,
  estateType: EstateType,
  zipCode: string | null,
  pref: Pref | null,
  city: City | null,
  town: Town | null,
  address: string | null,
  isResident: boolean,
  builtAt: Date | null,
  landArea: number | null,
  apartmentArea: number | null,
  buildingArea: number | null,
  structure: StructureType | null,
  room: {
    quantity: number,
    layout: LayoutTypes,
  } | null,
  floorQuantity: number | null,
  parkingQuantity: number | null,
  estateDirection: Direction | null,
  landShape: LandShape | null,
  mainAccessRoad: {
    direction: Direction,
    type: RoadType,
  } | null,
  roadWidth: number | null,
  entranceWidth: number | null,
  highSellPrices: { min: number, max: number },
  quickSellPrices: { min: number, max: number },
  highSellPricesFormatted: { min: string, max: string },
  quickSellPricesFormatted: { min: string, max: string },
  highSellPricesUnit: { min: string, max: string },
  quickSellPricesUnit: { min: string, max: string },
  sellType: SellType,
  shops: Shop[],
}

const toEstateType: Record<EstateTypeGroup, Exclude<EstateType, EstateType.business>> = {
  HOUSE: EstateType.house,
  APARTMENT: EstateType.apartment,
  LAND: EstateType.land,
}

const toStructureType: Record<StructureMaterialApi, StructureType> = {
  WOODEN: StructureType.Wood,
  BLOCK: StructureType.Blocks,
  STEEL: StructureType.Steel,
  RC: StructureType.ReinforcedConcrete,
  SRC: StructureType.SteelReinforcedConcrete,
  LIGHT_WEIGHT_STEEL: StructureType.LightWeightSteel,
};

const toRoadType: Record<RoadTypeApi, RoadType> = {
  PUBLIC_ROAD: RoadType.Public,
  DRIVEWAY: RoadType.Private,
}


export interface NearbyEstatesInfo {
  estateId: number,
  status: EstateStatusAPI,
  address: {
    prefecture: {
      id: number,
      name: string,
    },
    city: {
      id: number,
      name: string,
      prefId: number
    },
    town: {
      id: string,
      name: string,
      cityId: number
      prefId: number
    },
    street: string,
  },
  landArea: number,
  apartmentArea: number,
  price: number,
  buildingCoverageRatio: string,
  buildingPlotAreaRatio: string,
  floorSpaceTotal: number,
  constructAt: Date,
  distance: number,
}

interface EstateSentToSellAnalyticsHttpResponse {
  housedocom: {
    pv: {
      total: number,
      lastWeek: number,
      lastTwoWeeks: number,
      weekViews: {
        date: Date[],
        value: number[],
      },
      monthViews: {
        date: Date[],
        value: number[],
      }
    },
    cv: {
      total: number,
      lastWeek: number,
      lastTwoWeeks: number,
      weekViews: {
        date: Date[],
        value: number[],
      },
      monthViews: {
        date: Date[],
        value: number[],
      }
    }
  },
  dosearch: {
    pv: {
      total: number,
      lastWeek: number,
      lastTwoWeeks: number,
      weekViews: {
        date: Date[],
        value: number[],
      },
      monthViews: {
        date: Date[],
        value: number[],
      }
    },
    cv: {
      total: number,
      lastWeek: number,
      lastTwoWeeks: number,
      weekViews: {
        date: Date[],
        value: number[],
      },
      monthViews: {
        date: Date[],
        value: number[],
      }
    }
  },
  mypage: {
    pv: {
      total: number,
      lastWeek: number,
      lastTwoWeeks: number,
      weekViews: {
        date: Date[],
        value: number[],
      },
      monthViews: {
        date: Date[],
        value: number[],
      }
    },
    cv: {
      total: number,
      lastWeek: number,
      lastTwoWeeks: number,
      weekViews: {
        date: Date[],
        value: number[],
      },
      monthViews: {
        date: Date[],
        value: number[],
      }
    }
  },
  nearbyEstatesInfo: NearbyEstatesInfo[]
}

const textFormatter = useEstateTextFormatter();

function convertListDoNetworkEstateResponse(estate: ListDoNetworkEstateResponse): Estate {
  const buildAtStr = estate.building.builtAt ? estate.building.builtAt.split('-') : [];
  return {
    id: Number(estate.ESTATE_ID),
    catch: estate.CATCH,
    type: kanjiToEstateTypeMap[estate.ESTATE_TYPE_NAME],
    elementarySchool: estate.ELEMENTARYSCHOOL_NAME
      ? {distance: Number(estate.ELEMENTARYSCHOOL_DISTANCE), name: estate.ELEMENTARYSCHOOL_NAME}
      : undefined,
    juniorHighSchool: {name: estate.JUNIORHIGHSCHOOL_NAME},
    landPublicM2Space: estate.LAND_SPACE_M2_PUBLIC,
    name: estate.ESTATE_NAME,
    portalName: estate.PORTAL_ESTATE_NAME,
    price: Number(estate.ESTATE_PRICE),
    formattedPrice: estate.HOUSE_ESTATE_PRICE_FORMAT,
    formattedPriceUnit: estate.HOUSE_ESTATE_PRICE_FORMAT_UNIT,
    priceDownFlag: estate.PRICE_DOWN_FLAG === '1',
    room: {layoutName: estate.ROOM_LAYOUT_TYPE_NAME, quantity: Number(estate.ROOM_QUANTITY)},
    statusId: estate.ESTATE_STATUS_ID as EstateStatus,
    realTypeName: estate.REAL_ESTATE_TYPE_NAME,
    newUsedTypeName: estate.NEW_USED_TYPE_NAME ? String(estate.NEW_USED_TYPE_NAME) : '',
    stateOfUse: estate.NEW_USED_TYPE_ID ? estate.NEW_USED_TYPE_ID : null,
    ad: {
      name: estate.ESTATE_NAME,
      web: estate.ESTATE_NAME_WEB, // TODO Remove this field
    },
    structureName: estate.structureName,
    floor: Number(estate.FLOOR),
    buildingTotalFloor: estate.FLOOR_ABOVE_QUANTITY.includes('階')
      ? Number(estate.FLOOR_ABOVE_QUANTITY.replace('階', ''))
      : Number(estate.FLOOR_ABOVE_QUANTITY),
    rentFlag: estate.SHUEKI_FLAG === '1',
    trafficEtc: estate.TRAFFIC_ETC,
    walkMinutesEtc: estate.WALK_MINUTES_ETC || '',
    address: estate.ESTATE_ADDRESS,
    floorString: estate.FLOOR ? estate.FLOOR : '',
    totalFloorSpace: estate.FLOOR_SPACE_TOTAL,
    totalMansionSpace: estate.FLOOR_SPACE_MANSION,
    totalPublicLandSpace: estate.LAND_SPACE_M2_PUBLIC,
    totalSpace: Number(estate.FLOOR_SPACE_TOTAL),
    floorSpaceMansion: Number(estate.FLOOR_SPACE_MANSION),
    tsubo: Number(estate.TSUBO),
    tsuboTanka: Number(estate.TSUBO_TANKA),
    estatesUses: [estate.ESTATE_USEA, estate.ESTATE_USEB].filter((use) => !!use),
    buildingRestrictTypeName: estate.BUILDING_RESTRICT_TYPE_NAME,
    businessTypeName: estate.REAL_ESTATE_TYPE_NAME,
    yieldRate: Number(estate.YIELD_RATE),
    buildingAreaRadio: estate.BUILDING_COVERAGE,
    utilAreaRadio: estate.PLOT_RATIO,
    district: {
      name: estate.USE_DISTRICT_NAME || '',
      date: estate.CHIKUNENGETSU ? new Date(...estate.CHIKUNENGETSU.split('/') as ConstructorParameters<typeof Date>) : undefined,
    },
    isSold: estate.ESTATE_STATUS_ID === EstateStatus.soldThirdParty || estate.ESTATE_STATUS_ID === EstateStatus.sold,
    lowModifyAt: new Date(estate.LOW_MODIFY_DATETIME),
    completionYear: Number(estate.COMPLETION_YEAR),
    completionMonth: Number(estate.COMPLETION_MONTH),
    isInHomepage: Boolean(Number(estate.HP_FLAG)),

    createdAt: new Date(estate.REGIST_DATETIME),

    town: {name: estate.TOWN_NAME},
    user: {name: estate.USER_NAME},
    fc: {id: Number(estate.FC_ID)},
    fcShop: {id: Number(estate.FC_SHOP_ID)},
    pref: {id: Number(estate.PREF_ID), name: estate.PREF_NAME},
    city: {id: Number(estate.CITY_ID), name: estate.CITY_NAME},
    access: [{
      stationName: (estate.ms1sn || '').replace('駅', ''),
      railName: (estate.mr1rn || ''),
      busMinutes: estate.BUS_MINUTES1 ? Number(estate.BUS_MINUTES1.match(/\d+/)) : 0,
      busStopName: estate.BUS_STOP_NAME1 || '',
      walkMinutes: estate.WALK_MINUTES1 ? Number(estate.WALK_MINUTES1.match(/\d+/)) : 0,
      priority: estate.YUSEN_KOTSU_KBN_1 ? Number(estate.YUSEN_KOTSU_KBN_1) : null,
      stationAccess: estate.STATION_ACCESS1 ? estate.STATION_ACCESS1 : '',
    }, {
      stationName: (estate.ms2sn || '').replace('駅', ''),
      railName: (estate.mr2rn || ''),
      busMinutes: estate.BUS_MINUTES2 ? Number(estate.BUS_MINUTES2.match(/\d+/)) : 0,
      busStopName: estate.BUS_STOP_NAME2 || '',
      walkMinutes: estate.WALK_MINUTES2 ? Number(estate.WALK_MINUTES2.match(/\d+/)) : 0,
      priority: estate.YUSEN_KOTSU_KBN_2 ? Number(estate.YUSEN_KOTSU_KBN_2) : null,
      stationAccess: estate.STATION_ACCESS2 ? estate.STATION_ACCESS2 : '',
    }, {
      stationName: (estate.ms3sn || '').replace('駅', ''),
      railName: (estate.mr3rn || ''),
      busMinutes: estate.BUS_MINUTES3 ? Number(estate.BUS_MINUTES3.match(/\d+/)) : 0,
      busStopName: estate.BUS_STOP_NAME3 || '',
      walkMinutes: estate.WALK_MINUTES3 ? Number(estate.WALK_MINUTES3.match(/\d+/)) : 0,
      priority: estate.YUSEN_KOTSU_KBN_3 ? Number(estate.YUSEN_KOTSU_KBN_3) : null,
      stationAccess: estate.STATION_ACCESS3 ? estate.STATION_ACCESS3 : '',
    }].filter((item) => item.railName && item.stationName),
    accessEtc: {
      walkMinutes: Number.isNaN(Number(estate.WALK_MINUTES_ETC)) ? 0 : Number(estate.WALK_MINUTES_ETC),
      traffic: estate.TRAFFIC_ETC,
    },
    salesman: estate.USER_ID || estate.USER_NAME || estate.USER_PR_HP ? {
      id: Number(estate.USER_ID) || 0,
      name: estate.USER_NAME || '',
      prHp: estate.USER_PR_HP || '',
      profileImage: estate.PROFILE_IMAGE || '',
    } : undefined,

    // TODO
    estateTypeDetail: estate.ESTATE_TYPE_DETAIL,
    balcony: estate.BALCONY,
    mainLighting: estate.MAIN_LIGHTING,
    spaceDetail: estate.SPACE_DETAIL,
    structure: estate.STRUCTURE,
    floorQuantityString: estate.FLOOR_QUANTITY ? estate.FLOOR_QUANTITY : '',
    building: {
      completionType: estate.building?.completionType ? estate.building.completionType : null,
      builtAt: buildAtStr.length ? new Date(Number(buildAtStr[0]), Number(buildAtStr[1]) - 1) : null,
    },

    land: {
      area: estate.land.area,
      publicAreaType: estate.land.publicAreaType ? estate.land.publicAreaType : null,
      restriction: estate.land?.restriction ? {
        type: estate.land.restriction.type,
        other: estate.land?.restriction.other || '',
        suumo: estate.land?.restriction.other || '', // Suumo it is a site that sell houses in Japan
      } : null,
    },
    garages: {
      car: estate.garages.car ? {
        availability: estate.garages.car.availability,
        price: estate.garages.car.price,
        distance: estate.garages.car.distance,
        quantity: estate.garages.car.quantity,
      } : null,
    },
    images: {
      layout: estate.images.layout,
      exterior: estate.images.exterior,
    },
    surroundings: estate.surroundings,
    areas: estate.areas,
    rights: {
      ownership: estate.rights.ownership ? stringToEstateOwnership[estate.rights.ownership] : null,
    },
    sellContract: {
      sellerId: estate.sellContract.sellerId,
      sellerPassword: estate.sellContract.sellerPassword,
      sellEstateId: estate.sellContract.sellEstateId,
      transactionTypeId: estate.sellContract.transactionTypeId,
      sellerRegisterDate: estate.sellContract.sellerRegisterDate ? new Date(estate.sellContract.sellerRegisterDate) : null,
      mediateContractRegisterDate: estate.sellContract.mediateContractRegisterDate ? new Date(estate.sellContract.mediateContractRegisterDate) : null,
      finalMediationContractDate: estate.sellContract.finalMediationContractDate ? new Date(estate.sellContract.finalMediationContractDate) : null,
      renewalMediationContractDate: estate.sellContract.renewalMediationContractDate ? new Date(estate.sellContract.renewalMediationContractDate) : null,
    },
  };
}

function formatPrefCities(prefCities: PrefCity[]) {
  const strPrefCities = prefCities
    .filter((prefCity) => prefCity.prefId && prefCity.cityId)
    .map((prefCity) => `${String(prefCity.prefId).padStart(2, '0')}${String(prefCity.cityId).padStart(3, '0')}`);
  return [...new Set(strPrefCities)].join(',');
}

function formatStationRails(stations: { id: number, railId?: number }[]) {
  const strStationRails = stations
    .map((station) => `${String(station.id)}${station.railId ? `,${String(station.railId)}` : ''}`);
  return strStationRails;
}

function formatLimit(params: { limit: number | undefined, page: number | undefined }) {
  let limit: string | undefined;
  if (params.limit && params.limit < 0) {
    limit = 'all';
  } else if (params.page !== undefined && params.limit !== undefined) {
    limit = `${(params.page - 1) * params.limit},${params.limit}`;
  }
  return limit;
}

interface InsuranceItems {
  earthquake: string;
  earthquakeBiko: string;
  policy: string;
  policyCover: string;
  policyCoverDetail: string;
  warranty: string;
  warrantyCover: string;
  warrantyCoverDetail: string;
  invoice: string;
}

function formatInsurances(items: InsuranceItems): string[] {
  const formattedItems = Object.values(items).map(item => item.trim());
  formattedItems.forEach((item, idx) => {
    if (item.endsWith('・')) {
      formattedItems[idx] = formattedItems[idx].slice(0, -1);
    }
  });
  return formattedItems;
}

export interface PaginateEstate {
  total: number;
  estates: Estate[];
}

interface EstateListStationsRes {
  rails: {
    id: `${number}`,
    name: string
  }[],
  stations: {
    id: `${number}`
    railId: `${number}`
    name: string,
    prefId: `${number}`
    cityId: `${number}`
  }[],
}

export default {
  async get(params: ListDoNetworkEstateForm): Promise<Estate[]> {
    const response = await axios.get('/doNetwork/getEstateListForMypage', {
      params: {
        estateTypes: params.condition ? `${params.condition}_${romajiEstateTypeMap[params.estateType as EstateType]}`
          : romajiEstateTypeMap[params.estateType as EstateType],
        prefCityIds: params.prefCities ? formatPrefCities(params.prefCities) : undefined,
        order: params.order,
        openHouseId: params.openHouseId,
        ohEventId: params.ohEventId,
        ohEventTypeId: params.ohEventTypeId,
        typeId: params.typeId,
        fcShopId: params.fcShopId,
        sendDate: params.sendDate ? DateTime.fromJSDate(params.sendDate).toFormat('yyyy-MM-dd') : undefined,
        limit: formatLimit({limit: params.limit, page: params.page}),
        hpFlag: params.onlyMe !== undefined ? Number(params.onlyMe) : undefined,
        roomType: params.roomTypes ? [...new Set(params.roomTypes)].join(',') : undefined,
        priceMin: params.minPrice,
        priceMax: params.maxPrice,
        walkMin: params.stationDistance,
        landUnderSpace: params.landMinArea,
        landHighSpace: params.landMaxArea,
        tsuboTanka: params.squareMeterPrice,
        tatemonoUnderSpace: params.buildingMinArea,
        tatemonoHighSpace: params.buildingMaxArea,
        senyuUnderSpace: params.propertyConstructMinArea,
        senyuHighSpace: params.propertyConstructMaxArea,
        floorAboveQuantity: params.floors,
        completion: params.years,
      },
    });
    const data = response.data as ListDoNetworkEstateResponse[];

    return data.map((item) => convertListDoNetworkEstateResponse(item));
  },

  async getEstateDetailImage(form: GetEstateDetailImageForm): Promise<EstateDetailImage[]> {
    const response = await axios.get('getEstateDetailImage', {params: form});
    return response.data.map((item: {
      DIVISION: string,
      DIVISION_GROUP: string,
      DIVISION_GROUP_ID: string,
      EQUIPMENT: number,
      ESTATE_ID: string,
      IMAGE_TITLE: string | null,
      LOCAL_WALK_DISTANCE: number | null,
      OUTPUT_ORDER: number,
      PATH: string,
      PATH_L: string,
      PR: string,
      SELLER_COLUMN: number,
      DIVISION_ID: string | null,
      walkingMinutes: number | null,
      distanceMeters: number | null,
    }) => ({
      path: item.PATH,
      pathL: item.PATH_L,
      estateId: Number(item.ESTATE_ID),
      divisionGroupId: item.DIVISION_GROUP_ID as EstateDetailImageType,
      divisionName: item.DIVISION,
      divisionId: item.DIVISION_ID as EstateMapTypes,
      pr: item.PR,
      localDistance: item.LOCAL_WALK_DISTANCE,
      outputOrder: String(item.OUTPUT_ORDER),
      title: item.IMAGE_TITLE ? item.IMAGE_TITLE : '',
      referencePoint: {
        walkingMinutes: item.walkingMinutes ? Number(item.walkingMinutes) : 0,
        distanceMeters: item.distanceMeters ? Number(item.distanceMeters) : 0,
      },
    }));
  },

  async countEstates(params: ListDoNetworkEstateForm): Promise<number> {
    const res = await axios.get('/doNetwork/getEstateListCountForMypage', {
      params: {
        estateTypes: params.condition ? `${params.condition}_${romajiEstateTypeMap[params.estateType as EstateType]}`
          : romajiEstateTypeMap[params.estateType as EstateType],
        prefCityIds: params.prefCities ? formatPrefCities(params.prefCities) : undefined,
        order: params.order,
        openHouseId: params.openHouseId,
        ohEventId: params.ohEventId,
        ohEventTypeId: params.ohEventTypeId,
        typeId: params.typeId,
        fcShopId: params.fcShopId,
        sendDate: params.sendDate ? DateTime.fromJSDate(params.sendDate).toFormat('yyyy-MM-dd') : undefined,
        hpFlag: params.onlyMe !== undefined ? Number(params.onlyMe) : undefined,
        roomType: params.roomTypes ? [...new Set(params.roomTypes)].join(',') : undefined,
        priceMin: params.minPrice,
        priceMax: params.maxPrice,
        walkMin: params.stationDistance,
        landUnderSpace: params.landMinArea,
        landHighSpace: params.landMaxArea,
        tsuboTanka: params.squareMeterPrice,
        tatemonoUnderSpace: params.buildingMinArea,
        tatemonoHighSpace: params.buildingMaxArea,
        senyuUnderSpace: params.propertyConstructMinArea,
        senyuHighSpace: params.propertyConstructMaxArea,
        completion: params.years,
      },
    });
    const data = res.data as [{ count: number }];
    return Number(data[0].count);
  },

  async countFavoriteEstates(params: ListDoNetworkEstateForm): Promise<number> {
    const res = await axios.get('/favorites/count', {
      params: {
        estateTypes: params.condition ? `${params.condition}_${romajiEstateTypeMap[params.estateType as EstateType]}`
          : romajiEstateTypeMap[params.estateType as EstateType],
        prefCityIds: params.prefCities ? formatPrefCities(params.prefCities) : undefined,
        order: params.order,
        openHouseId: params.openHouseId,
        ohEventId: params.ohEventId,
        ohEventTypeId: params.ohEventTypeId,
        typeId: params.typeId,
        fcShopId: params.fcShopId,
        sendDate: params.sendDate ? DateTime.fromJSDate(params.sendDate).toFormat('yyyy-MM-dd') : undefined,
        hpFlag: params.onlyMe !== undefined ? Number(params.onlyMe) : undefined,
        roomType: params.roomTypes ? [...new Set(params.roomTypes)].join(',') : undefined,
        priceMin: params.minPrice,
        priceMax: params.maxPrice,
        walkMin: params.stationDistance,
        landUnderSpace: params.landMinArea,
        landHighSpace: params.landMaxArea,
        tsuboTanka: params.squareMeterPrice,
        tatemonoUnderSpace: params.buildingMinArea,
        tatemonoHighSpace: params.buildingMaxArea,
        senyuUnderSpace: params.propertyConstructMinArea,
        senyuHighSpace: params.propertyConstructMaxArea,
        completion: params.years,
      },
    });
    const data = res.data as [{ count: number }];
    return Number(data[0].count);
  },

  async getEstateDetail(form: EstateDetailForm): Promise<EstateDetail> {
    const res = await axios.get('/getEstateDetail', {
      params: {
        estateId: form.estateId,
        urlType: form.urlType,
      }
    });
    const item = res.data[0] as {
      ESTATE_ID: string,
      PRICE_DOWN_FLAG: string,
      NOT_BUILD: string,
      ESTATE_TYPE: string,
      ESTATE_TYPE_ID: string,
      NEW_USED_TYPE_NAME: string | null,
      REAL_ESTATE_TYPE_ID: RealEstateTypeId,
      NEW_USED_TYPE_ID: string,
      ESTATE_TYPE_NAME: KanjiEstateType,
      ESTATE_NAME: string,
      ESTATE_NAME_WEB: string,
      SHUEKI_FLAG: string,
      ESTATE_PRICE: string,
      HOUSE_ESTATE_PRICE_FORMAT:  string,
      HOUSE_ESTATE_PRICE_UNIT:  string,
      PREF_NAME: string,
      PREF_ID: string,
      CITY_ID: string,
      CITY_NAME: string,
      TOWN_NAME: string,
      ESTATE_ADDRESS: string,
      REGIST_DATETIME: string,
      RAIL_ID: string,
      RAIL_NAME1: string,
      STATION_NAME: string,
      STATION_NAME1: string,
      RAIL_NAME2: string,
      STATION_NAME2: string,
      RAIL_NAME3: string,
      RAIL_NAME4: string,
      STATION_NAME3: string,
      STATION_NAME4: string,
      YUSEN_KOTSU_KBN_1: string | null,
      YUSEN_KOTSU_KBN_2: string | null,
      YUSEN_KOTSU_KBN_3: string | null,
      YUSEN_KOTSU_KBN_ETC: string | null,
      BUS_STOP_NAME1: string,
      BUS_MINUTES1: string,
      WALK_MINUTES1: string,
      BUS_STOP_NAME2: string,
      BUS_MINUTES2: string,
      WALK_MINUTES2: string,
      BUS_STOP_NAME3: string,
      BUS_MINUTES3: string,
      WALK_MINUTES3: string,
      TRAFFIC_ETC: string,
      WALK_MINUTES_ETC: string,
      BURDEN_CHARGE: string | null,
      BURDEN_CHARGE_REMARK: string,
      EXTERIOR_IMAGE_NAME1: string,
      EXTERIOR_IMAGE_NAME2: string,
      EXTERIOR_IMAGE_NAME3: string,
      EXTERIOR_IMAGE_NAME4: string,
      EXTERIOR_IMAGE_NAME5: string,
      EXTERIOR_IMAGE_NAME6: string,
      EXTERIOR_IMAGE_NAME7: string,
      EXTERIOR_IMAGE_NAME8: string,
      EXTERIOR_IMAGE_NAME9: string,
      EXTERIOR_IMAGE_NAME10: string,
      EXTERIOR_IMAGE_NAME11: string,
      EXTERIOR_IMAGE_NAME12: string,
      EXTERIOR_IMAGE_NAME13: string,
      EXTERIOR_IMAGE_NAME14: string,
      EXTERIOR_IMAGE_NAME15: string,
      EXTERIOR_IMAGE_NAME16: string,
      EXTERIOR_IMAGE_NAME17: string,
      EXTERIOR_IMAGE_NAME18: string,
      EXTERIOR_IMAGE_NAME19: string,
      KAWA_ID: string,
      ROOM_QUANTITY: string | null,
      VACATE_PERIOD: string,
      ROOM_LAYOUT_TYPE_ID: '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | '09' | null,
      FLOOR: string | null,
      FLOOR_ABOVE_QUANTITY: `${number}階` | `${number}`,
      FLOOR_SPACE_MANSION: string,
      COMPLETION_YEAR: string,
      COMPLETION_MONTH: string,
      CONTROL_COST: string,
      REPAIR_COST: string,
      balconyDirectionName: string,
      BALCONY_DIRECTION_ID: '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | null,
      GARAGE_PRICE: string,
      GARAGE_PRICE_LOWER: string,
      GARAGE_PRICE_UPPER: string,
      CUSTOS_RESIDENCE_FLAG: string,
      CUSTOS_DAY_SHIFT_FLAG: string,
      CUSTOS_ROUTINE_FLAG: string,
      MANAGEMENT_MODE_TYPE_ID: '01' | '02' | '03' | null,
      BIKO: string,
      ROAD_DIRECTION_ID1: '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | null,
      ROAD_DIRECTION_ID2: '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | null,
      ROAD_DIRECTION_ID3: '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | null,
      ROAD_DIRECTION_ID4: '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | null,
      ROAD_TYPE_ID1: '01' | '02' | null,
      ROAD_TYPE_ID2:  '01' | '02' | null,
      ROAD_TYPE_ID3: '01' | '02' | null,
      ROAD_TYPE_ID4: '01' | '02' | null,
      ROAD_SPAN1: string,
      ROAD_FRONT_SPAN1: string,
      ROAD_SPAN2: string,
      ROAD_FRONT_SPAN2: string,
      ROAD_SPAN3: string,
      ROAD_FRONT_SPAN3: string,
      ROAD_SPAN4: string,
      ROAD_FRONT_SPAN4: string,
      FLOOR_SPACE_TOTAL: string,
      FLOOR_SPACE_1F: string,
      FLOOR_SPACE_2F: string,
      FLOOR_SPACE_3F: string,
      FLOOR_SPACE_ETC: string,
      LAND_SPACE_M2_PUBLIC: string,
      BUILDING_COVERAGE: string,
      PLOT_RATIO: string,
      TSUBO: string,
      TSUBO_TANKA: string | null,
      LAND_CATEGORY_NAME: string | null,
      USE_DISTRICT: string,
      PRIVATE_ROAD_SPACE: string | null,
      SETBACK_SPINE_SQM: string | null,
      SETBACK_SPINE_FLAG: string,
      SETBACK_ALREADY_FLAG: string,
      SPLITTABLE_FLAG: string,
      VACATE_TYPE_ID: string,
      PARKING_FLAG: string,
      PARKING_QUANTITY: string | null,
      GARAGE_FLAG: string,
      PARKING_2_FLAG: string,
      PARKING_3_FLAG: string,
      BUILDING_CERTIFICATION_CODE: string,
      FC_NAME: string | null,
      FC_SHOP_NAME: string,
      FC_ID: string,
      FC_SHOP_ID: string,
      FC_SHOP_FREE_DIAL: string | null,
      FC_SHOP_TEL: string,
      LICENSE_NUMBER: string | null,
      FC_SHOP_MAIL_ADDRESS: string | null,
      FC_SHOP_ADDRESS: string | null,
      BUSINESS_HOUR: string | null,
      HOLIDAY: string | null,
      BUILDING_ID: string | null,
      TOTAL_ESTATE_QUANTITY: string | null,
      TOTAL_SALES_HOUSE: string | null,
      REAL_ESTATE_TYPE_NAME: string,
      CATCH: string,
      PR: string,
      RENOVATION_FLAG: string,
      YIELD_RATE: string | null,
      LOW_MODIFY_DATETIME: string,
      ELEMENTARYSCHOOL_NAME: string | null,
      ELEMENTARYSCHOOL_DISTANCE: string,
      JUNIORHIGHSCHOOL_NAME: string,
      JUNIORHIGHSCHOOL_DISTANCE: string,
      balcony_direction_id: string | null,
      balcony_space: string | null,
      balcony_space_around_flag: string,
      roofbalcony_space: string | null,
      ruef_balcony_flag: string,
      roofbalcony_price: string | null,
      only_garden_space: string | null,
      only_garden_flag: string,
      only_garden_price: string | null,
      terrace_flag: string,
      terrace_space: string | null,
      terrace_price: string | null,
      wide_balcony_flag: string,
      reform_execution_id: string | null,
      wide_balcony: string
      balcony_2: string
      balcony_3: string
      terrace: string
      wood_deck: string
      prepare_year: string,
      prepare_month: string,
      NOT_REBUILD: string,
      latitude: string,
      longitude: string,
      OH_EVENT_ID: string | null,
      GARAGE_TYPE_ID: string | null,
      OPEN_PARKING_FLAG: string,
      PARKING_LOT_FLAG: string,
      PORTAL_MAP_PIN_FLAG: string,
      REFORM_EXECUTION_WATER_YEAR: string,
      REFORM_EXECUTION_WATER_MONTH: string,
      REFORM_EXECUTION_OUT_YEAR: string,
      REFORM_EXECUTION_OUT_MONTH: string,
      REFORM_EXECUTION_IN_YEAR: string,
      REFORM_EXECUTION_IN_MONTH: string,
      RENOVATION_YEAR: string,
      RENOVATION_MONTH: string,
      reform_execution_kitchen: string,
      reform_execution_bath: string,
      reform_execution_toilet: string,
      reform_execution_washstand: string,
      reform_execution_hot_water_dispenser: string,
      reform_execution_plumbing: string,
      reform_execution_water_other: string,
      reform_execution_wall: string,
      reform_execution_floor: string,
      reform_execution_whole: string,
      reform_execution_fitting: string,
      reform_execution_sash: string,
      reform_execution_other: string,
      reform_execution_out_wall: string,
      reform_execution_out_roof: string,
      reform_execution_out_large: string,
      reform_execution_out_other: string,
      reform_execution_biko: string,
      USER_PR_HP: string | null,
      USER_ID: string,
      USER_NAME: string,
      USER_NAME_ALTERNATIVE: string | null,
      PROFILE_IMAGE: string | null,
      MYPAGE_FLAG: string,
      HP_FLAG: string,
      PORTAL_ESTATE_NAME: string,
      PRIVATE_ROAD_RATE_SPACE: string,
      PRIVATE_ROAD_RATE_TEXT: string | null,
      ESTATE_STATUS_ID: string,
      DELETE_FLAG: string,
      OPTIMAL_DISTRICT_NAME: string | null
      PRIVATE_ROAD_TYPE_ID: '01' | '02' | null,
      ADDITIONAL_PR_URL: string,
      ADDITIONAL_PR_TYPE: 0 | 1 | 2,
      ADDITIONAL_PR_BANNER_NAME: string | null,
      DWELLING_QUANTITY: string | null,
      DWELLING_QUANTITY_BIKO: string,
      FLOOR_SPACE_MANSION_UPPER: string,
      MAIN_LIGHTING: string,
      BALCONY: string,
      ROAD1: string,
      ROAD2: string,
      ROAD3: string,
      ROAD4: string,
      CITY_PLAN: string,
      ELEMENTARYSCHOOL: string,
      JUNIORHIGHSCHOOL: string,
      VACATE: string,
      MANAGEMENT_MODE_TYPE: string,
      STRUCTURE: string,
      LAND_RIGHT: string,
      MANAGEMENT_COMPANY: string,
      EXECUTION_COMPANY: string,
      BUILDING_RESTRICT: string,
      KOHO: string,
      CHISEI: string,
      LOWS_NOTE: string,
      TRADE_TRANSACTION_TYPE: string,
      SITUATION: string,
      LAND_CATEGORYE: string,
      REFORM_EXECUTION_OTHER_CONTENT_DETAIL: string,
      SITE_AREA: string,
      PACKAGE_PART_TYPE_ID: string,

      // TODO Review the use case from the following fields
      ESTATE_TYPE_DISP: string,
      ESTATE_TYPE_DETAIL: string,
      PARKING: string,
      SETBACK: string,
      ROOFBALCONY: string,
      PREPARE_YEAR_MONTH_LOCAL: string
      AD_MODIFY_DATE: string,
      AD_NEXT_MODIFY_DATE: string,
      COUPON_YIELD: string,
      ASSUMPTION_YCOUPON_YIELD: string,
      STATION_ACCESS1: string,
      STATION_ACCESS2: string,
      STATION_ACCESS3: string,
      STATION_ACCESS4: string,
      SPACE_TSUBO: string,
      SPACE_DETAIL: string,
      COMPLETION_YEAR_MONTH_LOCAL: string,
      FLOOR_QUANTITY: string,
      ONLY_GARDEN: string
      TERRACE: string
      TATEMONOKENSA_JISSHI: string
      TATEMONOKENSA_JISSHI_BIKO: string
      KASHI_HOSHO: string
      KASHI_HOSHO_RANGE: string
      KASHI_HOSHO_RANGE_DETAIL: string
      KASHI_HOKEN: string
      KASHI_HOKEN_RANGE: string
      KASHI_HOKEN_RANGE_DETAIL: string
      HYOKASHO: string
      REFORM_EXECUTION_WATER_YEAR_MONTH: string
      REFORM_EXECUTION_WATER_CONTENTS: string
      REFORM_EXECUTION_WATER_CONTENTS_DETAIL: string
      REFORM_EXECUTION_OUT_YEAR_MONTH: string
      REFORM_EXECUTION_OUT_CONTENTS: string
      REFORM_EXECUTION_OUT_CONTENTS_DETAIL: string
      REFORM_EXECUTION_IN_YEAR_MONTH: string
      REFORM_EXECUTION_IN_CONTENTS: string
      REFORM_EXECUTION_IN_CONTENTS_DETAIL: string
      REFORM_COMMON_YEAR_MONTH: string
      REFORM_COMMON_CONTENT_DETAIL: string
      REFORM_EXECUTION_OTHER_YEAR_MONTH: string
      KAWA_MINMAX_PRICE: string
      KAWA_MINMAX_PRICE_FORMATTED: string
      KAWA_MAJORITY_PRICE: string
      KAWA_MAJORITY_PRICE_FORMATTED: string
      KAWA_MINMAX_AREA: string
      KAWA_MINMAX_FLOOR_SPACE_TOTAL: string
      KAWA_MINMAX_CONTROL_COST: string
      KAWA_MINMAX_REPAIR_COST: string
      LAND_TSUBO: string
      LAND_KEISHA: string
      LAND_ROJIJO: string
      LAND_KOATSUSEN: string
      LAND_EQUITY: string
      CHIEKI: string
      RENTED_GROUND: string
      TERM_LEASEHOLD: string
      TERM_LEASEHOLD_REGISTRATION: string
      TERM_LEASEHOLD_RENTED_PRICE: string
      BUILDING_PERMIT_REASON: string
      KOKUDO: string
      KAIHATUKYOKA_NO: string
      NOCHI_TENYO_KYOKA_NO: string
      ICHIJIKIN: string
      RUNNING_COST: string
      CHOKAIHI: string
      ONSEN: string
      ONSEN_DETAIL: string
      FTTH: string
      CATV: string
      CABLE_BROADCAST: string
      IMAGE_TITLE: string
      LOCAL_WALK_DISTANCE: string
      FLOOR_MANSION: string
      CUSTOS: string
      MANAGE_KUMIAI: string
      PARKING_MANSION: string
      PARKING_KOZO: string
      SPECIAL: string | null
      PARKING_MOTORCYCLES: string
      PARKING_BICYCLES: string
      PARKING_BUNJO: string
      PARKING_BUNJO_KIKIN: string
      OPTIMAL_DISTRICT: string
      EQUIPMENT: string
      EQUIP_BATH: string
      EQUIP_KITCHEN: string
      EQUIP_WASH: string
      EQUIP_LIFELINE: string
      EQUIP_SECURITY: string
      EQUIP_OTHER: string
      EQUIP_ROOM: string
      NOTE_KOHO: string
      NOTE_HOSYO: string
      OH_ADDRESS: string
      OH_DATE: string
      OH_EVENT_APPROACH: string
      OH_EVENT_STATUS: string
      OH_TIME: string
      EVENT_IMAGE_1: string
      ESTATE_ADDRESS_MAP: string
      VR_FLAG: string
      VR_360_FLAG: string,
      ESTATE_ADDRESS_DISP: string,
      land?: {
        area?: number,
        restriction: {
          type: BuildingRestriction | null,
          other: string,
          suumo: string, // Suumo it is a site that sell houses in Japan
        } | null,
      },
      garages?: {
        car?: {
          availability: GarageAvailability,
          price: number | { min: number, max: number } | null,
          distance: number,
          quantity: { normal: number, mini: number } | null,
        } | null,
      },
      surroundings: {
        trainStations: Array<{
          id: number,
          name: string,
          rail: { id: number; name: string; } | null,
          access: Access | null,
        } | {
          name: string,
          rail: { name: string } | null,
          access: Access | null,
        }>
      },
      images: {
        layout: string[],
        exterior: string[]
      },
      areas: {
        floor: {
          area: { value: number, measureType: MeasureType | null },
          buildAreaType: LandAreaType | null,
        }
      }
      rights: {
        ownership: EstateOwnership | null,
      }
    };
    if (!item) throw new NotFoundError('Estate Not Found');
    const formattedInsurances = formatInsurances({
      earthquake: item.TATEMONOKENSA_JISSHI ? item.TATEMONOKENSA_JISSHI : '',
      earthquakeBiko: item.TATEMONOKENSA_JISSHI_BIKO ? item.TATEMONOKENSA_JISSHI_BIKO : '',
      policy: item.KASHI_HOSHO ? item.KASHI_HOSHO : '',
      policyCover: item.KASHI_HOSHO_RANGE ? item.KASHI_HOSHO_RANGE : '',
      policyCoverDetail: item.KASHI_HOSHO_RANGE_DETAIL ? item.KASHI_HOSHO_RANGE_DETAIL : '',
      warranty: item.KASHI_HOKEN ? item.KASHI_HOKEN : '',
      warrantyCover: item.KASHI_HOKEN_RANGE ? item.KASHI_HOKEN_RANGE : '',
      warrantyCoverDetail: item.KASHI_HOKEN_RANGE_DETAIL ? item.KASHI_HOKEN_RANGE_DETAIL : '',
      invoice: item.HYOKASHO ? item.HYOKASHO : '',
    });
    return {
      id: Number(item.ESTATE_ID),
      catch: item.CATCH,
      elementarySchool: item.ELEMENTARYSCHOOL_NAME ? {
        distance: Number(item.ELEMENTARYSCHOOL_DISTANCE),
        name: item.ELEMENTARYSCHOOL_NAME,
        nameWithDistance: item.ELEMENTARYSCHOOL,
      } : undefined,
      landLotId: Number(item.KAWA_ID),
      juniorHighSchool: {
        name: item.JUNIORHIGHSCHOOL_NAME || '',
        nameWithDistance: item.JUNIORHIGHSCHOOL
      },
      // Todo refazer a formatação
      landPublicM2Space: item.LAND_SPACE_M2_PUBLIC ? item.LAND_SPACE_M2_PUBLIC : '',
      name: item.ESTATE_NAME_WEB,
      portalName: item.PORTAL_ESTATE_NAME,
      price: Number(item.ESTATE_PRICE) * 10000,
      priceInTenThousand: (item.ESTATE_PRICE),
      formattedPrice: item.HOUSE_ESTATE_PRICE_FORMAT,
      formattedPriceUnit: item.HOUSE_ESTATE_PRICE_UNIT,
      estateAddressDisp: item.ESTATE_ADDRESS_DISP,
      ad: {
        name: item.ESTATE_NAME,
        web: item.ESTATE_NAME_WEB,
      },
      priceDownFlag: item.PRICE_DOWN_FLAG === '1',
      room: {
        layoutName: item.ROOM_LAYOUT_TYPE_ID ? toRoomLayout[item.ROOM_LAYOUT_TYPE_ID] : '',
        quantity: item.ROOM_QUANTITY ? Number(item.ROOM_QUANTITY) : ''
      },
      statusId: item.ESTATE_STATUS_ID as EstateStatus,
      realTypeName: estateTypeIdToEstateType[item.REAL_ESTATE_TYPE_ID],
      cableBroadcasting: item.CABLE_BROADCAST ? item.CABLE_BROADCAST : '',
      structureName: item.STRUCTURE,
      floor: Number(item.FLOOR),
      buildingTotalFloor: item.FLOOR_ABOVE_QUANTITY.includes('階')
        ? Number(item.FLOOR_ABOVE_QUANTITY.replace('階', ''))
        : Number(item.FLOOR_ABOVE_QUANTITY),
      rentFlag: item.SHUEKI_FLAG === '1',
      trafficEtc: item.TRAFFIC_ETC,
      walkMinutesEtc: item.WALK_MINUTES_ETC,
      address: item.ESTATE_ADDRESS,
      type: kanjiToEstateTypeMap[item.ESTATE_TYPE_NAME],
      totalFloorSpace: item.FLOOR_SPACE_TOTAL,
      totalMansionSpace: item.FLOOR_SPACE_MANSION,
      totalPublicLandSpace: item.LAND_SPACE_M2_PUBLIC,
      totalSpace: Number(item.FLOOR_SPACE_TOTAL),
      // TODO make the formatter after
      floorSpaceMansion: item.FLOOR_SPACE_MANSION,
      tsubo: item.TSUBO,
      // termina aqui
      tsuboTanka: item.TSUBO_TANKA ? item.TSUBO_TANKA : '',
      buildingRestrictTypeName: item.BUILDING_RESTRICT ? item.BUILDING_RESTRICT : undefined,
      businessTypeName: item.REAL_ESTATE_TYPE_NAME,
      yieldRate: Number(item.YIELD_RATE),
      buildingAreaRadio: Number(item.BUILDING_COVERAGE),
      utilAreaRadio: item.PLOT_RATIO,
      isSold: item.ESTATE_STATUS_ID === EstateStatus.soldThirdParty || item.ESTATE_STATUS_ID === EstateStatus.sold,
      // TODO make the formatter after
      lowModifyAt: new Date(item.LOW_MODIFY_DATETIME),
      completionYear: Number(item.COMPLETION_YEAR),
      completionMonth: Number(item.COMPLETION_MONTH),
      isInHomepage: Boolean(Number(item.HP_FLAG)),
      floorString: item.FLOOR ? item.FLOOR : '',
      createdAt: new Date(item.REGIST_DATETIME),
      specialFeatures: item.SPECIAL,
      town: {name: item.TOWN_NAME},
      user: {name: item.USER_NAME},
      fcShop: {name: item.FC_SHOP_NAME, id: Number(item.FC_SHOP_ID)},
      pref: {id: Number(item.PREF_ID), name: item.PREF_NAME},
      city: {
        id: Number(item.CITY_ID),
        name: item.CITY_NAME,
        urbanPlanning: urbanPlanningMap[item.CITY_PLAN as UrbanPlanningType]
      },
      access: [{
        stationName: item.STATION_NAME1 ? item.STATION_NAME1 : '',
        railName: item.RAIL_NAME1 ? item.RAIL_NAME1 : '',
        busMinutes: item.BUS_MINUTES1 ? Number(item.BUS_MINUTES1.match(/\d+/)) : 0,
        busStopName: item.BUS_STOP_NAME1 || '',
        walkMinutes: item.WALK_MINUTES1 ? Number(item.WALK_MINUTES1.match(/\d+/)) : 0,
        stationAccess: item.STATION_ACCESS1 ? item.STATION_ACCESS1 : '',
        priority: item.YUSEN_KOTSU_KBN_1 ? Number(item.YUSEN_KOTSU_KBN_1) : null,
      }, {
        stationName: item.STATION_NAME2 ? item.STATION_NAME2 : '',
        railName: item.RAIL_NAME2 ? item.RAIL_NAME2 : '',
        busMinutes: item.BUS_MINUTES2 ? Number(item.BUS_MINUTES2.match(/\d+/)) : 0,
        busStopName: item.BUS_STOP_NAME2 || '',
        walkMinutes: item.WALK_MINUTES2 ? Number(item.WALK_MINUTES2.match(/\d+/)) : 0,
        stationAccess: item.STATION_ACCESS2 ? item.STATION_ACCESS2 : '',
        priority: item.YUSEN_KOTSU_KBN_2 ? Number(item.YUSEN_KOTSU_KBN_2) : null,
      }, {
        stationName: item.STATION_NAME3 ? item.STATION_NAME3 : '',
        railName: item.RAIL_NAME3 ? item.RAIL_NAME3 : '',
        busMinutes: item.BUS_MINUTES3 ? Number(item.BUS_MINUTES3.match(/\d+/)) : 0,
        busStopName: item.BUS_STOP_NAME3 || '',
        walkMinutes: item.WALK_MINUTES3 ? Number(item.WALK_MINUTES3.match(/\d+/)) : 0,
        stationAccess: item.STATION_ACCESS3 ? item.STATION_ACCESS3 : '',
        priority: item.YUSEN_KOTSU_KBN_3 ? Number(item.YUSEN_KOTSU_KBN_3) : null,
      }, {
        stationName: item.STATION_NAME4 ? item.STATION_NAME4 : '',
        railName: item.RAIL_NAME4 ? item.RAIL_NAME4 : '',
        busMinutes: 0,
        busStopName: '',
        walkMinutes: 0,
        stationAccess: item.STATION_ACCESS4 ? item.STATION_ACCESS4 : '',
        priority: item.YUSEN_KOTSU_KBN_ETC ? Number(item.YUSEN_KOTSU_KBN_ETC) : null,
      }].filter((access) => access.stationName || access.railName || access.busMinutes || access.busStopName),
      accessEtc: {
        walkMinutes: Number.isNaN(Number(item.WALK_MINUTES_ETC)) ? 0 : Number(item.WALK_MINUTES_ETC),
        traffic: item.TRAFFIC_ETC,
        priority: item.YUSEN_KOTSU_KBN_ETC ? item.YUSEN_KOTSU_KBN_ETC : undefined,
      },
      salesman: item.USER_ID || item.USER_NAME || item.USER_PR_HP ? {
        id: Number(item.USER_ID) || 0,
        name: item.USER_NAME || item.USER_NAME_ALTERNATIVE || '',
        prHp: item.USER_PR_HP || '',
        profileImage: item.PROFILE_IMAGE || '',
      } : undefined,
      myPageFlag: item.MYPAGE_FLAG === '1',
      fcShopTel: item.FC_SHOP_TEL,
      ohEventId: Number.isNaN(Number(item.OH_EVENT_ID)) ? 0 : Number(item.OH_EVENT_ID),
      realRegisterDatetime: item.REGIST_DATETIME,
      latitude: Number(item.latitude),
      longitude: Number(item.longitude),
      portalMapPinFlag: item.PORTAL_MAP_PIN_FLAG === '1',
      mapAddress: item.PORTAL_MAP_PIN_FLAG === '1'
        ? item.PREF_NAME + item.CITY_NAME + item.TOWN_NAME + item.ESTATE_ADDRESS
        : item.PREF_NAME + item.CITY_NAME + item.TOWN_NAME,
      exteriorImages: [
        item.EXTERIOR_IMAGE_NAME1,
        item.EXTERIOR_IMAGE_NAME2,
        item.EXTERIOR_IMAGE_NAME3,
        item.EXTERIOR_IMAGE_NAME4,
        item.EXTERIOR_IMAGE_NAME5,
        item.EXTERIOR_IMAGE_NAME6,
        item.EXTERIOR_IMAGE_NAME7,
        item.EXTERIOR_IMAGE_NAME8,
        item.EXTERIOR_IMAGE_NAME10,
        item.EXTERIOR_IMAGE_NAME11,
        item.EXTERIOR_IMAGE_NAME12,
        item.EXTERIOR_IMAGE_NAME13,
        item.EXTERIOR_IMAGE_NAME14,
        item.EXTERIOR_IMAGE_NAME15,
        item.EXTERIOR_IMAGE_NAME16,
        item.EXTERIOR_IMAGE_NAME17,
        item.EXTERIOR_IMAGE_NAME18,
        item.EXTERIOR_IMAGE_NAME19,
      ].filter((i) => !!i) as string[],
      reformFlag: (item.reform_execution_id !== '' && item.reform_execution_id !== '1' && !!item.reform_execution_id),
      roofBalconyFlag: item.ruef_balcony_flag === '1',
      renovationFlag: item.RENOVATION_FLAG === '1',
      lowModifyDatetime: item.LOW_MODIFY_DATETIME,
      packagePartTypeId: item.PACKAGE_PART_TYPE_ID ? item.PACKAGE_PART_TYPE_ID : undefined,
      businessFlag: item.SHUEKI_FLAG === '1',
      floorAboveQuantity: item.FLOOR_ABOVE_QUANTITY,
      typeId: item.ESTATE_TYPE_ID,
      dwellingQuantity: Number.isNaN(Number(item.DWELLING_QUANTITY)) ? 0 : Number(item.DWELLING_QUANTITY),
      dwellingQuantityBiko: Number.isNaN(Number(item.DWELLING_QUANTITY_BIKO)) ? 0 : Number(item.DWELLING_QUANTITY_BIKO),
      floorSpaceMansionUpper: Number(item.FLOOR_SPACE_MANSION_UPPER),
      balconyDirectionName: item.BALCONY_DIRECTION_ID ? toDirectionText[directionIdToDirection[item.BALCONY_DIRECTION_ID]] : '',
      situationName: item.SITUATION,
      balconyDirectionId: item.BALCONY_DIRECTION_ID ? item.BALCONY_DIRECTION_ID : undefined,
      balconySpace: item.balcony_space ? item.balcony_space : undefined,
      balconySpaceAroundFlag: item.balcony_space_around_flag === '1',
      roofBalconySpace: item.roofbalcony_space ? item.roofbalcony_space : undefined,
      roofBalconyPrice: Number.isNaN(Number(item.roofbalcony_price)) ? 0 : Number(item.roofbalcony_price),
      onlyGardenFlag: item.only_garden_flag === '1',
      onlyGardenSpace: item.only_garden_space ? item.only_garden_space : undefined,
      onlyGardenPrice: Number.isNaN(Number(item.only_garden_price)) ? 0 : Number(item.only_garden_price),
      terraceFlag: item.terrace_flag === '1',
      terraceSpace: item.terrace_space ? item.terrace_space : undefined,
      terracePrice: Number.isNaN(Number(item.terrace_price)) ? 0 : Number(item.terrace_price),
      wideBalcony: item.wide_balcony,
      balcony2: item.balcony_2,
      balcony3: item.balcony_3,
      woodDeck: item.wood_deck,
      garageTypeId: item.GARAGE_TYPE_ID as GarageType,
      parking2Flag: item.PARKING_2_FLAG === '1',
      openParkingFlag: item.OPEN_PARKING_FLAG === '1',
      parkingLotFlag: item.PARKING_LOT_FLAG === '1',
      parkingFlag: item.PARKING_FLAG === '1',
      parkingQuantity: item.PARKING_QUANTITY ? item.PARKING_QUANTITY : '',
      garageFlag: item.GARAGE_FLAG === '1',
      parkingText: item.PARKING,
      garagePriceLower: Number.isNaN(Number(item.GARAGE_PRICE_LOWER)) ? 0 : Number(item.GARAGE_PRICE_LOWER),
      garagePriceUpper: Number.isNaN(Number(item.GARAGE_PRICE_UPPER)) ? 0 : Number(item.GARAGE_PRICE_UPPER),
      garagePrice: Number.isNaN(Number(item.GARAGE_PRICE)) ? 0 : Number(item.GARAGE_PRICE),
      // TODO existe uma nova regra no novo estate detail entao estou voltando conforme o sql
      totalEstateQuantity: item.TOTAL_ESTATE_QUANTITY ? item.TOTAL_ESTATE_QUANTITY : '',
      repairCost: item.REPAIR_COST ? Number(String(item.REPAIR_COST).replace(/\D/g, '')) : null,
      managementModeTypeName: item.MANAGEMENT_MODE_TYPE_ID ? managementModeTypeToString[managementTypeIdToManagementModeType[item.MANAGEMENT_MODE_TYPE_ID]] : undefined,
      costResidenceFlag: item.CUSTOS_RESIDENCE_FLAG !== '' || item.CUSTOS_RESIDENCE_FLAG !== null,
      costResidenceText: item.CUSTOS_RESIDENCE_FLAG,
      costDayShiftFlag: item.CUSTOS_DAY_SHIFT_FLAG !== '' || item.CUSTOS_DAY_SHIFT_FLAG !== null,
      costDayShiftText: item.CUSTOS_DAY_SHIFT_FLAG,
      costRoutineFlag: item.CUSTOS_ROUTINE_FLAG !== '' || item.CUSTOS_ROUTINE_FLAG !== null,
      costRoutineText: item.CUSTOS_ROUTINE_FLAG,
      landRightTypeName: item.LAND_RIGHT,
      vacateTypeId: item.VACATE_TYPE_ID as VacateType,
      buildingCertificationCode: item.BUILDING_CERTIFICATION_CODE,
      renovationYear: item.RENOVATION_YEAR,
      renovationMonth: item.RENOVATION_MONTH,
      reformExecutionWaterYear: item.REFORM_EXECUTION_WATER_YEAR,
      reformExecutionWaterMonth: item.REFORM_EXECUTION_WATER_MONTH,
      reformExecutionKitchen: item.reform_execution_kitchen,
      reformExecutionBath: item.reform_execution_bath,
      reformExecutionToilet: item.reform_execution_toilet,
      reformExecutionWashstand: item.reform_execution_washstand,
      reformExecutionHotWaterDispenser: item.reform_execution_hot_water_dispenser,
      reformExecutionPlumbing: item.reform_execution_plumbing,
      reformExecutionWaterOther: item.reform_execution_water_other,
      reformExecutionInYear: item.REFORM_EXECUTION_IN_YEAR,
      reformExecutionInMonth: item.REFORM_EXECUTION_IN_MONTH,
      reformExecutionWall: item.reform_execution_wall,
      reformExecutionFloor: item.reform_execution_floor,
      reformExecutionWhole: item.reform_execution_whole,
      reformExecutionFitting: item.reform_execution_fitting,
      reformExecutionSash: item.reform_execution_sash,
      reformExecutionOutYear: item.REFORM_EXECUTION_OUT_YEAR,
      reformExecutionOutMonth: item.REFORM_EXECUTION_OUT_MONTH,
      reformExecutionOutWall: item.reform_execution_out_wall,
      reformExecutionOutRoof: item.reform_execution_out_roof,
      reformExecutionOutLarge: item.reform_execution_out_large,
      reformExecutionOutOther: item.reform_execution_out_other,
      reformExecutionBiko: item.reform_execution_biko,
      juniorHighSchoolDistance: Number.isNaN(Number(item.JUNIORHIGHSCHOOL_DISTANCE)) ? 0 : Number(item.JUNIORHIGHSCHOOL_DISTANCE),
      juniorHighSchoolName: item.JUNIORHIGHSCHOOL_NAME,
      tradeTransactionTypeName: item.TRADE_TRANSACTION_TYPE,
      // TODO nao tem como um floor space ser 0,
      // Se for zero nao mostrar nada.
      floorSpaceTotal: item.FLOOR_SPACE_TOTAL ? item.FLOOR_SPACE_TOTAL : '',
      floorSpace1F: Number.isNaN(Number(item.FLOOR_SPACE_1F)) ? '' : Number(item.FLOOR_SPACE_1F),
      floorSpace2F: Number.isNaN(Number(item.FLOOR_SPACE_2F)) ? '' : Number(item.FLOOR_SPACE_2F),
      floorSpace3F: Number.isNaN(Number(item.FLOOR_SPACE_3F)) ? '' : Number(item.FLOOR_SPACE_3F),
      floorSpaceETC: Number.isNaN(Number(item.FLOOR_SPACE_ETC)) ? '' : Number(item.FLOOR_SPACE_ETC),
      buildingCoverage: item.BUILDING_COVERAGE,
      plotRatio: item.PLOT_RATIO,
      useDistrictName: item.USE_DISTRICT,
      landCategoryName: item.LAND_CATEGORY_NAME ? item.LAND_CATEGORY_NAME : undefined,
      privateRoadTypeId: Number.isNaN(Number(item.PRIVATE_ROAD_TYPE_ID)) ? 0 : Number(item.PRIVATE_ROAD_TYPE_ID),
      // Todo Private road space passará a ser formatada no front
      privateRoadSpace: item.PRIVATE_ROAD_SPACE ? item.PRIVATE_ROAD_SPACE : '',
      privateRoadTypeName: item.PRIVATE_ROAD_TYPE_ID ? privateRoadTypeToText[privateRoadTypeIdToPrivateRoadType[item.PRIVATE_ROAD_TYPE_ID]] : undefined,
      privateRoadRateText: item.PRIVATE_ROAD_RATE_TEXT ? item.PRIVATE_ROAD_RATE_TEXT : undefined,
      privateRoadRateSpace: Number.isNaN(Number(item.PRIVATE_ROAD_RATE_SPACE)) ? 0 : Number(item.PRIVATE_ROAD_RATE_SPACE),
      roadDirectionNames: [
        item.ROAD_DIRECTION_ID1 ? toDirectionText[directionIdToDirection[item.ROAD_DIRECTION_ID1]] : null,
        item.ROAD_DIRECTION_ID2 ? toDirectionText[directionIdToDirection[item.ROAD_DIRECTION_ID2]] : null,
        item.ROAD_DIRECTION_ID3 ? toDirectionText[directionIdToDirection[item.ROAD_DIRECTION_ID3]] : null,
        item.ROAD_DIRECTION_ID4 ? toDirectionText[directionIdToDirection[item.ROAD_DIRECTION_ID4]] : null,].filter((i) => !!i) as string[],
      roadTypeNames: [
        item.ROAD_TYPE_ID1 ? toRoadTypeText[roadTypeIdToRoadType[item.ROAD_TYPE_ID1]] : null,
        item.ROAD_TYPE_ID2 ? toRoadTypeText[roadTypeIdToRoadType[item.ROAD_TYPE_ID2]] : null,
        item.ROAD_TYPE_ID3 ? toRoadTypeText[roadTypeIdToRoadType[item.ROAD_TYPE_ID3]] : null,
        item.ROAD_TYPE_ID4 ? toRoadTypeText[roadTypeIdToRoadType[item.ROAD_TYPE_ID4]] : null,].filter((i) => !!i) as string[],
      roadSpans: [item.ROAD_SPAN1, item.ROAD_SPAN2, item.ROAD_SPAN3, item.ROAD_SPAN4].filter((i) => !!i) as string[],
      roadFrontSpans: [item.ROAD_FRONT_SPAN1, item.ROAD_FRONT_SPAN2, item.ROAD_FRONT_SPAN3, item.ROAD_FRONT_SPAN4].filter((i) => !!i) as string[],
      setbackStatus: (Number.isNaN(Number(item.SETBACK_SPINE_FLAG)) ? 0 : Number(item.SETBACK_SPINE_FLAG)),
      setbackSpineSQM: (Number.isNaN(Number(item.SETBACK_SPINE_SQM)) ? 0 : Number(item.SETBACK_SPINE_SQM)),
      setbackAlreadyFlag: item.SETBACK_ALREADY_FLAG === '1',
      splittableFlag: item.SPLITTABLE_FLAG === '1',
      vacateTypeName: item.VACATE,
      vacatePeriod: item.VACATE_PERIOD,
      controlCost: item.CONTROL_COST ? Number(String(item.CONTROL_COST).replace(/\D/g, '')) : null,
      biko: item.BIKO,
      pr: item.PR,
      additionalPR: {
        type: Number(item.ADDITIONAL_PR_TYPE),
        url: item.ADDITIONAL_PR_URL,
        bannerName: item.ADDITIONAL_PR_BANNER_NAME,
      },
      reformExecutionOther: item.reform_execution_other,
      prepareYear: Number(item.prepare_year),
      prepareMonth: Number(item.prepare_month),
      notBuild: item.NOT_BUILD,
      // TODO de onde saiu o 主要採光 ? que esta concatenando no front ?
      mainLighting: item.MAIN_LIGHTING,
      balcony: item.BALCONY,
      road: [item.ROAD1, item.ROAD2, item.ROAD3, item.ROAD4].filter((i) => !!i),
      licenseNumber: item.LICENSE_NUMBER ? item.LICENSE_NUMBER : '',
      vacate: item.VACATE,
      managementModeType: item.MANAGEMENT_MODE_TYPE,
      structure: item.STRUCTURE,
      landRight: item.LAND_RIGHT,
      managementCompany: item.MANAGEMENT_COMPANY,
      executionCompany: item.EXECUTION_COMPANY,
      buildingRestrict: item.BUILDING_RESTRICT,
      constructionMethod: item.KOHO,
      topography: item.CHISEI,
      // TODO existe dado que estao vindo com 0, deveria ser vazio ?
      rebuild: item.NOT_REBUILD !== '0' ? item.NOT_REBUILD : '',
      lowsNote: item.LOWS_NOTE,
      tradeTransactionType: item.TRADE_TRANSACTION_TYPE,
      situation: item.SITUATION,
      landCategory: item.LAND_CATEGORYE,
      reformExecutionOtherContentDetail: item.REFORM_EXECUTION_OTHER_CONTENT_DETAIL,
      siteArea: item.SITE_AREA,
      ownership: item.PACKAGE_PART_TYPE_ID as Ownership | null,
      stateOfUse: item.NEW_USED_TYPE_ID as StateOfUse,
      building: {
        completionType: null,
        builtAt: null,
      },

      // TODO so adicionei para fazer o deploy
      optimalDistrictName: item.OPTIMAL_DISTRICT_NAME ? item.OPTIMAL_DISTRICT_NAME : '',
      estateTypeDetail: item.ESTATE_TYPE_DETAIL,
      setback: item.SETBACK,
      roofbalcony: item.ROOFBALCONY,
      typeDisplay: item.ESTATE_TYPE_DISP,
      typeDetail: item.ESTATE_TYPE_DETAIL,
      parking: item.PARKING,
      constructionCompleteDate: item.PREPARE_YEAR_MONTH_LOCAL ? item.PREPARE_YEAR_MONTH_LOCAL : '',
      floorQuantityString: '',

      adModifyDate: item.AD_MODIFY_DATE,
      adNextModifyDate: item.AD_NEXT_MODIFY_DATE,
      couponYield: item.COUPON_YIELD,
      assumptionCouponYield: item.ASSUMPTION_YCOUPON_YIELD,
      spaceTsubo: item.SPACE_TSUBO,
      spaceDetail: item.SPACE_DETAIL,
      completionYearMonthLocal: item.COMPLETION_YEAR_MONTH_LOCAL,
      floorQuantity: item.FLOOR_QUANTITY,
      onlyGarden: item.ONLY_GARDEN,
      terrace: item.TERRACE,
      insurance: {
        earthquake: formattedInsurances[0],
        earthquakeBiko: formattedInsurances[1],
        policy: formattedInsurances[2],
        policyCover: formattedInsurances[3],
        policyCoverDetail: formattedInsurances[4],
        warranty: formattedInsurances[5],
        warrantyCover: formattedInsurances[6],
        warrantyCoverDetail: formattedInsurances[7],
        invoice: formattedInsurances[8],
      },
      reform: {
        executionWaterYearMonth: item.REFORM_EXECUTION_WATER_YEAR_MONTH ? item.REFORM_EXECUTION_WATER_YEAR_MONTH : '',
        executionWaterContents: item.REFORM_EXECUTION_WATER_CONTENTS ? item.REFORM_EXECUTION_WATER_CONTENTS : '',
        executionWaterContentsDetail: item.REFORM_EXECUTION_WATER_CONTENTS_DETAIL ? item.REFORM_EXECUTION_WATER_CONTENTS_DETAIL : '',
        executionOutYearMonth: item.REFORM_EXECUTION_OUT_YEAR_MONTH ? item.REFORM_EXECUTION_OUT_YEAR_MONTH : '',
        executionOutContents: item.REFORM_EXECUTION_OUT_CONTENTS ? item.REFORM_EXECUTION_OUT_CONTENTS : '',
        executionOutContentsDetails: item.REFORM_EXECUTION_OUT_CONTENTS_DETAIL ? item.REFORM_EXECUTION_OUT_CONTENTS_DETAIL : '',
        executionInYearMonth: item.REFORM_EXECUTION_IN_YEAR_MONTH ? item.REFORM_EXECUTION_IN_YEAR_MONTH : '',
        executionInContents: item.REFORM_EXECUTION_IN_CONTENTS ? item.REFORM_EXECUTION_IN_CONTENTS : '',
        executionInContentsDetails: item.REFORM_EXECUTION_IN_CONTENTS_DETAIL ? item.REFORM_EXECUTION_IN_CONTENTS_DETAIL : '',
        executionCommonYearMonth: item.REFORM_COMMON_YEAR_MONTH ? item.REFORM_COMMON_YEAR_MONTH : '',
        executionCommonContentDetail: item.REFORM_COMMON_CONTENT_DETAIL ? item.REFORM_COMMON_CONTENT_DETAIL : '',
        executionOtherYearMonth: item.REFORM_EXECUTION_OTHER_YEAR_MONTH ? item.REFORM_EXECUTION_OTHER_YEAR_MONTH : '',
        executionOtherContentDetail: item.REFORM_EXECUTION_OTHER_CONTENT_DETAIL ? item.REFORM_EXECUTION_OTHER_CONTENT_DETAIL : '',
      },
      landLot: {
        totalSalesHouse: item.TOTAL_SALES_HOUSE ? item.TOTAL_SALES_HOUSE : '',
        minMaxPrice: item.KAWA_MINMAX_PRICE ? item.KAWA_MINMAX_PRICE : '',
        minMaxPriceFormatted: item.KAWA_MINMAX_PRICE_FORMATTED ? item.KAWA_MINMAX_PRICE_FORMATTED : '',
        majorityPrice: item.KAWA_MAJORITY_PRICE ? item.KAWA_MAJORITY_PRICE : '',
        majorityPriceFormatted: item.KAWA_MAJORITY_PRICE_FORMATTED ? item.KAWA_MAJORITY_PRICE_FORMATTED : '',
        minMaxArea: item.KAWA_MINMAX_AREA ? item.KAWA_MINMAX_AREA : '',
        minMaxFloorSpaceTotal: item.KAWA_MINMAX_FLOOR_SPACE_TOTAL ? item.KAWA_MINMAX_FLOOR_SPACE_TOTAL : '',
        minMaxControlCost: item.KAWA_MINMAX_CONTROL_COST ? item.KAWA_MINMAX_CONTROL_COST : '',
        minMaxRepairCost: item.KAWA_MINMAX_REPAIR_COST ? item.KAWA_MINMAX_REPAIR_COST : '',
      },
      landTsubo: item.LAND_TSUBO ? item.LAND_TSUBO : '',
      landInsurance: item.LAND_KEISHA ? item.LAND_KEISHA : '',
      landNarrowPathway: item.LAND_ROJIJO ? item.LAND_ROJIJO : '',
      landKoatsusen: item.LAND_KOATSUSEN ? item.LAND_KOATSUSEN : '',
      landEquity: item.LAND_EQUITY ? item.LAND_EQUITY : '',
      setBack: item.SETBACK ? item.SETBACK : '',
      realPropertyRight: item.CHIEKI ? item.CHIEKI : '',
      rentedGround: item.RENTED_GROUND ? item.RENTED_GROUND : '',
      termLeasehold: item.TERM_LEASEHOLD ? item.TERM_LEASEHOLD : '',
      termLeaseholdRegistration: item.TERM_LEASEHOLD_REGISTRATION ? item.TERM_LEASEHOLD_REGISTRATION : '',
      termLeaseholdRentedPrice: item.TERM_LEASEHOLD_RENTED_PRICE ? item.TERM_LEASEHOLD_RENTED_PRICE : '',
      buildingPermitReason: item.BUILDING_PERMIT_REASON ? item.BUILDING_PERMIT_REASON : '',
      landPlanning: item.KOKUDO ? item.KOKUDO : '',
      licenceNumber: item.KAIHATUKYOKA_NO ? item.KAIHATUKYOKA_NO : '',
      conversionLicenseNumber: item.NOCHI_TENYO_KYOKA_NO ? item.NOCHI_TENYO_KYOKA_NO : '',
      build: item.NOT_BUILD ? item.NOT_BUILD : '',
      oneTimePayment: item.ICHIJIKIN ? item.ICHIJIKIN : '',
      runningCost: item.RUNNING_COST ? item.RUNNING_COST : '',
      neighborhoodAssociation: item.CHOKAIHI ? item.CHOKAIHI : '',
      hotSpring: item.ONSEN ? item.ONSEN : '',
      hotSpringDetail: item.ONSEN_DETAIL ? item.ONSEN_DETAIL : '',
      internetConnection: item.FTTH ? item.FTTH : '',
      cableTv: item.CATV ? item.CATV : '',
      imageTitle: item.IMAGE_TITLE ? item.IMAGE_TITLE : '',
      localWalkDistance: item.LOCAL_WALK_DISTANCE ? item.LOCAL_WALK_DISTANCE : '',
      floorSpaceApartment: item.FLOOR_SPACE_MANSION ? item.FLOOR_SPACE_MANSION : '',
      floorMansion: item.FLOOR_MANSION ? item.FLOOR_MANSION : '',
      costs: item.CUSTOS ? item.CUSTOS : '',
      organizationManagement: item.MANAGE_KUMIAI ? item.MANAGE_KUMIAI : '',
      parkingApartment: item.PARKING_MANSION ? item.PARKING_MANSION : '',
      parkingStructure: item.PARKING_KOZO ? item.PARKING_KOZO : '',
      parkingMotorcycles: item.PARKING_MOTORCYCLES ? item.PARKING_MOTORCYCLES : '',
      parkingBicycles: item.PARKING_BICYCLES ? item.PARKING_BICYCLES : '',
      parkingCondominium: item.PARKING_BUNJO ? item.PARKING_BUNJO : '',
      parkingCondominiumFund: item.PARKING_BUNJO_KIKIN ? item.PARKING_BUNJO_KIKIN : '',
      optimalDistrict: item.OPTIMAL_DISTRICT ? item.OPTIMAL_DISTRICT : '',
      buildingId: Number.isNaN(Number(item.BUILDING_ID)) ? 0 : Number(item.BUILDING_ID),
      equipment: {
        bath: item.EQUIP_BATH ? item.EQUIP_BATH : '',
        kitchen: item.EQUIP_KITCHEN ? item.EQUIP_KITCHEN : '',
        wash: item.EQUIP_WASH ? item.EQUIP_WASH : '',
        lifeLine: item.EQUIP_LIFELINE ? item.EQUIP_LIFELINE : '',
        security: item.EQUIP_SECURITY ? item.EQUIP_SECURITY : '',
        other: item.EQUIP_OTHER ? item.EQUIP_OTHER : '',
        room: item.EQUIP_ROOM ? item.EQUIP_ROOM : '',
      },
      constructionAndStructureMethod: item.NOTE_KOHO ? item.NOTE_KOHO : '',
      floorSpace: {
        firstFloor: item.FLOOR_SPACE_1F,
        secondFloor: item.FLOOR_SPACE_2F,
        thirdFloor: item.FLOOR_SPACE_3F,
        floorEtc: item.FLOOR_SPACE_ETC,
      },
      addressMap: item.ESTATE_ADDRESS_MAP ? item.ESTATE_ADDRESS_MAP : '',
      burdenFee: item.BURDEN_CHARGE ? item.BURDEN_CHARGE : '',
      burdenFeeRemark: item.BURDEN_CHARGE_REMARK ? item.BURDEN_CHARGE_REMARK : '',
      hasVr: item.VR_FLAG === '1',
      has360Vr: item.VR_360_FLAG === '1',
      land: {
        area: item.land?.area || 0,
        publicAreaType: null,
        restriction: item.land?.restriction ? {
          type: item.land.restriction.type,
          other: item.land?.restriction.other || '',
          suumo: item.land?.restriction.other || '', // Suumo it is a site that sell houses in Japan
        } : null,
      },
      garages: {
        car: item.garages?.car ? {
          availability: item.garages.car.availability,
          price: item.garages.car.price,
          distance: item.garages.car.distance,
          quantity: item.garages.car.quantity,
        } : null,
      },
      surroundings: item.surroundings,
      images: {
        layout: item.images?.layout ? item.images.layout : [''],
        exterior: item.images?.exterior ? item.images.exterior : [''],
      },
      areas: item.areas,
      rights: {
        ownership: item.rights?.ownership ? stringToEstateOwnership[item.rights.ownership] : null,
      },
      sellContract: {
        sellerId: null,
        sellerPassword: null,
        sellEstateId: null,
        transactionTypeId: null,
        sellerRegisterDate: null,
        mediateContractRegisterDate: null,
        finalMediationContractDate: null,
        renewalMediationContractDate: null,
      },
      newUsedTypeName: item.NEW_USED_TYPE_ID ? String(item.NEW_USED_TYPE_ID) : '',
      fc: {
        id: item.FC_ID ? Number(item.FC_ID) : 0,
      }
    };
  },

  async getEstateDetailOhEvent(form: { estateId: number }): Promise<OhEventEstateDetail> {
    const res = await axios.get('/getEstateDetailOhEvent', {params: {...form}});
    const response = res.data[0] as {
      ESTATE_ID: string,
      OH_EVENT_ID: string,
      OH_EVENT_TYPE_ID: string,
      OH_EVENT_TYPE: string,
      OH_EVENT_TITLE: string,
      OH_EVENT_STATUS: string,
      HOLIDAY_PG_FLAG: 0,
      EVENT_IMAGE_1: null,
      MAP_IMAGE: null,
      OH_ADDRESS: string,
      OH_DATE: string,
      OH_TIME: string,
      OH_EVENT_APPROACH: string | null,
      SORT_NO: string,
      PR: string,
      BIKO: string,
    };
    if (!response) throw new NotFoundError('Event Not Found');
    return {
      estateId: Number(response.ESTATE_ID),
      ohEventId: Number(response.OH_EVENT_ID),
      ohEventTypeId: Number(response.OH_EVENT_TYPE_ID),
      ohEventType: response.OH_EVENT_TYPE,
      ohEventTitle: response.OH_EVENT_TITLE,
      ohEventStatus: response.OH_EVENT_STATUS,
      holidayPgFlag: response.HOLIDAY_PG_FLAG,
      pr: response.PR ? response.PR : '',
      biko: response.BIKO ? response.BIKO : '',
      eventImage1: response.EVENT_IMAGE_1 ? response.EVENT_IMAGE_1 : '',
      mapImage: response.MAP_IMAGE ? response.MAP_IMAGE : '',
      ohAddress: response.OH_ADDRESS,
      ohDate: response.OH_DATE,
      ohTime: response.OH_TIME,
      ohEventApproach: response.OH_EVENT_APPROACH ? response.OH_EVENT_APPROACH : '',
      sortNo: response.SORT_NO,
    };
  },

  async getEstateLandLotDetails(form: {
    landLotId: number,
    buildingId: number
  }): Promise<LandLotDetails[]> {
    const res = await axios.get('/getEstateLandLotDetails', {params: {...form}});
    return res.data.map((item: {
      ESTATE_ID: string,
      FC_SHOP_ID: string,
      NEW_USED_TYPE_ID: string,
      ESTATE_TYPE_ID: string,
      PACKAGE_PART_TYPE_ID: string,
      SHUEKI: string,
      ESTATE_TYPE_DISP: string,
      ICON: string,
      ROOM_LAYOUT_IMAGE: string,
      EXTERIOR_IMAGE_1: string,
      ESTATE_PRICE_FORMAT: string,
      ESTATE_PRICE: string,
      HOUSE_ESTATE_PRICE_FORMAT: string,
      HOUSE_ESTATE_PRICE_UNIT: string,
      ROOM_LAYOUT: string,
      FLOOR_SPACE_TOTAL: string,
      LAND_SPACE_M2_PUBLIC: string,
      FLOOR_SPACE_MANSION: string,
    }) => ({
      estateId: Number(item.ESTATE_ID),
      shop: {
        id: Number(item.FC_SHOP_ID),
      },
      newUsedTypeId: Number(item.NEW_USED_TYPE_ID),
      estateTypeId: Number(item.ESTATE_TYPE_ID),
      packagePartTypeId: Number(item.PACKAGE_PART_TYPE_ID),
      businessFlag: item.SHUEKI === '1',
      estateTypeDisp: item.ESTATE_TYPE_DISP ? item.ESTATE_TYPE_DISP : '',
      icon: item.ICON ? item.ICON : '',
      roomLayoutImage: item.ROOM_LAYOUT_IMAGE ? item.ROOM_LAYOUT_IMAGE : '',
      exteriorImage1: item.EXTERIOR_IMAGE_1 ? item.EXTERIOR_IMAGE_1 : '',
      estatePriceFormatted: item.ESTATE_PRICE_FORMAT ? item.ESTATE_PRICE_FORMAT : '',
      estatePrice: item.ESTATE_PRICE ? item.ESTATE_PRICE : '',
      priceFormatted: item.HOUSE_ESTATE_PRICE_FORMAT ? item.HOUSE_ESTATE_PRICE_FORMAT : '',
      priceFormattedUnit: item.HOUSE_ESTATE_PRICE_UNIT ? item.HOUSE_ESTATE_PRICE_UNIT : '',
      roomLayout: item.ROOM_LAYOUT ? item.ROOM_LAYOUT : '',
      floorSpaceTotal: item.FLOOR_SPACE_TOTAL ? item.FLOOR_SPACE_TOTAL : '',
      landSpaceM2Public: item.LAND_SPACE_M2_PUBLIC ? item.LAND_SPACE_M2_PUBLIC : '',
      floorSpaceMansion: item.FLOOR_SPACE_MANSION ? item.FLOOR_SPACE_MANSION : '',
    }));
  },

  async getEstatePropertyLayoutDetails(form: {
    estateId: number
  }): Promise<PropertyLayoutDetails[]> {
    const res = await axios.get('/getEstateDetailPropertyLayout', {params: {...form}});
    const response = res.data as {
      SHOZAIKAI: string,
      FLOOR_SPACE: string,
      ROOM_TYPE: string | null,
      OUTPUT_ORDER: string
    }[];
    return response.map((item) => ({
      propertyLayout: item.SHOZAIKAI ? item.SHOZAIKAI : '',
      floorSpace: item.FLOOR_SPACE,
      roomType: item.ROOM_TYPE ? item.ROOM_TYPE : '',
      outputOrder: item.OUTPUT_ORDER,
    }));
  },

  async getEstateDetailEquipments(form: { estateId: number }): Promise<EstateEquipments> {
    const res = await axios.get('/getEstateDetailEquipments', {params: {...form}});
    const response = res.data[0] as {
      ESTATE_ID: string | null,
      EQUIP_ROOM: string | null,
      EQUIP_BATH: string | null,
      EQUIP_KITCHEN: string | null,
      EQUIP_WASH: string | null,
      EQUIP_LIFELINE: string | null,
      EQUIP_SECURITY: string | null,
      EQUIP_OTHER: string | null,
      SPECIAL: string | null,
      NOTE_KOHO: string | null,
      NOTE_HOSYO: string | null,
    };
    return {
      estateId: Number.isNaN(Number(response.ESTATE_ID)) ? 0 : Number(response.ESTATE_ID),
      equipment: {
        room: response.EQUIP_ROOM ? response.EQUIP_ROOM : '',
        bath: response.EQUIP_BATH ? response.EQUIP_BATH : '',
        kitchen: response.EQUIP_KITCHEN ? response.EQUIP_KITCHEN : '',
        wash: response.EQUIP_WASH ? response.EQUIP_WASH : '',
        lifeline: response.EQUIP_LIFELINE ? response.EQUIP_LIFELINE : '',
        security: response.EQUIP_SECURITY ? response.EQUIP_SECURITY : '',
        other: response.EQUIP_OTHER ? response.EQUIP_OTHER : '',
      },
      special: response.SPECIAL ? response.SPECIAL : '',
      lifelineDetails: response.NOTE_KOHO ? response.NOTE_KOHO : '',
      equipmentDetails: response.NOTE_HOSYO ? response.NOTE_HOSYO : '',
    };
  },

  async getEstateLandLotApartmentDetails(form: {
    estateId: number,
    buildingId?: number
  }): Promise<ApartmentDetails> {
    const res = await axios.get('/getEstateApartmentDetails', {params: {...form}});
    const response = res.data[0] as {
      BUILDING_ID: string | null,
      ESTATE_STATUS: string,
      ESTATE_ZIP: string,
      ESTATE_ADDRESS_DISP: string,
      AD_MODIFY_DATE: string,
      AD_NEXT_MODIFY_DATE: string,
      TRADE_TERM_EXPIRE_DATE: string | null,
      RAIL_NAME1: string | null,
      STATION_NAME1: string | null,
      STATION_ACCESS1: string | null,
      RAIL_NAME2: string | null,
      STATION_NAME2: string | null,
      STATION_ACCESS2: string | null,
      RAIL_NAME3: string | null,
      STATION_NAME3: string | null,
      STATION_ACCESS3: string | null,
      RAIL_NAME4: string | null,
      STATION_NAME4: string | null,
      STATION_ACCESS4: string | null,
      EXTERIOR_IMAGE_1: string | null,
      BIKO: string | null,
      LATITUDE: number,
      LONGITUDE: number,
      TOTAL_SALES_HOUSE: string | null,
      TOTAL_ESTATE_QUANTITYE: string | null,
      MANSION_MINMAX_PRICE: string | null,
      MANSION_MINMAX_PRICE_FORMATTED: string | null,
      MIN_ESTATE_PRICE: string | null,
      MAX_ESTATE_PRICE: string | null,
      MANSION_MAJORITY_PRICE: string | null,
      MANSION_MAJORITY_PRICE_FORMATTED: string | null,
      MANSION_MINMAX_AREA: string | null,
      MANSION_MINMAX_KANRIHI: string | null,
      MANSION_MINMAX_TSUMITATEKIN: string | null,
      BURDEN_CHARGE: string | null,
      BURDEN_CHARGE_REMARK: string,
      CITY_PLAN: string | null,
      BUILDING_PERMIT_REASON: string | null,
      USE_DISTRICT: string | null,
      BUILDING_COVERAGE: string | null,
      PLOT_RATIO: string | null,
      CHISEI: string | null,
      KAIHATUKYOKA_NO: string | null,
      NOCHI_TENYO_KYOKA_NO: string | null,
      ELEMENTARYSCHOOL: string | null,
      JUNIORHIGHSCHOOL: string | null,
      LOWS_NOTE: string | null,
      TRADE_TRANSACTION: string,
      FC_NAME: string,
      FC_SHOP_NAME: string,
      FC_SHOP_MAIN_TEL: string,
      ZIP_CODE: string,
      FC_SHOP_ADDRESS: string,
      BUSINESS_HOUR: string,
      HOLIDAY: string,
      LICENSE_NUMBER: string,
      SHOP_IMAGE: string,
      SHOP_MAP_IMAGE: string
    };
    if (!response) throw new NotFoundError('Apartment Not Found');
    return {
      buildingId: response.BUILDING_ID ? Number(response.BUILDING_ID) : null,
      estateStatus: response.ESTATE_STATUS,
      estateZipCode: response.ESTATE_ZIP,
      estateAddress: response.ESTATE_ADDRESS_DISP,
      adModifyDate: response.AD_MODIFY_DATE,
      adNextModifyDate: response.AD_NEXT_MODIFY_DATE,
      tradeTermExpireDate: response.TRADE_TERM_EXPIRE_DATE ? response.TRADE_TERM_EXPIRE_DATE : '',
      access: [
        {
          railName: response.RAIL_NAME1 ? response.RAIL_NAME1 : '',
          stationName: response.STATION_NAME1 ? response.STATION_NAME1 : '',
          stationAccess: response.STATION_ACCESS1 ? response.STATION_ACCESS1 : '',
        },
        {
          railName: response.RAIL_NAME2 ? response.RAIL_NAME2 : '',
          stationName: response.STATION_NAME2 ? response.STATION_NAME2 : '',
          stationAccess: response.STATION_ACCESS2 ? response.STATION_ACCESS2 : '',
        },
        {
          railName: response.RAIL_NAME3 ? response.RAIL_NAME3 : '',
          stationName: response.STATION_NAME3 ? response.STATION_NAME3 : '',
          stationAccess: response.STATION_ACCESS3 ? response.STATION_ACCESS3 : '',
        },
        {
          railName: response.RAIL_NAME4 ? response.RAIL_NAME4 : '',
          stationName: response.STATION_NAME4 ? response.STATION_NAME4 : '',
          stationAccess: response.STATION_ACCESS4 ? response.STATION_ACCESS4 : '',
        },
      ].filter((item) => item.railName || item.stationName || item.stationAccess),
      exteriorImage: response.EXTERIOR_IMAGE_1 ? response.EXTERIOR_IMAGE_1 : '',
      biko: response.BIKO ? response.BIKO : '',
      latitude: response.LATITUDE,
      longitude: response.LONGITUDE,
      totalSalesHouse: response.TOTAL_SALES_HOUSE ? response.TOTAL_SALES_HOUSE : '',
      totalEstateQuantity: response.TOTAL_ESTATE_QUANTITYE ? response.TOTAL_ESTATE_QUANTITYE : '',
      apartmentPriceRange: response.MANSION_MINMAX_PRICE ? response.MANSION_MINMAX_PRICE : '',
      apartmentPriceRangeFormatted: response.MANSION_MINMAX_PRICE_FORMATTED ? response.MANSION_MINMAX_PRICE_FORMATTED : '',
      minEstatePrice: response.MIN_ESTATE_PRICE ? response.MIN_ESTATE_PRICE : '',
      maxEstatePrice: response.MAX_ESTATE_PRICE ? response.MAX_ESTATE_PRICE : '',
      apartmentMajorityPrice: response.MANSION_MAJORITY_PRICE ? response.MANSION_MAJORITY_PRICE : '',
      apartmentMajorityPriceFormatted: response.MANSION_MAJORITY_PRICE_FORMATTED ? response.MANSION_MAJORITY_PRICE_FORMATTED : '',
      apartmentRangeArea: response.MANSION_MINMAX_AREA ? response.MANSION_MINMAX_AREA : '',
      managementTaxRange: response.MANSION_MINMAX_KANRIHI ? response.MANSION_MINMAX_KANRIHI : '',
      repairCostRange: response.MANSION_MINMAX_TSUMITATEKIN ? response.MANSION_MINMAX_TSUMITATEKIN : '',
      burdenTax: response.BURDEN_CHARGE ? response.BURDEN_CHARGE : '',
      burdenTaxDetail: response.BURDEN_CHARGE_REMARK ? response.BURDEN_CHARGE_REMARK : '',
      cityPlanning: response.CITY_PLAN ? response.CITY_PLAN : '',
      buildingPermitReason: response.BUILDING_PERMIT_REASON ? response.BUILDING_PERMIT_REASON : '',
      useDistrict: response.USE_DISTRICT ? response.USE_DISTRICT : '',
      buildingCoverage: response.BUILDING_COVERAGE ? response.BUILDING_COVERAGE : '',
      plotRatio: response.PLOT_RATIO ? response.PLOT_RATIO : '',
      topography: response.CHISEI ? response.CHISEI : '',
      licenceNumber: response.KAIHATUKYOKA_NO ? response.KAIHATUKYOKA_NO : '',
      conversionLicenseNumber: response.NOCHI_TENYO_KYOKA_NO ? response.NOCHI_TENYO_KYOKA_NO : '',
      elementarySchool: response.ELEMENTARYSCHOOL ? response.ELEMENTARYSCHOOL : '',
      juniorHighSchool: response.JUNIORHIGHSCHOOL ? response.JUNIORHIGHSCHOOL : '',
      lowsNote: response.LOWS_NOTE ? response.LOWS_NOTE : '',
      tradeTransaction: response.TRADE_TRANSACTION,
      shop: {
        fcName: response.FC_NAME,
        name: response.FC_SHOP_NAME,
        mainTel: response.FC_SHOP_MAIN_TEL,
        zipCode: response.ZIP_CODE,
        address: response.FC_SHOP_ADDRESS,
        businessHour: response.BUSINESS_HOUR,
        dayOff: response.HOLIDAY,
        licenseNumber: response.LICENSE_NUMBER,
        image: response.SHOP_IMAGE,
        mapImage: response.SHOP_MAP_IMAGE,
      },
    };
  },

  async getLandLotHouseAndLandDetail(form: { landLotId: number }): Promise<HouseAndLandDetails> {
    const res = await axios.get('/getEstateHouseAndLandDetails', {params: {landLotId: form.landLotId}});
    const response = res.data[0] as {
      KAWA_ID: string,
      KAWA_NAME: string,
      ESTATE_STATUS: string,
      ESTATE_ZIP: string,
      ESTATE_ADDRESS_LIST: string,
      ESTATE_ADDRESS_DISP: string,
      AD_MODIFY_DATE: string,
      AD_NEXT_MODIFY_DATE: string,
      TRADE_TERM_EXPIRE_DATE: string,
      RAIL_NAME1: string,
      STATION_NAME1: string,
      STATION_ACCESS1: string,
      RAIL_NAME2: string,
      STATION_NAME2: string,
      STATION_ACCESS2: string,
      RAIL_NAME3: string,
      STATION_NAME3: string,
      STATION_ACCESS3: string,
      RAIL_NAME4: string,
      STATION_NAME4: string,
      STATION_ACCESS4: string,
      KAWA_IMAGE: string | null,
      MAP_IMAGE: string,
      PR_IMAGE: string,
      BIKO: string,
      MAP_PIN_FLG: string,
      ESTATE_ADDRESS_MAP: string,
      LATITUDE: string,
      LONGITUDE: string,
      TOTAL_SALES_HOUSE: string,
      TOTAL_ESTATE_QUANTITYE: string,
      KAWA_MINMAX_PRICE: string,
      KAWA_MINMAX_PRICE_FORMATTED: string,
      MIN_ESTATE_PRICE: string,
      MAX_ESTATE_PRICE: string,
      KAWA_MAJORITY_PRICE: string,
      KAWA_MAJORITY_PRICE_FORMATTED: string,
      KAWA_MINMAX_AREA: string,
      KAWA_FLOOR_SPACE: string,
      BUILDING_RESTRICT: string,
      BURDEN_CHARGE: string | null,
      BURDEN_CHARGE_REMARK: string,
      CITY_PLAN: string,
      BUILDING_PERMIT_REASON: string,
      USE_DISTRICT: string,
      BUILDING_COVERAGE: string,
      PLOT_RATIO: string,
      OPTIMAL_DISTRICT_NAME: string | null,
      CHISEI: string,
      KAIHATUKYOKA_NO: string,
      NOCHI_TENYO_KYOKA_NO: string,
      KOKUDO: string,
      ELEMENTARYSCHOOL: string,
      JUNIORHIGHSCHOOL: string,
      LOWS_NOTE: string,
      EQUIPMENT: string,
      NOTE: string,
      TRADE_TRANSACTION: string,
      FC_NAME: string,
      FC_SHOP_NAME: string,
      FC_SHOP_MAIN_TEL: string,
      FC_SHOP_FREE_DIAL_ICON: string,
      FC_SHOP_ZIP_CODE: string,
      FC_SHOP_ADDRESS: string,
      FC_SHOP_TEL: string,
      FC_SHOP_FAX: string,
      FC_SHOP_MAIL_ADDRESS: string,
      SHOP_ACCESS1: string | null,
      SHOP_ACCESS2: string | null,
      SHOP_ACCESS3: string | null,
      SHOP_ACCESS4: string | null,
      SHOP_PARKING: string | null,
      BUSINESS_HOUR: string,
      HOLIDAY: string,
      SHOP_MANAGER: string,
      LICENSE_NUMBER: string,
      JOIN_GROUP1: string,
      JOIN_GROUP2: string,
      JOIN_GROUP3: string,
      SHOP_IMAGE: string,
      SHOP_IMAGE2: string,
      SHOP_MAP_IMAGE: string | null,
      SHOP_LATITUDE: string,
      SHOP_LONGITUDE: string,
      RESTRICT_ESTATE_COUNT_TOTAL: string,
      RESTRICT_ESTATE_COUNT_SALES: string,
      RESTRICT_MIN_ESTATE_PRICE: string | null,
      RESTRICT_MAX_ESTATE_PRICE: string | null,
      RESTRICT_KAWA_MINMAX_PRICE: string | null,
      RESTRICT_KAWA_MINMAX_PRICE_FORMATTED: string | null,
      RESTRICT_KAWA_MINMAX_AREA: string | null,
      RESTRICT_KAWA_MINMAX_TSUBO: string | null,
      NON_RESTRICT_ESTATE_COUNT_TOTAL: string,
      NON_RESTRICT_ESTATE_COUNT_SALES: string,
      NON_RESTRICT_MIN_ESTATE_PRICE: string,
      NON_RESTRICT_MAX_ESTATE_PRICE: string,
      NON_RESTRICT_KAWA_MINMAX_PRICE: string,
      NON_RESTRICT_KAWA_MINMAX_PRICE_FORMATTED: string,
      NON_RESTRICT_KAWA_MINMAX_AREA: string,
      NON_RESTRICT_KAWA_MINMAX_TSUBO: string,
      TATE_ESTATE_COUNT_TOTAL: string,
      TATE_ESTATE_COUNT_SALES: string,
      TATE_MIN_ESTATE_PRICE: string | null,
      TATE_MAX_ESTATE_PRICE: string | null,
      TATE_KAWA_MINMAX_PRICE: string | null,
      TATE_KAWA_MINMAX_PRICE_FORMATTED: string | null,
      TATE_KAWA_MINMAX_AREA: string | null,
      TATE_KAWA_MINMAX_TSUBO: string | null,
      TATE_KAWA_FLOOR_SPACE: string | null,
      TATE_KAWA_FLOOR_TSUBO: string | null
    };
    return {
      landLot: {
        id: Number(response.KAWA_ID),
        name: response.KAWA_NAME ? response.KAWA_NAME : '',
        priceRange: response.KAWA_MINMAX_PRICE ? response.KAWA_MINMAX_PRICE : '',
        priceRangeFormatted: response.KAWA_MINMAX_PRICE_FORMATTED ? response.KAWA_MINMAX_PRICE_FORMATTED : '',
        majorityPrice: response.KAWA_MAJORITY_PRICE ? response.KAWA_MAJORITY_PRICE : '',
        majorityPriceFormatted: response.KAWA_MAJORITY_PRICE_FORMATTED ? response.KAWA_MAJORITY_PRICE_FORMATTED : '',
        areaRange: response.KAWA_MINMAX_AREA ? response.KAWA_MINMAX_AREA : '',
        floorSpace: response.KAWA_FLOOR_SPACE ? response.KAWA_FLOOR_SPACE : '',
      },
      estate: {
        status: response.ESTATE_STATUS ? response.ESTATE_STATUS : '',
        zipCode: response.ESTATE_ZIP ? response.ESTATE_ZIP : '',
        addressList: response.ESTATE_ADDRESS_LIST ? response.ESTATE_ADDRESS_LIST : '',
        addressDisplay: response.ESTATE_ADDRESS_DISP ? response.ESTATE_ADDRESS_DISP : '',
        addressMap: response.ESTATE_ADDRESS_MAP ? response.ESTATE_ADDRESS_MAP : '',
        minPrice: response.MIN_ESTATE_PRICE ? response.MIN_ESTATE_PRICE : '',
        maxPrice: response.MAX_ESTATE_PRICE ? response.MAX_ESTATE_PRICE : '',
      },
      adModifyDate: response.AD_MODIFY_DATE ? response.AD_MODIFY_DATE : '',
      adNextModifyDate: response.AD_NEXT_MODIFY_DATE ? response.AD_NEXT_MODIFY_DATE : '',
      tradeTermExpireDate: response.TRADE_TERM_EXPIRE_DATE ? response.TRADE_TERM_EXPIRE_DATE : '',
      access: [
        {
          railName: response.RAIL_NAME1 ? response.RAIL_NAME1 : '',
          stationName: response.STATION_NAME1 ? response.STATION_NAME1 : '',
          stationAccess: response.STATION_ACCESS1 ? response.STATION_ACCESS1 : '',
        },
        {
          railName: response.RAIL_NAME2 ? response.RAIL_NAME2 : '',
          stationName: response.STATION_NAME2 ? response.STATION_NAME2 : '',
          stationAccess: response.STATION_ACCESS2 ? response.STATION_ACCESS2 : '',
        },
        {
          railName: response.RAIL_NAME3 ? response.RAIL_NAME3 : '',
          stationName: response.STATION_NAME3 ? response.STATION_NAME3 : '',
          stationAccess: response.STATION_ACCESS3 ? response.STATION_ACCESS3 : '',
        },
        {
          railName: response.RAIL_NAME4 ? response.RAIL_NAME4 : '',
          stationName: response.STATION_NAME4 ? response.STATION_NAME4 : '',
          stationAccess: response.STATION_ACCESS4 ? response.STATION_ACCESS4 : '',
        },
      ].filter(i => !!i.railName || !!i.stationName || !!i.stationAccess),
      landLotImage: response.KAWA_IMAGE ? response.KAWA_IMAGE : '',
      mapImage: response.MAP_IMAGE ? response.MAP_IMAGE : '',
      prImage: response.PR_IMAGE ? response.PR_IMAGE : '',
      biko: response.BIKO,
      hasPinFlag: !!response.MAP_PIN_FLG,
      latitude: Number(response.LATITUDE),
      longitude: Number(response.LONGITUDE),
      totalSalesHouse: response.TOTAL_SALES_HOUSE ? response.TOTAL_SALES_HOUSE : '',
      totalEstateQuantity: response.TOTAL_ESTATE_QUANTITYE ? response.TOTAL_ESTATE_QUANTITYE : '',
      buildingRestrictions: response.BUILDING_RESTRICT ? response.BUILDING_RESTRICT : '',
      burdenCharge: response.BURDEN_CHARGE ? response.BURDEN_CHARGE : '',
      burdenChargeRemake: response.BURDEN_CHARGE_REMARK ? response.BURDEN_CHARGE_REMARK : '',
      cityPlanning: response.CITY_PLAN ? response.CITY_PLAN : '',
      buildingPermitReason: response.BUILDING_PERMIT_REASON,
      useDistrict: response.USE_DISTRICT ? response.USE_DISTRICT : '',
      buildingCoverage: response.BUILDING_COVERAGE ? response.BUILDING_COVERAGE : '',
      plotRatio: response.PLOT_RATIO ? response.PLOT_RATIO : '',
      optimalDistrictName: response.OPTIMAL_DISTRICT_NAME ? response.OPTIMAL_DISTRICT_NAME : '',
      topography: response.CHISEI ? response.CHISEI : '',
      licenceNumber: response.KAIHATUKYOKA_NO ? response.KAIHATUKYOKA_NO : '',
      conversionLicenseNumber: response.NOCHI_TENYO_KYOKA_NO ? response.NOCHI_TENYO_KYOKA_NO : '',
      landPlanning: response.KOKUDO ? response.KOKUDO : '',
      elementarySchool: response.ELEMENTARYSCHOOL ? response.ELEMENTARYSCHOOL : '',
      juniorHighSchool: response.JUNIORHIGHSCHOOL ? response.JUNIORHIGHSCHOOL : '',
      lowsNote: response.LOWS_NOTE ? response.LOWS_NOTE : '',
      equipment: response.EQUIPMENT ? response.EQUIPMENT : '',
      note: response.NOTE ? response.NOTE : '',
      tradeTransaction: response.TRADE_TRANSACTION ? response.TRADE_TRANSACTION : '',
      shop: {
        fcName: response.FC_NAME,
        name: response.FC_SHOP_NAME,
        mainTel: response.FC_SHOP_MAIN_TEL,
        hasFreeDialIcon: !!response.FC_SHOP_FREE_DIAL_ICON,
        zipCode: response.FC_SHOP_ZIP_CODE,
        address: response.FC_SHOP_ADDRESS,
        tel: response.FC_SHOP_TEL,
        fax: response.FC_SHOP_FAX,
        mail: response.FC_SHOP_MAIL_ADDRESS,
        access: [
          response.SHOP_ACCESS1 ? response.SHOP_ACCESS1 : '',
          response.SHOP_ACCESS2 ? response.SHOP_ACCESS2 : '',
          response.SHOP_ACCESS3 ? response.SHOP_ACCESS3 : '',
          response.SHOP_ACCESS4 ? response.SHOP_ACCESS4 : '',
        ].filter((i) => !!i),
        parking: response.SHOP_PARKING ? response.SHOP_PARKING : '',
        businessHour: response.BUSINESS_HOUR ? response.BUSINESS_HOUR : '',
        holiday: response.HOLIDAY ? response.HOLIDAY : '',
        manager: response.SHOP_MANAGER ? response.SHOP_MANAGER : '',
        licenseNumber: response.LICENSE_NUMBER ? response.LICENSE_NUMBER : '',
        joinGroup: [
          response.JOIN_GROUP1 ? response.JOIN_GROUP1 : '',
          response.JOIN_GROUP2 ? response.JOIN_GROUP2 : '',
          response.JOIN_GROUP3 ? response.JOIN_GROUP3 : '',
        ].filter((i) => !!i),
        image: [
          response.SHOP_IMAGE ? response.SHOP_IMAGE : '',
          response.SHOP_IMAGE2 ? response.SHOP_IMAGE2 : '',
        ].filter((i) => !!i),
        mapImage: response.SHOP_MAP_IMAGE ? response.SHOP_MAP_IMAGE : '',
        latitude: response.SHOP_LATITUDE ? Number(response.SHOP_LATITUDE) : 0,
        longitude: response.SHOP_LONGITUDE ? Number(response.SHOP_LONGITUDE) : 0,
      },
      restrictEstateCountTotal: response.RESTRICT_ESTATE_COUNT_TOTAL ? Number(response.RESTRICT_ESTATE_COUNT_TOTAL) : 0,
      restrictEstateCountSales: response.RESTRICT_ESTATE_COUNT_SALES ? Number(response.RESTRICT_ESTATE_COUNT_SALES) : 0,
      restrictMinEstatePrice: Number.isNaN(Number(response.RESTRICT_MIN_ESTATE_PRICE)) ? 0 : Number(response.RESTRICT_MIN_ESTATE_PRICE),
      restrictMaxEstatePrice: Number.isNaN(Number(response.RESTRICT_MAX_ESTATE_PRICE)) ? 0 : Number(response.RESTRICT_MAX_ESTATE_PRICE),
      restrictLandLotPriceRange: response.RESTRICT_KAWA_MINMAX_PRICE ? response.RESTRICT_KAWA_MINMAX_PRICE : '',
      restrictLandLotPriceRangeFormatted: response.RESTRICT_KAWA_MINMAX_PRICE_FORMATTED ? response.RESTRICT_KAWA_MINMAX_PRICE_FORMATTED : '',
      restrictLandLotAreaRange: response.RESTRICT_KAWA_MINMAX_AREA ? response.RESTRICT_KAWA_MINMAX_AREA : '',
      restrictLandLotTsubo: response.RESTRICT_KAWA_MINMAX_TSUBO ? response.RESTRICT_KAWA_MINMAX_TSUBO : '',
      nonRestrictEstateCountTotal: response.NON_RESTRICT_ESTATE_COUNT_TOTAL ? Number(response.NON_RESTRICT_ESTATE_COUNT_TOTAL) : 0,
      nonRestrictEstateCountSales: response.NON_RESTRICT_ESTATE_COUNT_SALES ? Number(response.NON_RESTRICT_ESTATE_COUNT_SALES) : 0,
      nonRestrictMinEstatePrice: response.NON_RESTRICT_MIN_ESTATE_PRICE ? Number(response.NON_RESTRICT_MIN_ESTATE_PRICE) : 0,
      nonRestrictMaxEstatePrice: response.NON_RESTRICT_MAX_ESTATE_PRICE ? Number(response.NON_RESTRICT_MAX_ESTATE_PRICE) : 0,
      nonRestrictLandLotPriceRange: response.NON_RESTRICT_KAWA_MINMAX_PRICE ? response.NON_RESTRICT_KAWA_MINMAX_PRICE : '',
      nonRestrictLandLotPriceRangeFormatted: response.NON_RESTRICT_KAWA_MINMAX_PRICE_FORMATTED ? response.NON_RESTRICT_KAWA_MINMAX_PRICE_FORMATTED : '',
      nonRestrictLandLotAreaRange: response.NON_RESTRICT_KAWA_MINMAX_AREA ? response.NON_RESTRICT_KAWA_MINMAX_AREA : '',
      nonRestrictLandLotTsubo: response.NON_RESTRICT_KAWA_MINMAX_TSUBO ? response.NON_RESTRICT_KAWA_MINMAX_TSUBO : '',
      buildingEstateTotalCount: response.TATE_ESTATE_COUNT_TOTAL ? Number(response.TATE_ESTATE_COUNT_TOTAL) : 0,
      buildingEstateSalesCount: response.TATE_ESTATE_COUNT_SALES ? Number(response.TATE_ESTATE_COUNT_SALES) : 0,
      buildingEstateMinPrice: response.TATE_MIN_ESTATE_PRICE ? Number(response.TATE_MIN_ESTATE_PRICE) : 0,
      buildingEstateMaxPrice: response.TATE_MAX_ESTATE_PRICE ? Number(response.TATE_MAX_ESTATE_PRICE) : 0,
      buildingLandLotPriceRange: response.TATE_KAWA_MINMAX_PRICE ? response.TATE_KAWA_MINMAX_PRICE : '',
      buildingLandLotPriceRangeFormatted: response.TATE_KAWA_MINMAX_PRICE_FORMATTED ? response.TATE_KAWA_MINMAX_PRICE_FORMATTED : '',
      buildingLandLotAreaRange: response.TATE_KAWA_MINMAX_AREA ? response.TATE_KAWA_MINMAX_AREA : '',
      buildingLandLotTsuboRange: response.TATE_KAWA_MINMAX_TSUBO ? response.TATE_KAWA_MINMAX_TSUBO : '',
      buildingLandLotFloorSpace: response.TATE_KAWA_FLOOR_SPACE ? response.TATE_KAWA_FLOOR_SPACE : '',
      buildingLandLotTsubo: response.TATE_KAWA_FLOOR_TSUBO ? response.TATE_KAWA_FLOOR_TSUBO : '',
    };
  },

  async countEstateHistory(params: ListDoNetworkEstateForm): Promise<number> {
    const res = await axios.get('/estate/historyCount', {
      params: {
        estateTypes: params.condition ? `${params.condition}_${romajiEstateTypeMap[params.estateType as EstateType]}`
          : romajiEstateTypeMap[params.estateType as EstateType],
        prefCityIds: params.prefCities ? formatPrefCities(params.prefCities) : undefined,
        order: params.order,
        limit: formatLimit({limit: params.limit, page: params.page}),
        openHouseId: params.openHouseId,
        ohEventId: params.ohEventId,
        ohEventTypeId: params.ohEventTypeId,
        typeId: params.typeId,
        fcShopId: params.fcShopId,
        sendDate: params.sendDate ? DateTime.fromJSDate(params.sendDate).toFormat('yyyy-MM-dd') : undefined,
        hpFlag: params.onlyMe !== undefined ? Number(params.onlyMe) : undefined,
        roomType: params.roomTypes ? [...new Set(params.roomTypes)].join(',') : undefined,
        priceMin: params.minPrice,
        priceMax: params.maxPrice,
        walkMin: params.stationDistance,
        landUnderSpace: params.landMinArea,
        landHighSpace: params.landMaxArea,
        tsuboTanka: params.squareMeterPrice,
        tatemonoUnderSpace: params.buildingMinArea,
        tatemonoHighSpace: params.buildingMaxArea,
        senyuUnderSpace: params.propertyConstructMinArea,
        senyuHighSpace: params.propertyConstructMaxArea,
        completion: params.years,
      },
    });
    const data = res.data as number;
    return Number(data);
  },

  async getEstateHistory(params: ListDoNetworkEstateForm): Promise<Estate[]> {
    const res = await axios.get<ListDoNetworkEstateResponse[]>('/estate/history', {
      params: {
        estateTypes: params.condition ? `${params.condition}_${romajiEstateTypeMap[params.estateType as EstateType]}`
          : romajiEstateTypeMap[params.estateType as EstateType],
        prefCityIds: params.prefCities ? formatPrefCities(params.prefCities) : undefined,
        order: params.order,
        openHouseId: params.openHouseId,
        ohEventId: params.ohEventId,
        ohEventTypeId: params.ohEventTypeId,
        typeId: params.typeId,
        fcShopId: params.fcShopId,
        sendDate: params.sendDate ? DateTime.fromJSDate(params.sendDate).toFormat('yyyy-MM-dd') : undefined,
        limit: formatLimit({limit: params.limit, page: params.page}),
        hpFlag: params.onlyMe !== undefined ? Number(params.onlyMe) : undefined,
        roomType: params.roomTypes ? [...new Set(params.roomTypes)].join(',') : undefined,
        priceMin: params.minPrice,
        priceMax: params.maxPrice,
        walkMin: params.stationDistance,
        landUnderSpace: params.landMinArea,
        landHighSpace: params.landMaxArea,
        tsuboTanka: params.squareMeterPrice,
        tatemonoUnderSpace: params.buildingMinArea,
        tatemonoHighSpace: params.buildingMaxArea,
        senyuUnderSpace: params.propertyConstructMinArea,
        senyuHighSpace: params.propertyConstructMaxArea,
        floorAboveQuantity: params.floors,
        completion: params.years,
      },
    });
    return res.data.map((item) => convertListDoNetworkEstateResponse(item));
  },

  async getFavoriteEstates(params: ListDoNetworkEstateForm): Promise<Estate[]> {
    const res = await axios.get<ListDoNetworkEstateResponse[]>('/estates/favorites', {
      params: {
        estateTypes: params.condition ? `${params.condition}_${romajiEstateTypeMap[params.estateType as EstateType]}`
          : romajiEstateTypeMap[params.estateType as EstateType],
        prefCityIds: params.prefCities ? formatPrefCities(params.prefCities) : undefined,
        order: params.order,
        openHouseId: params.openHouseId,
        ohEventId: params.ohEventId,
        ohEventTypeId: params.ohEventTypeId,
        typeId: params.typeId,
        fcShopId: params.fcShopId,
        sendDate: params.sendDate ? DateTime.fromJSDate(params.sendDate).toFormat('yyyy-MM-dd') : undefined,
        limit: formatLimit({limit: params.limit, page: params.page}),
        hpFlag: params.onlyMe !== undefined ? Number(params.onlyMe) : undefined,
        roomType: params.roomTypes ? [...new Set(params.roomTypes)].join(',') : undefined,
        priceMin: params.minPrice,
        priceMax: params.maxPrice,
        walkMin: params.stationDistance,
        landUnderSpace: params.landMinArea,
        landHighSpace: params.landMaxArea,
        tsuboTanka: params.squareMeterPrice,
        tatemonoUnderSpace: params.buildingMinArea,
        tatemonoHighSpace: params.buildingMaxArea,
        senyuUnderSpace: params.propertyConstructMinArea,
        senyuHighSpace: params.propertyConstructMaxArea,
        floorAboveQuantity: params.floors,
        completion: params.years,
      },
    });
    return res.data.map((item) => convertListDoNetworkEstateResponse(item));
  },
  async getFavoriteEstateIds(): Promise<number[]> {
    const res = await axios.get('/estate/favorites/idList/');
    const data = res.data as { estates: { id: number}[]};
    return data.estates.map((item) => item.id);
  },

  favoriteEstate(form: FavoriteEstateForm): Promise<void> {
    return axios.post('/estate/favorites/register/', {
      id: form.id,
    });
  },
  unfavoriteEstate(form: UnfavoriteEstateForm): Promise<void> {
    return axios.post('/estate/favorites/delete/', {
      id: form.id,
    });
  },
  async getEstateDetailOuterImages(form: { estateId: number }): Promise<EstateDetailOuterImage[]> {
    const response = await DoNetworkAPI.get('/getEstateDetailOuterImages', {
      params: {estateId: form.estateId},
    });
    const res = response.data as {
      IMAGE_SERVICE_ID: string,
      PATH: string,
      TAG: string | null,
      IMAGE_SERVICE_TYPE_ID: string,
      DIVISION_ID: string,
      DIVISION_NAME: string,
      DIVISION_ORDER: string,
      PR: string,
      OUTPUT_ORDER: string
    }[];
    return res.map((item) => ({
      imageServiceId: Number(item.IMAGE_SERVICE_ID),
      path: item.PATH,
      tag: item.TAG ? item.TAG : '',
      imageServiceTypeId: Number(item.IMAGE_SERVICE_TYPE_ID),
      divisionId: Number(item.DIVISION_ID),
      divisionName: item.DIVISION_NAME,
      divisionOrder: item.DIVISION_ORDER,
      pr: item.PR,
      outputOrder: item.OUTPUT_ORDER,
    }));
  },
  pushEstateToHistory(form: { estateId: number }): Promise<void> {
    return axios.post('/estate/history', form);
  },
  async listEstatesByIds(form: { estateIds: number[] }): Promise<PaginateEstate> {
    const response = await axios.get('/estate/list/news', {
      params: {estateIds: form.estateIds.join(','), limit: 1000, page: 1},
    });
    const res = response.data as {
      page: number,
      pageSize: number,
      total: number,
      estates: ListDoNetworkEstateResponse[]
    };
    return {
      total: res.total,
      estates: res.estates.map((item) => convertListDoNetworkEstateResponse(item)),
    };
  },

  async estateList(params: ListEstateForm): Promise<PaginateEstate> {
    const prefCities = params.prefCities ? formatPrefCities(params.prefCities) : undefined;
    const response = await axios.post('/estate/list/', {
        estateTypes: params.condition ? `${params.condition}_${romajiEstateTypeMap[params.estateType as EstateType]}`
          : romajiEstateTypeMap[params.estateType as EstateType],
        prefCityIds: prefCities || undefined,
        prefIds: params.prefectures && params.prefectures.length ? params.prefectures.map(pref => pref.id).join(',') : undefined,
        order: params.order,
        openHouseId: params.openHouseId,
        ohEventId: params.ohEventId,
        ohEventTypeId: params.ohEventTypeId,
        typeId: params.typeId,
        fcShopId: params.fcShopId,
        sendDate: params.sendDate ? DateTime.fromJSDate(params.sendDate).toFormat('yyyy-MM-dd') : undefined,
        limit: formatLimit({limit: params.limit, page: params.page}),
        hpFlag: params.onlyMe !== undefined ? Number(params.onlyMe) : undefined,
        room: params.room,
        priceMin: params.minPrice,
        priceMax: params.maxPrice,
        walkMin: params.stationDistance,
        landUnderSpace: params.landMinArea,
        landHighSpace: params.landMaxArea,
        tsuboTanka: params.squareMeterPrice,
        senyuUnderSpace: params.propertyConstructMinArea,
        senyuHighSpace: params.propertyConstructMaxArea,
        tatemonoUnderSpace: params.buildingMinArea,
        tatemonoHighSpace: params.buildingMaxArea,
        floorAboveQuantity: params.floors,
        completion: params.years,
        estateIds: params.estateIds?.join(','),
        land: params.searchForTypes?.land || undefined,
        newHouse: params.searchForTypes?.newHouse || undefined,
        usedHouse: params.searchForTypes?.usedHouse || undefined,
        newApartment: params.searchForTypes?.newApartment || undefined,
        usedApartment: params.searchForTypes?.usedApartment || undefined,
        business: params.searchForTypes?.business || undefined,
        isRentable: params.isRentable,
        stations: params.stations,
        parking: params.parking,
        towns: params.towns?.length ? params.towns : undefined,
        page: params.page,
        pageSize: params.limit,
      });

    const data = response.data as {
      page: number,
      pageSize: number,
      total: number,
      estates: ListDoNetworkEstateResponse[]
    };
    return {
      total: data.total,
      estates: data.estates.map((item) => convertListDoNetworkEstateResponse(item)),
    };
  },

  async estateFavoriteList(params: ListDoNetworkEstateForm): Promise<PaginateEstate> {
    const prefCities = params.prefCities ? formatPrefCities(params.prefCities) : undefined;
    const response = await axios.get('/estate/favorite', {
      params: {
        estateTypes: params.condition ? `${params.condition}_${romajiEstateTypeMap[params.estateType as EstateType]}`
          : romajiEstateTypeMap[params.estateType as EstateType],
        prefCityIds: prefCities || undefined,
        order: params.order,
        openHouseId: params.openHouseId,
        ohEventId: params.ohEventId,
        ohEventTypeId: params.ohEventTypeId,
        typeId: params.typeId,
        fcShopId: params.fcShopId,
        sendDate: params.sendDate ? DateTime.fromJSDate(params.sendDate).toFormat('yyyy-MM-dd') : undefined,
        limit: formatLimit({limit: params.limit, page: params.page}),
        hpFlag: params.onlyMe !== undefined ? Number(params.onlyMe) : undefined,
        roomType: params.roomTypes && params.roomTypes.length ? [...new Set(params.roomTypes)].join(',') : undefined,
        priceMin: params.minPrice,
        priceMax: params.maxPrice,
        walkMin: params.stationDistance,
        landUnderSpace: params.landMinArea,
        landHighSpace: params.landMaxArea,
        tsuboTanka: params.squareMeterPrice,
        senyuUnderSpace: params.propertyConstructMinArea,
        senyuHighSpace: params.propertyConstructMaxArea,
        tatemonoUnderSpace: params.buildingMinArea,
        tatemonoHighSpace: params.buildingMaxArea,
        floorAboveQuantity: params.floors,
        completion: params.years,

        estateIds: params.estateIds?.join(','),
        land: params.searchForTypes?.land || undefined,
        newHouse: params.searchForTypes?.newHouse || undefined,
        usedHouse: params.searchForTypes?.usedHouse || undefined,
        newApartment: params.searchForTypes?.newApartment || undefined,
        usedApartment: params.searchForTypes?.usedApartment || undefined,
        business: params.searchForTypes?.business || undefined,
        railId: params.railId,
        stationId: params.stationId,
        parking: params.parking,
        page: params.page,
        pageSize: params.limit,
      },
    });
    const data = response.data as {
      page: number,
      pageSize: number,
      total: number,
      estates: ListDoNetworkEstateResponse[]
    };
    return {
      total: data.total,
      estates: data.estates.map((item) => convertListDoNetworkEstateResponse(item)),
    };
  },

  async estateHistoryList(params: ListDoNetworkEstateForm): Promise<PaginateEstate> {
    const prefCities = params.prefCities ? formatPrefCities(params.prefCities) : undefined;
    const response = await axios.get('/estate/viewed', {
      params: {
        estateTypes: params.condition ? `${params.condition}_${romajiEstateTypeMap[params.estateType as EstateType]}`
          : romajiEstateTypeMap[params.estateType as EstateType],
        prefCityIds: prefCities || undefined,
        order: params.order,
        openHouseId: params.openHouseId,
        ohEventId: params.ohEventId,
        ohEventTypeId: params.ohEventTypeId,
        typeId: params.typeId,
        fcShopId: params.fcShopId,
        sendDate: params.sendDate ? DateTime.fromJSDate(params.sendDate).toFormat('yyyy-MM-dd') : undefined,
        limit: formatLimit({limit: params.limit, page: params.page}),
        hpFlag: params.onlyMe !== undefined ? Number(params.onlyMe) : undefined,
        roomType: params.roomTypes && params.roomTypes.length ? [...new Set(params.roomTypes)].join(',') : undefined,
        priceMin: params.minPrice,
        priceMax: params.maxPrice,
        walkMin: params.stationDistance,
        landUnderSpace: params.landMinArea,
        landHighSpace: params.landMaxArea,
        tsuboTanka: params.squareMeterPrice,
        senyuUnderSpace: params.propertyConstructMinArea,
        senyuHighSpace: params.propertyConstructMaxArea,
        tatemonoUnderSpace: params.buildingMinArea,
        tatemonoHighSpace: params.buildingMaxArea,
        floorAboveQuantity: params.floors,
        completion: params.years,

        estateIds: params.estateIds?.join(','),
        land: params.searchForTypes?.land || undefined,
        newHouse: params.searchForTypes?.newHouse || undefined,
        usedHouse: params.searchForTypes?.usedHouse || undefined,
        newApartment: params.searchForTypes?.newApartment || undefined,
        usedApartment: params.searchForTypes?.usedApartment || undefined,
        business: params.searchForTypes?.business || undefined,
        railId: params.railId,
        stationId: params.stationId,
        parking: params.parking,
        page: params.page,
        pageSize: params.limit,
      },
    });
    const data = response.data as {
      page: number,
      pageSize: number,
      total: number,
      estates: ListDoNetworkEstateResponse[]
    };
    return {
      total: data.total,
      estates: data.estates.map((item) => convertListDoNetworkEstateResponse(item)),
    };
  },

  async getEstateListAreas(): Promise<GetEstatesListAreasResult> {
    const estateListAreasRes = await axios.get('/estate/locations-to-search/');

    const res = estateListAreasRes.data[0] as {
      HP_COUNT: number;
      MYPAGE_ONLY_COUNT: number;
      PREF: string;
      CITY: string;
      TOWN: string;
      MIN_ESTATE_PRICE: string | null;
      MAX_ESTATE_PRICE: string | null;
      MIN_LAND_SPACE_M2_PUBLIC: string | null;
      MAX_LAND_SPACE_M2_PUBLIC: string | null;
      MIN_FLOOR_SPACE_TOTAL: string | null;
      MAX_FLOOR_SPACE_TOTAL: string | null;
      MIN_FLOOR_SPACE_APARTMENT: string | null;
      MAX_FLOOR_SPACE_APARTMENT: string | null;
    };

    const prefStrings = res.PREF.split(',');
    const prefectures = prefStrings.map((item) => {
      const prefItem = item.split(':');
      return {
        id: Number(prefItem[0]),
        name: prefItem[1],
      };
    });

    const cityStrings = res.CITY.split(',');
    const cities = cityStrings.map((item) => {
      const cityItem = item.split(':');
      return {
        id: Number(cityItem[1]),
        name: cityItem[2],
        prefId: Number(cityItem[0]),
      };
    });

    const townStrings = res.TOWN.split(',');
    const towns: Town[] = townStrings.map((item) => {
      const townItem = item.split(':');
      return {
        id: Number(townItem[2]),
        name: townItem[3],
        cityId: Number(townItem[1]),
        prefId: Number(townItem[0]),
        zipCode: ''
      };
    });

    return {
      estateCounts: {
        homepage: res.HP_COUNT,
        myPage: res.MYPAGE_ONLY_COUNT,
      },
      prefectures,
      cities,
      towns,
      estatePrice: {
        min: Number(res.MIN_ESTATE_PRICE),
        max: Number(res.MAX_ESTATE_PRICE),
      },
      landArea: {
        min: Number.isNaN(Number(res.MIN_LAND_SPACE_M2_PUBLIC)) ? 0 : Number(res.MIN_LAND_SPACE_M2_PUBLIC),
        max: Number.isNaN(Number(res.MAX_LAND_SPACE_M2_PUBLIC)) ? 0 : Number(res.MAX_LAND_SPACE_M2_PUBLIC),
      },
      floorArea: {
        min: Number.isNaN(Number(res.MIN_FLOOR_SPACE_TOTAL)) ? 0 : Number(res.MIN_FLOOR_SPACE_TOTAL),
        max: Number.isNaN(Number(res.MAX_FLOOR_SPACE_TOTAL)) ? 0 : Number(res.MAX_FLOOR_SPACE_TOTAL),
      },
      floorAreaApartment: {
        min: Number.isNaN(Number(res.MIN_FLOOR_SPACE_APARTMENT)) ? 0 : Number(res.MIN_FLOOR_SPACE_APARTMENT),
        max: Number.isNaN(Number(res.MAX_FLOOR_SPACE_APARTMENT)) ? 0 : Number(res.MAX_FLOOR_SPACE_APARTMENT),
      },
    };
  },

  async getEstateListStations(): Promise<GetEstatesListStationsResult> {
    const estateListStationsRes = await axios.get('/estate/train-stations-to-search/');

    const res = estateListStationsRes.data as EstateListStationsRes;
    const rails = res.rails.map(rail => ({
      id: Number(rail.id),
      name: rail.name
    }));
    const stations = res.stations.map(station => ({
      id: Number(station.id),
      cityId: Number(station.cityId),
      name: station.name,
      prefId: Number(station.prefId),
      railId: Number(station.railId),
    }));

    return stations.map((station) => ({
      ...station,
      rails: rails.filter(rail => rail.id === station.railId),
    }));
  },

  async estimateEstatePrice(form: EstimateEstatePriceForm): Promise<EstimateEstatePriceResult> {
    const response = await axios.get('/estate/estimate-price/', {
      params: {
        estateType: form.estateType,
        zipCode: form.zipCode,
        prefId: form.prefId,
        cityId: form.cityId,
        townId: form.townId,
        address: form.address,
        landArea: form.landArea,
        apartmentArea: form.apartmentArea,
        buildingArea: form.buildingArea,
        structure: form.structure ? form.structure : undefined,
        isResident: form.isResident ? 1 : 0,
        builtAt: form.builtAt ? `${form.builtAt.getFullYear()}-${form.builtAt.getMonth() + 1}-${form.builtAt.getDate()}` : undefined,
        roomQuantity: form.room?.quantity,
        roomLayout: form.room?.layout,
        floorQuantity: form.floorQuantity || 0,
        parkingQuantity: form.parkingQuantity || 0,
        estateDirection: form.estateDirection || undefined,
        landShape: form.landShape || undefined,
        mainAccessRoadDirection: form.mainAccessRoad?.direction,
        mainAccessRoadType: form.mainAccessRoad?.type,
        roadWidth: form.roadWidth,
        entranceWidth: form.entranceWidth,
      },
    });
    const data = response.data as {
      highSellPrices: {
        min: number,
        max: number,
      },
      highSellPricesFormatted: {
        min: string,
        max: string,
      },
      highSellPricesUnit: {
        min: string,
        max: string,
      },
      quickSellPrices: {
        min: number,
        max: number,
      },
      quickSellPricesFormatted: {
        min: string,
        max: string,
      },
      quickSellPricesUnit: {
        min: string,
        max: string,
      },
      coordinates: {
        lat: number,
        lng: number,
      },
    };

    return {
      ...data,
    };
  },

  registerCustomerEstateToSell(form: RegisterCustomerEstateToSellForm): Promise<void> {
    return axios.post('/customer/register-estate-to-sell/', {
      estateType: form.estateType,
      zipCode: form.zipCode,
      prefId: form.prefId,
      cityId: form.cityId,
      townId: form.townId,
      address: form.address,
      landArea: form.landArea,
      apartmentArea: form.apartmentArea,
      buildingArea: form.buildingArea,
      structure: form.structure ? form.structure : undefined,
      isResident: form.isResident ? 1 : 0,
      builtAt: form.builtAt ? `${form.builtAt.getFullYear()}-${form.builtAt.getMonth() + 1}-${form.builtAt.getDate()}` : undefined,
      roomQuantity: form.room?.quantity,
      roomLayout: form.room?.layout,
      floorQuantity: form.floorQuantity || 0,
      parkingQuantity: form.parkingQuantity || 0,
      estateDirection: form.estateDirection || undefined,
      landShape: form.landShape || undefined,
      mainAccessRoadDirection: form.mainAccessRoad?.direction,
      mainAccessRoadType: form.mainAccessRoad?.type,
      roadWidth: form.roadWidth,
      entranceWidth: form.entranceWidth,
      highSellPriceMax: form.highSellPriceMax,
      highSellPriceMin: form.highSellPriceMin,
      quickSellPriceMax: form.quickSellPriceMax,
      quickSellPriceMin: form.quickSellPriceMin,
      sellType: form.sellType,
      shopIds: form.shopIds,
    });
  },

  saveCustomerEstateToSell(form: RegisterCustomerEstateToSellForm): Promise<void> {
    return axios.post('/customer/save-estate-info/', {
      estateType: form.estateType,
      zipCode: form.zipCode,
      prefId: form.prefId,
      cityId: form.cityId,
      townId: form.townId,
      address: form.address,
      landArea: form.landArea,
      apartmentArea: form.apartmentArea,
      buildingArea: form.buildingArea,
      structure: form.structure ? form.structure : undefined,
      isResident: form.isResident ? 1 : 0,
      builtAt: form.builtAt ? `${form.builtAt.getFullYear()}-${form.builtAt.getMonth() + 1}-${form.builtAt.getDate()}` : undefined,
      roomQuantity: form.room?.quantity,
      roomLayout: form.room?.layout,
      floorQuantity: form.floorQuantity || 0,
      parkingQuantity: form.parkingQuantity || 0,
      estateDirection: form.estateDirection || undefined,
      landShape: form.landShape || undefined,
      mainAccessRoadDirection: form.mainAccessRoad?.direction,
      mainAccessRoadType: form.mainAccessRoad?.type,
      roadWidth: form.roadWidth,
      entranceWidth: form.entranceWidth,
      highSellPriceMax: form.highSellPriceMax,
      highSellPriceMin: form.highSellPriceMin,
      quickSellPriceMax: form.quickSellPriceMax,
      quickSellPriceMin: form.quickSellPriceMin,
      sellType: form.sellType,
      shopIds: form.shopIds,
    });
  },

  async getCustomerEstateToSellList(form: {
    isAvailableToSell: boolean
  }): Promise<GetCustomerEstateToSellListResult[]> {
    const response = await axios.get('/customer/estates-to-sell/', {params: {isAvailableToSell: form.isAvailableToSell ? 1 : 0}});
    const data = response.data as GetCustomerEstateToSellListApiResponse[];

    return data.map((item) => {
      return {
        id: item.id,
        estateType: toEstateType[item.estateType],
        zipCode: item.zipCode,
        pref: item.pref,
        city: item.city,
        town: item.town,
        address: item.address,
        isResident: item.isResident,
        builtAt: item.builtAt ? new Date(item.builtAt) : null,
        landArea: item.landArea,
        apartmentArea: item.apartmentArea,
        buildingArea: item.buildingArea,
        structure: item.structure ? toStructureType[item.structure] : null,
        room: item.room ? {
          quantity: item.room.quantity,
          layout: item.room.layout,
        } : null,
        floorQuantity: item.floorQuantity,
        parkingQuantity: item.parkingQuantity,
        estateDirection: item.estateDirection ? item.estateDirection : null,
        landShape: item.landShape ? item.landShape : null,
        mainAccessRoad: item.mainAccessRoad ? {
          direction: item.mainAccessRoad.direction,
          type: toRoadType[item.mainAccessRoad.type],
        } : null,
        roadWidth: item.roadWidth,
        entranceWidth: item.entranceWidth,
        highSellPrices: {min: item.highSellPrices.min, max: item.highSellPrices.max},
        quickSellPrices: {min: item.quickSellPrices.min, max: item.quickSellPrices.max},
        highSellPricesFormatted: {min: item.highSellPricesFormatted.min, max: item.highSellPricesFormatted.max},
        quickSellPricesFormatted: {min: item.quickSellPricesFormatted.min, max: item.quickSellPricesFormatted.max},
        highSellPricesUnit: {min: item.highSellPricesUnit.min, max: item.highSellPricesUnit.max},
        quickSellPricesUnit: {min: item.quickSellPricesUnit.min, max: item.quickSellPricesUnit.max},
        sellType: item.sellType,
        shops: item.shops.map((shop) => {
          return {
            id: shop.id,
            name: shop.name,
            tel: shop.tel.main,
            address: {
              pref: {
                id: shop.address.pref.id,
                name: shop.address.pref.name,
                kanaName: shop.address.pref.kanaName,
              },
              city: {
                id: shop.address.city.id,
                name: shop.address.city.name,
                prefId: shop.address.city.prefId,
                kanaName: shop.address.pref.kanaName,
              },
              street: shop.address.street,
              zipCode: shop.address.zipCode ? shop.address.zipCode : '',
            }
          };
        }),
      }
    });
  },

  async getCustomerEstateToSellById(form: {
    id: number
  }): Promise<GetCustomerEstateToSellListResult> {
    const response = await axios.get('/customer/estate-to-sell-by-id/', {params: {id: form.id}});
    const data = response.data as GetCustomerEstateToSellListApiResponse;

    return {
      id: data.id,
      estateType: toEstateType[data.estateType],
      zipCode: data.zipCode,
      pref: data.pref,
      city: data.city,
      town: data.town,
      address: data.address,
      isResident: data.isResident,
      builtAt: data.builtAt ? new Date(data.builtAt) : null,
      landArea: data.landArea,
      apartmentArea: data.apartmentArea,
      buildingArea: data.buildingArea,
      structure: data.structure ? toStructureType[data.structure] : null,
      room: data.room ? {
        quantity: data.room.quantity,
        layout: data.room.layout,
      } : null,
      floorQuantity: data.floorQuantity,
      parkingQuantity: data.parkingQuantity,
      estateDirection: data.estateDirection ? data.estateDirection : null,
      landShape: data.landShape ? data.landShape : null,
      mainAccessRoad: data.mainAccessRoad ? {
        direction: data.mainAccessRoad.direction,
        type: toRoadType[data.mainAccessRoad.type],
      } : null,
      roadWidth: data.roadWidth,
      entranceWidth: data.entranceWidth,
      highSellPrices: {min: data.highSellPrices.min, max: data.highSellPrices.max},
      quickSellPrices: {min: data.quickSellPrices.min, max: data.quickSellPrices.max},
      highSellPricesFormatted: {min: data.highSellPricesFormatted.min, max: data.highSellPricesFormatted.max},
      quickSellPricesFormatted: {min: data.quickSellPricesFormatted.min, max: data.quickSellPricesFormatted.max},
      highSellPricesUnit: {min: data.highSellPricesUnit.min, max: data.highSellPricesUnit.max},
      quickSellPricesUnit: {min: data.quickSellPricesUnit.min, max: data.quickSellPricesUnit.max},
      sellType: data.sellType,
      shops: data.shops.map((shop) => {
        return {
          id: shop.id,
          name: shop.name,
          tel: shop.tel.main,
          address: {
            pref: {
              id: shop.address.pref.id,
              name: shop.address.pref.name,
              kanaName: shop.address.pref.kanaName,
            },
            city: {
              id: shop.address.city.id,
              name: shop.address.city.name,
              prefId: shop.address.city.prefId,
              kanaName: shop.address.pref.kanaName,
            },
            street: shop.address.street,
            zipCode: shop.address.zipCode ? shop.address.zipCode : '',
          }
        };
      }),
    }
  },

  sendEstateToSell(form: UpdateCustomerEstateToSellForm): Promise<void> {
    return axios.post('/customer/update-estate-to-sell/', form);
  },

  async getEstateSentToSellAnalytics(form: { id: number }): Promise<EstateSentToSellAnalyticsHttpResponse> {
    const res = await axios.get<EstateSentToSellAnalyticsHttpResponse>('/estate/sent-to-sell/analytics', { params: { id: form.id } });
    return res.data;
  },

  async customerEstatesToSell(): Promise<Estate[]> {
    const response = await axios.get<{ estates: ListDoNetworkEstateResponse[] }>('/estate/customer/sent-to-sell/');
    return response.data.estates.map((item) => convertListDoNetworkEstateResponse(item));
  }
};
