import estateHttp from "@/services/http/estate.http";
import {useHttp} from "@/composable/service/useHttp";

export default function useGetImages() {
  const http = useHttp(estateHttp.checkImageExists);

  async function checkImageSizes(url: string, fallbackUrlError?: string) {
    // TODO RETURN A OBJECT URL

    const imageUrl = url;
    const otherSizeImageUrl = changeImageSize(imageUrl);

    if (isImageUrlLarge(imageUrl)) {
      const isImageUrlValid = await http.request(imageUrl);
      if (isImageUrlValid) return imageUrl;
    } else {
      const isLargeUrlValid = await http.request(otherSizeImageUrl);
      if (isLargeUrlValid) return otherSizeImageUrl;

      const isImageUrlValid = await http.request(imageUrl);
      if (isImageUrlValid) return imageUrl;
    }

    return new URL(`/src/assets/img/noimg_l.jpg`, import.meta.url).href;
  }

  function changeImageSize(url: string) {
    const regex = /(.*\/)([^/]+?)(L?)(\.\w+)$/;
    const match = url.match(regex);

    if (!match) return url;

    const [_, base, fileName, size, extension] = match;
    if (size === "L") {
      return `${base}${fileName}${extension}`;
    } else {
      return `${base}${fileName}L${extension}`;
    }
  }

  function isImageUrlLarge(url: string) {
    const regex = /(.*\/)([^/]+?)(L?)(\.\w+)$/;
    const match = url.match(regex);
    if (!match) return false;
    const [, , , size] = match;
    return size === "L";
  }

  function isImageValid(imageUrl: string) {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = imageUrl;
    });
  }

  return {
    request: checkImageSizes,
    loading: http.loading,
    error: http.error
  }
}
