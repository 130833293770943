import { defineStore } from 'pinia';
import { encryptMessage, decryptMessage } from "@/store/utility/storeEncrypt";
import { setItem, getItem, removeItem} from "@/store/utility/userStorage";

export interface CurrentUser {
  id: number,
  firstName: string,
  lastName: string,
  firstNameKana: string,
  lastNameKana: string,
  age: number | null,
  sex: string | null,
  contactTelFlag: boolean,
  contactFaxFlag: boolean,
  contactPostFlag: boolean,
  contactMailFlag: boolean,
  zipCode: string | null,
  prefId: number | null,
  cityId: number | null,
  address: string | null,
  tel: string | null,
  fax: string,
  email: string,
  initialPasswordSetFlag: boolean,
  passwordResetHash: string,
  passwordResetExpired: Date,
  twoStepVerificationFlag: boolean,
  deleteFlag: boolean | null,
  registerDatetime: Date,
  updatedAt: Date,
}

export const localToken = localStorage.getItem('token');

const localUser: CurrentUser | null = (() => {
  try {
    const localUser = getItem('_usr');
    if (localUser && localToken) {
      const decryptedUser = decryptMessage(localUser._temp, localToken);
      return JSON.parse(decryptedUser);
    } else {
      //TODO: THIS WAS ADDED TO SUPPORT THE OLD WAY OF STORING USER DATA
      //TODO: REMOVE THIS AFTER ALL USERS HAVE BEEN MIGRATED
      const oldUser = localStorage.getItem('user');
      if (oldUser) {
        return JSON.parse(oldUser);
      }
    }
    return null;
  } catch (error) {
    return null;
  }
})();


if (localUser) {
  localUser.registerDatetime = new Date(localUser.registerDatetime);
  localUser.updatedAt = new Date(localUser.updatedAt);
  localUser.passwordResetExpired = new Date(localUser.passwordResetExpired);
}

export interface UserState {
  currentUser: CurrentUser | null,
  token: string,
}
export const useAuthStore = defineStore('user', {
  state: (): UserState => ({
    currentUser: localUser || null,
    token: localToken || '',
  }),
  actions: {
    setUser(user: CurrentUser) {
      this.currentUser = user;
      try {
        const encryptedUser = encryptMessage(JSON.stringify(user), this.token);
        setItem('_usr', { _temp: encryptedUser });
      } catch (error) {
        console.error('Error encrypting user:', error);
      }
    },
    removeUser() {
      this.currentUser = null;
      removeItem('_usr');
    },
    setToken(token: string) {
      this.token = token;
      localStorage.setItem('token', token);
    },
    removeToken() {
      this.token = '';
      localStorage.removeItem('token');
    },
  },
  getters: {
    isAuthorized(state): boolean {
      return !!state.token;
    },
  },
});
