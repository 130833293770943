import axios from 'axios';
import {
    AreaType,
    ParkingOption,
    SearchPreferenceForm
} from "@/composable/controller/user/useUserEstateListSearchPreferences";
import {EstateOrder} from "@/services/http/estate.donetwork.http";

export interface User {
  id: string | number;
  firstName: string;
  lastName: string;
  email: string;
  zipCode: string;
  prefId: string;
  city: string;
  address: string;
  additionalInfo: string;
  tel: string;
  points: number;
  isStaff: boolean;
  isOwner: boolean;
}

export interface UserRegisterForm{
  firstName: string,
  lastName: string,
  firstNameKana: string,
  lastNameKana: string,
  email: string,
  password: string,
  tel: string,
  fax?: string,
  zipCode: string,
  prefId: number,
  cityId: number,
  address: string,
  contactTelFlag?: boolean,
  contactFaxFlag?: boolean,
  contactPostFlag?: boolean,
  contactMailFlag?: boolean,
  fcShopIds: number[],
  hope: {
    prefId: number,
    cityIds: number[],
    estateType: {
      newEstatePrice: boolean,
      oldEstatePrice: boolean,
      newMansionPrice: boolean,
      oldMansionPrice: boolean,
      landPrice: boolean,
      businessPrice: boolean,
    },
    typeId: string,
    newEstatePrice: [number, number],
    oldEstatePrice: [number, number],
    newMansionPrice: [number, number],
    oldMansionPrice: [number, number],
    landPrice: [number, number],
    businessPrice: [number, number],
    pushShinyabinFlag?: boolean,
    newstellerFlag?: boolean,
  },
}

export interface GetMeResponse {
  id: number,
  firstName: string,
  lastName: string,
  firstNameKana: string,
  lastNameKana: string,
  age: number | null,
  sex: number | null,
  contactTelFlag: boolean,
  contactFaxFlag: boolean,
  contactPostFlag: boolean,
  contactMailFlag: boolean,
  zipCode: string | null,
  prefId: number | null,
  cityId: number | null,
  address: string | null,
  tel: string | null,
  fax: string,
  email: string,
  initialPasswordSetFlag: boolean,
  twoStepVerificationFlag: boolean,
  deleteFlag: boolean | null,
  registerDatetime: Date,
  updatedAt: Date
}

export interface UpdateUserForm {
  id: number,
  email?: string,
  firstName?: string,
  lastName?: string,
  firstNameKana?: string,
  lastNameKana?: string,
  age?: number,
  sex?: string,
  tel?: string,
  fax?: string,
  zipCode?: string,
  prefId?: number,
  cityId?: number,
  address?: string,
  contactTelFlag?: boolean,
  contactFaxFlag?: boolean,
  contactPostFlag?: boolean,
  contactMailFlag?: boolean,
  twoStepVerificationFlag?: boolean,
  password?: string,
  hopes?: string,
  emailMagazineService?: boolean,
  allowEmailService?: boolean,
  newHouseService?: boolean,
  willBeDeleted?: boolean,
}

export interface VerifyCodeAndChangeEmail extends UpdateUserForm {
  code: string,
  token: string,
}

export interface VerifyUserActiveForm {
  id: number | undefined,
}

// eslint-disable-next-line no-shadow
export enum AbortReasonType {
  BuyOtherHouse = 0,
  DontHaveInformation = 1,
  StopToSearching = 2,
  Others = 3,
}

export interface DeleteMyselfForm {
  abortReasonTypeId: "serviceOnly" |  "fullService",
  abortReason: AbortReasonType,
  abortReasonExplanation: string | null,
  userHopeId: number[],
  userToken: string,
}

export interface LogoutForm {
  userToken: string,
}

export interface RegisterUserResult {
  confirmEmailToken: string,
}

interface EstateListRange {
  min?: number,
  max?: number,
}

interface EstateListRangeFormatted {
  minFormatted?: string,
  maxFormatted?: string,
  minUnit?: string,
  maxUnit?: string,
}

export enum EstateTypes {
  "land" = "land",
  "newHouse" = "newHouse",
  "usedHouse" = "usedHouse",
  "newApartment" = "newApartment",
  "usedApartment" = "usedApartment",
  "business" = "business"
}

export enum QuantityOfRooms {
    One = 1,
    Two = 2,
    Three = 3,
    Four = 4,
    MoreOfFive = 5,
}

export interface SetCustomerPreferenceForm {
  preference: {
    searchForTypes: Array<EstateTypes>,
    quantityOfLivingDiningKitchen: Array<QuantityOfRooms>,
    prefectures: number[],
    cities: {id: number, prefId: number}[],
    towns:  {id: number, prefId: number, cityId: number}[],
    stations: Array<{ id: number, railId: number }>,
    stationDistance: undefined | number,
    landArea: EstateListRange,
    buildingArea: EstateListRange,
    propertyConstructArea: EstateListRange,
    price: EstateListRange,
    years: undefined | number,
    parking: ParkingOption | null,
    order: EstateOrder | null,
    isRentable: undefined | boolean,
    areaType: AreaType,
  },
  index: number,
}

export enum EstateWishListOrderField {
  New = "NEW",
  Price = "PRICE",
  BuiltAt = "BuiltAt",
  FloorMansion = "FLOOR_MANSION",
  Floor = "FLOOR_TOTAL",
  LandArea = "LAND_SPACE"
}

export enum EstateWishListOrderDirection {
  Asc = "ASC",
  Desc = "DESC"
}

export enum SearchEstateAreaType {
    Area = 0,
    Station = 1,
    Line = 2
}

interface CustomerEstateSearchPreference {
    searchForTypes: EstateTypes[],
    quantityOfLivingDiningKitchen: QuantityOfRooms[],
    prefectures: {id: number, name: string}[],
    cities: {id: number, prefId: number, name: string}[],
    towns: {id: number, prefId: number, cityId: number, name: string}[],
    stations: Array<{ id: number, railId: number }>;
    stationDistance: null | number;
    landArea: EstateListRange;
    buildingArea: EstateListRange;
    propertyConstructArea: EstateListRange;
    price: EstateListRange;
    priceFormatted: EstateListRangeFormatted
    years: null | number;
    parking: ParkingOption | null;
    order: { field: EstateWishListOrderField, direction: EstateWishListOrderDirection } | null;
    isRentable: null | boolean;
    areaType: AreaType | null;

    //Todo Fix this
    displayOrder: number;
}

function formatEstateCustomerPreferenceOrder(form: CustomerEstateSearchPreference) {
    let order: EstateOrder = EstateOrder.new;
    if (form.order) {
        if (form.order.field === EstateWishListOrderField.New && form.order.direction === EstateWishListOrderDirection.Desc) order = EstateOrder.new;
        if (form.order.field === EstateWishListOrderField.Price && form.order.direction === EstateWishListOrderDirection.Asc) order = EstateOrder.priceAsc;
        if (form.order.field === EstateWishListOrderField.Price && form.order.direction === EstateWishListOrderDirection.Desc) order =  EstateOrder.priceDesc;
        if (form.order.field === EstateWishListOrderField.BuiltAt && form.order.direction === EstateWishListOrderDirection.Asc) order =  EstateOrder.completionAsc;
        if (form.order.field === EstateWishListOrderField.BuiltAt && form.order.direction === EstateWishListOrderDirection.Desc) order =  EstateOrder.completionDesc;
        if (form.order.field === EstateWishListOrderField.FloorMansion && form.order.direction === EstateWishListOrderDirection.Asc) order =  EstateOrder.floorMansionAsc;
        if (form.order.field === EstateWishListOrderField.FloorMansion && form.order.direction === EstateWishListOrderDirection.Desc) order =  EstateOrder.floorMansionDesc;
        if (form.order.field === EstateWishListOrderField.Floor && form.order.direction === EstateWishListOrderDirection.Asc) order =  EstateOrder.floorTotalAsc;
        if (form.order.field === EstateWishListOrderField.Floor && form.order.direction === EstateWishListOrderDirection.Desc) order =  EstateOrder.floorTotalDesc;
        if (form.order.field === EstateWishListOrderField.LandArea && form.order.direction === EstateWishListOrderDirection.Asc) order =  EstateOrder.landSpaceAsc;
        if (form.order.field === EstateWishListOrderField.LandArea && form.order.direction === EstateWishListOrderDirection.Desc) order =  EstateOrder.landSpaceDesc;
    }
    return order;
}

export default {
  async register(form: UserRegisterForm): Promise<RegisterUserResult> {
    const res = await axios.post('/customers/register', {
      ...form,
      prefId: form.prefId.toString(),
      cityId: form.cityId.toString(),
      hope: {
        ...form.hope,
        prefId: form.hope.prefId?.toString().padStart(2, '0'),
        cityId1: form.hope.cityIds[0].toString(),
        cityId2: form.hope.cityIds[1] ? form.hope.cityIds[1].toString() : undefined,
        cityId3: form.hope.cityIds[2] ? form.hope.cityIds[2].toString() : undefined,
      },
    });
    const data = res.data as {confirmEmailToken: string};
    return {
      confirmEmailToken: data.confirmEmailToken,
    };
  },

  async getMe() : Promise<GetMeResponse> {
    const res = await axios.get('/customers/me');
    const data = res.data as {
      customer: {
        id: number,
        firstName: string,
        lastName: string,
        firstNameKana: string,
        lastNameKana: string,
        age: number | null,
        sex: number | null,
        contactTelFlag: boolean,
        contactFaxFlag: boolean,
        contactPostFlag: boolean,
        contactMailFlag: boolean,
        zipCode: string,
        prefId: string,
        cityId: string,
        address: string,
        tel: string,
        fax: string,
        email: string,
        initialPasswordSetFlag: boolean,
        twoStepVerificationFlag: boolean,
        deleteFlag: boolean | null,
        registerDatetime: Date,
        updatedAt: Date
      },
      token: string,
    };
    return {
      ...data.customer,
      cityId: data.customer.cityId ? Number(data.customer.cityId) : null,
      prefId: data.customer.prefId ? Number(data.customer.prefId) : null,
      registerDatetime: new Date(data.customer.registerDatetime),
      updatedAt: new Date(data.customer.updatedAt),
    };
  },

  update(form: UpdateUserForm): Promise<void> {
    return axios.put(`/customers/edit/mp/${form.id}`, {
      ...form,
      prefId: form.prefId?.toString(),
      cityId: form.cityId?.toString(),
    });
  },

  verifyCodeAndChangeEmail(form: VerifyCodeAndChangeEmail): Promise<void> {
    return axios.put(`/customers/emailChange/verify/${form.id}`, {
      ...form,
      prefId: form.prefId?.toString(),
      cityId: form.cityId?.toString(),
    });
  },

  verifyCustomerAuth(form: VerifyUserActiveForm): Promise<void> {
    return axios.get(`/customers/auth/${form.id}`);
  },

  deleteMyself(form: DeleteMyselfForm): Promise<void> {
    return axios.post('/account/remove', {
      applyingFlag: '1',
      abortReasonTypeId: form.abortReasonTypeId,
      abortReason: form.abortReason,
      abortReasonExplanation: form.abortReasonExplanation,
      userHopeId: form.userHopeId,
      userToken: form.userToken,
    });
  },

  logoutMyself(form: LogoutForm): Promise<void> {
    return axios.post('/account/logout', {
      userToken: form.userToken,
    });
  },

    async setCustomerPreferences(form: SetCustomerPreferenceForm): Promise<SearchPreferenceForm[]> {
        const setCustomerEstateSearchPreference = await axios.put<CustomerEstateSearchPreference[]>(`/customer/estate/preference/${form.index}`, { preference: form.preference });
        const customerPreferences = setCustomerEstateSearchPreference.data;
        return customerPreferences.map((preference): SearchPreferenceForm => ({
            searchForTypes: {
                land: preference.searchForTypes.includes(EstateTypes.land),
                newHouse: preference.searchForTypes.includes(EstateTypes.newHouse),
                usedHouse: preference.searchForTypes.includes(EstateTypes.usedHouse),
                newApartment: preference.searchForTypes.includes(EstateTypes.newApartment),
                usedApartment: preference.searchForTypes.includes(EstateTypes.usedApartment),
                business: preference.searchForTypes.includes(EstateTypes.business),
            },
            quantityOfLivingDiningKitchen: preference.quantityOfLivingDiningKitchen,
            prefIds: preference.prefectures.map(item => item.id),
            cities: preference.cities.map(city => city.name),
            towns: preference.towns.map(town => town.name),
            stations: preference.stations,
            stationDistance: preference.stationDistance || undefined,
            landMinArea: preference.landArea.min,
            landMaxArea: preference.landArea.max,
            buildingMinArea: preference.buildingArea.min,
            buildingMaxArea: preference.buildingArea.max,
            propertyConstructMinArea: preference.propertyConstructArea.min,
            propertyConstructMaxArea: preference.propertyConstructArea.max,
            minPrice: preference.price.min,
            maxPrice: preference.price.max,
            years: preference.years !== null ? preference.years : undefined,
            parking: preference.parking !== null ? preference.parking : null,
            order: formatEstateCustomerPreferenceOrder(preference),
            isRentable: preference.isRentable !== null ? preference.isRentable : undefined,
            areaType: preference.areaType || AreaType.Area,
            displayOrder: preference.displayOrder,
        }));
    },

    async getCustomerPreferences(): Promise<SearchPreferenceForm[]> {
        const getCustomerPreferencesResult = await axios.get<CustomerEstateSearchPreference[]>(`/customer/estate/preferences`);
        const customerPreferences = getCustomerPreferencesResult.data;
        return customerPreferences.map((preference): SearchPreferenceForm => ({
            searchForTypes: {
                land: preference.searchForTypes.includes(EstateTypes.land),
                newHouse: preference.searchForTypes.includes(EstateTypes.newHouse),
                usedHouse: preference.searchForTypes.includes(EstateTypes.usedHouse),
                newApartment: preference.searchForTypes.includes(EstateTypes.newApartment),
                usedApartment: preference.searchForTypes.includes(EstateTypes.usedApartment),
                business: preference.searchForTypes.includes(EstateTypes.business),
            },
            quantityOfLivingDiningKitchen: preference.quantityOfLivingDiningKitchen,
            prefIds: preference.prefectures.map(item => item.id),
            cities: preference.cities.map(city => city.name),
            towns: preference.towns.map(town => town.name),
            stations: preference.stations,
            stationDistance: preference.stationDistance || undefined,
            landMinArea: preference.landArea.min,
            landMaxArea: preference.landArea.max,
            buildingMinArea: preference.buildingArea.min,
            buildingMaxArea: preference.buildingArea.max,
            propertyConstructMinArea: preference.propertyConstructArea.min,
            propertyConstructMaxArea: preference.propertyConstructArea.max,
            minPrice: preference.price.min,
            maxPrice: preference.price.max,
            minPriceFormatted: preference.priceFormatted.minFormatted,
            maxPriceFormatted: preference.priceFormatted.maxFormatted,
            minPriceUnit: preference.priceFormatted.minUnit,
            maxPriceUnit: preference.priceFormatted.maxUnit,
            years: preference.years !== null ? preference.years : undefined,
            parking: preference.parking !== null ? preference.parking : null,
            order: formatEstateCustomerPreferenceOrder(preference),
            isRentable: preference.isRentable !== null ? preference.isRentable : undefined,
            areaType: preference.areaType || AreaType.Area,
            displayOrder: preference.displayOrder,
        }));
    },

    async removeCustomerPreferences(form: { idx: number}): Promise<SearchPreferenceForm[]> {
        const removeCustomerPreferencesResult = await axios.delete<CustomerEstateSearchPreference[]>(`/customer/estate/preference/${form.idx}`);
        const customerPreferences = removeCustomerPreferencesResult.data;
        return customerPreferences.map((preference): SearchPreferenceForm => ({
            searchForTypes: {
                land: preference.searchForTypes.includes(EstateTypes.land),
                newHouse: preference.searchForTypes.includes(EstateTypes.newHouse),
                usedHouse: preference.searchForTypes.includes(EstateTypes.usedHouse),
                newApartment: preference.searchForTypes.includes(EstateTypes.newApartment),
                usedApartment: preference.searchForTypes.includes(EstateTypes.usedApartment),
                business: preference.searchForTypes.includes(EstateTypes.business),
            },
            quantityOfLivingDiningKitchen: preference.quantityOfLivingDiningKitchen,
            prefIds: preference.prefectures.map(item => item.id),
            cities: preference.cities.map(city => city.name),
            towns: preference.towns.map(town => town.name),
            stations: preference.stations,
            stationDistance: preference.stationDistance || undefined,
            landMinArea: preference.landArea.min,
            landMaxArea: preference.landArea.max,
            buildingMinArea: preference.buildingArea.min,
            buildingMaxArea: preference.buildingArea.max,
            propertyConstructMinArea: preference.propertyConstructArea.min,
            propertyConstructMaxArea: preference.propertyConstructArea.max,
            minPrice: preference.price.min,
            maxPrice: preference.price.max,
            years: preference.years !== null ? preference.years : undefined,
            parking: preference.parking !== null ? preference.parking : null,
            order: formatEstateCustomerPreferenceOrder(preference),
            isRentable: preference.isRentable !== null ? preference.isRentable : undefined,
            areaType: preference.areaType || AreaType.Area,
            displayOrder: preference.displayOrder,
        }));
    },


};
