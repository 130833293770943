import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useAuthStore } from '@/store/auth.store';
import useApplicationConfig from "@/composable/controller/application/useApplicationConfig";
import EstateDetail from '../views/Estate/EstateDetail.vue';
import {
  useGetAuth
} from '@/composable/controller/useUser';


const routes: Array<RouteRecordRaw> = [
   {
    path: '/terms-of-use',
    name: 'TermsOfUse',
    component: () => import('../views/Login/TermsOfUse.vue'),
    meta: { pageTitle: '【ハウスドゥマイページ】マイページサービス利用規約' },
  },
  {
    path: '/estate/image/360',
    name: 'View360',
    component: () => import('../views/Estate/components/Modal/Image360Modal.vue'),
    meta: { pageTitle: '【ハウスドゥマイページ】360°写真', userAuth: true },
    props: (route) => route.query,
  },
  {
    path: '/',
    redirect: '/login',
    component: () => import('../views/Login/UnloggedLayout.vue'),
    children: [
      {
        path: '/memberregistration/input',
        name: 'AccountCreate',
        component: () => import('../views/Login/AccountCreate.vue'),
        props: (route) => ({
          encodedFcShopId: route.query.fcs,
          fcShopReferralCode: route.query.sf,
          analyticsCode: route.query.tc,
          prefId: route.query.prefID,
          cityId: route.query.cityID,
        }),
        meta: { pageTitle: '【ハウスドゥマイページ】マイページ登録', useDataPersistence: true },
      }, {
        path: '/account/create/result',
        name: 'AccountCreateResult',
        component: () => import('../views/Login/AccountCreateResult.vue'),
        meta: { pageTitle: '【ハウスドゥマイページ】マイページ登録完了', useDataPersistence: true },
      }, {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login/LoginView.vue'),
        props: (route) => {
          return {
            ...route.query,
            fcShopId: Number(route.query.fcshopId),
            fcShopReferralCode: route.query.sf,
            analyticsCode: route.query.tc,
          }
        },
        meta: { pageTitle: '【ハウスドゥマイページ】ログイン' },
      }, {
        path: '/login/authenticate',
        name: 'Authenticate',
        component: () => import('../views/Login/AuthenticateView.vue'),
        props: (route) => ({
          token: route.query.token,
        }),
        meta: { pageTitle: '【ハウスドゥマイページ】ログイン' },
      },
      {
        path: '/account/password/recover',
        name: 'RecoverPassword',
        component: () => import('../views/Login/RecoverPassword.vue'),
        meta: { pageTitle: '【ハウスドゥマイページ】パスワードを忘れた方へ', useDataPersistence: true },
      }, {
        path: '/account/email/confirm',
        name: 'ConfirmEmail',
        component: () => import('../views/Login/ConfirmEmail.vue'),
        props: (route) => route.query,
        meta: { pageTitle: '【ハウスドゥマイページ】メールアドレス認証', useDataPersistence: true },
      }, {
        path: '/account/password/recover/result',
        name: 'RecoverPasswordResult',
        component: () => import('../views/Login/RecoverPasswordResult.vue'),
        props: (route) => route.query,
        meta: { pageTitle: '【ハウスドゥマイページ】パスワードを忘れた方へ', useDataPersistence: true },
      }, {
        path: '/account/password/change',
        name: 'ChangePassword',
        component: () => import('../views/Login/ChangePassword.vue'),
        props: (route) => route.query,
        meta: { pageTitle: 'パスワード設定', useDataPersistence: true },
      }, {
        path: '/account/password/change/result',
        name: 'ChangePasswordResult',
        component: () => import('../views/Login/ChangePasswordResult.vue'),
        meta: { pageTitle: 'パスワードリセット完了', useDataPersistence: true },
      }, {
        path: '/logout/result',
        name: 'LogoutView',
        component: () => import('../views/Login/LogoutView.vue'),
        meta: { pageTitle: '【ハウスドゥマイページ】ログアウト' },
      },{
        path: '/account/deleteResult',
        name: 'AccountDeleteResult',
        component: () => import('../views/Account/AccountDeleteResult.vue'),
        meta: { pageTitle: '【ハウスドゥマイページ】退会完了', useDataPersistence: true },
      },
    ],
  },
  {
    path: '/home',
    name: 'Home',
    redirect: '/news',
    component: () => import('../views/Home/HomeView.vue'),
    meta: { userAuth: true },
    children: [
      {
        path: '/news',
        name: 'News',
        component: () => import('../views/News/NewsIndex.vue'),
        props: true,
        meta: { pageTitle: '【ハウスドゥマイページ】新着情報' },
      }, {
        path: '/event/event',
        name: 'EventIndex',
        component: () => import('../views/News/EventIndex.vue'),
        props: true,
        meta: { pageTitle: '【ハウスドゥマイページ】イベント情報' },
      }, {
        path: '/account',
        name: 'AccountIndex',
        component: () => import('../views/Account/AccountIndex.vue'),
        props: true,
        meta: { pageTitle: '【ハウスドゥマイページ】アカウント' },
      }, {
        path: '/estate/estimate-price',
        name: 'EstimateEstatePrice',
        component: () => import('../views/EstateSell/SellEstateRequestForm.vue'),
        props: true,
        meta: { pageTitle: '【ハウスドゥマイページ】10秒査定' },
      }, {
        path: '/estate/edit/estimate-price',
        name: 'EditEstimateEstatePrice',
        component: () => import('../views/EstateSell/SellEstateRequestForm.vue'),
        props: (route) => route.query,
        meta: { pageTitle: '【ハウスドゥマイページ】簡易査定編集' },
      }, {
        path: '/estate/estates-to-sell',
        name: 'EstatesToSell',
        component: () => import('../views/Account/CustomerEstatesToSellList.vue'),
        props: true,
        meta: { pageTitle: '【ハウスドゥマイページ】簡易査定保存' },
      }, {
        path: '/estate/estates-available-to-sell',
        name: 'EstatesAvailableToSell',
        component: () => import('../views/Account/CustomerEstatesAvailableToSell.vue'),
        props: true,
        meta: { pageTitle: '【ハウスドゥマイページ】正式査定依頼状況' },
      },
      {
        path: '/estate/submitted-to-sell',
        name: 'SubmittedEstatesToSell',
        component: () => import('../views/EstateSell/SubmittedEstatesToSell.vue'),
        props: true,
        meta: { pageTitle: ' 【ハウスドゥマイページ】ご依頼の物件' },
      },
      {
        path: '/estate/analysis/:estateId',
        name: 'EstateAnalysis',
        component: () => import('../views/EstateSell/EstateAnalysis.vue'),
        props: true,
        meta: { pageTitle: '【ハウスドゥマイページ】販売中不動産分析' },
      },
      {
        path: '/account/input',
        name: 'AccountEdit',
        component: () => import('../views/Account/AccountEdit.vue'),
        meta: { pageTitle: '【ハウスドゥマイページ】登録情報変更', useDataPersistence: true },
      },{
        path: '/hope',
        name: 'Hope',
        component: () => import('../views/Hope/HopeIndex.vue'),
        meta: { pageTitle: '【ハウスドゥマイページ】ご利用店舗' },
      }, {
        path: '/hope/new',
        name: 'HopeCreate',
        component: () => import('../views/Hope/HopeCreate.vue'),
        props: (route) => route.query,
        meta: { pageTitle: '【ハウスドゥマイページ】新たに店舗を追加', useDataPersistence: true },
      }, {
        path: '/hope/all',
        name: 'HopeMultipleEdit',
        component: () => import('../views/Hope/HopeEditMultiple.vue'),
        props: (route) => route.query,
        meta: { pageTitle: '【ハウスドゥマイページ】希望条件の再設定', useDataPersistence: true },
      }, {
        path: '/hope/one',
        name: 'HopeEdit',
        component: () => import('../views/Hope/HopeEditOne.vue'),
        props: (route) => route.query,
        meta: { pageTitle: '店舗別希望条件の再設定', useDataPersistence: true },
      }, {
        path: '/estates/list',
        name: 'EstateList',
        component: () => import('../views/Estate/EstateList.vue'),
        props: (route) => route.query,
        meta: { pageTitle: '【ハウスドゥマイページ】物件一覧' },
      },
      {
        path: '/estates/favorites',
        name: 'EstateFavoriteList',
        component: () => import('../views/Estate/EstateFavoriteList.vue'),
        props: (route) => route.query,
        meta: { pageTitle: '【ハウスドゥマイページ】お気に入り' },
      },
      {
        path: '/estates/histories',
        name: 'EstateHistoryList',
        component: () => import('../views/Estate/EstateHistoryList.vue'),
        props: (route) => route.query,
        meta: { pageTitle: '【ハウスドゥマイページ】最近見た物件' },
      }, {
        path: '/inquiry/other',
        name: 'InquiryOther',
        props: (route) => ({ fcShopId: route.query.fcShopId }),
        component: () => import('../views/Inquiry/InquiryOther.vue'),
        meta: { pageTitle: '【ハウスドゥマイページ】お問合せ' },
      }, {
        path: '/inquiry/other/result',
        name: 'InquiryOtherResult',
        component: () => import('../views/Inquiry/Components/InquiryOtherResult.vue'),
        meta: { pageTitle: '【ハウスドゥマイページ】お問合せ送信完了' },
      }, {
        path: '/inquiry/OnlineComing',
        name: 'InquiryOnline',
        props: (route) => ({ fcShopId: route.query.fcShopId }),
        component: () => import('../views/Inquiry/InquiryOnline.vue'),
        meta: { pageTitle: '【ハウスドゥマイページ】オンライン来店予約' },
      }, {
        path: '/inquiry/onlineComing/result',
        name: 'InquiryOnlineResult',
        component: () => import('../views/Inquiry/Components/InquiryOnlineResult.vue'),
        meta: { pageTitle: '【ハウスドゥマイページ】オンライン来店予約送信完了' },
      }, {
        path: '/inquiry/contact',
        name: 'InquiryContact',
        component: () => import('../views/Inquiry/InquiryContact.vue'),
        meta: { pageTitle: '【ハウスドゥマイページ】来店予約' },
      }, {
        path: '/inquiry/contact/result',
        name: 'InquiryContactResult',
        component: () => import('../views/Inquiry/Components/InquiryContactResult.vue'),
        meta: { pageTitle: '【ハウスドゥマイページ】来店予約送信完了' },
      }, {
        path: '/inquiry/estates',
        name: 'InquiryEstates',
        props: (route) => ({ estateIds: route.query.estateInquiry, isFromDetails: route.query.isFromDetails }),
        component: () => import('../views/Inquiry/InquiryEstates.vue'),
        meta: { pageTitle: '【ハウスドゥマイページ】物件のお問合せ' },
      }, {
        path: '/inquiry/estates/result',
        name: 'InquiryEstateResult',
        props: (route) => ({ estateIds: route.query.estateInquiry }),
        component: () => import('../views/Inquiry/Components/InquiryEstatesResult.vue'),
        meta: { pageTitle: '【ハウスドゥマイページ】物件のお問合せ' },
      }, {
        path: '/document/users-guide',
        name: 'UsersGuide',
        component: () => import('../views/Account/UsersGuide.vue'),
        meta: { pageTitle: '【ハウスドゥマイページ】機能ガイド' },
      }, {
        path: '/:pathMatch(.*)*',
        name: 'PageError',
        component: () => import('../views/Error/PageError.vue'),
        meta: { pageTitle: 'お探しのページが見つかりません。' },
      }, {
        path: '/detail/404',
        name: 'PageDetailError',
        component: () => import('../views/Error/PageDetailError.vue'),
        meta: { pageTitle: 'お探しの物件は掲載が終了しました。' },
      }, {
        path: '/detail/:shopId/:estateId',
        name: 'EstatesDetail',
        component: () => import('../views/Estate/EstateDetail.vue'),
        meta: { pageTitle: `【ハウスドゥマイページ】物件詳細` },
        props: (route) => route.params,
      },{
        path: '/analysis/:shopId/:estateId',
        name: 'EstatesDetailAnalysis',
        component: () => import('../views/Estate/EstateDetail.vue'),
        meta: { pageTitle: 'マイページ' },
        props: (route) => ({
          ...route.params,
          isAnalysis: true
        }),},{
        path: '/sitemap',
        name: 'SiteMap',
        component: () => import('../views/Home/SiteMap.vue'),
        meta: { pageTitle: 'サイトマップ' },
        props: (route) => route.params,
      },
      {
        path: '/account/result',
        name: 'AccountEditResult',
        component: () => import('../views/Account/AccountEditResult.vue'),
        meta: { pageTitle: '【ハウスドゥマイページ】登録情報変更送信完了', useDataPersistence: true },
      },
      {
        path: '/development',
        name: 'DevelopmentScreen',
        component: () => import('../views/Development/Components.vue'),
        meta: { pageTitle: '【Development Screen】Components', useDataPersistence: true },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const {configuration} = useApplicationConfig();
  const {getAuth} = useGetAuth();
  const isInDataPersistenceMaintenance = configuration.value.isInDataPersistenceMaintenance;
  const store = useAuthStore().$state;
  const isRouteNeedAuth = to.matched.some((record) => record.meta.userAuth);
  const isRouteNeedDataPersistence = to.matched.some((record) => record.meta.useDataPersistence);
  const isLogged = !!store.token;

  if (isRouteNeedAuth && !isLogged) return next({ name: 'Login' });
  if (isRouteNeedAuth && isInDataPersistenceMaintenance && isRouteNeedDataPersistence) return next({ name: 'Home' })
  if (!isRouteNeedAuth && isInDataPersistenceMaintenance && isRouteNeedDataPersistence) return next({ name: 'Login' })

  if (isLogged && from.name !== 'Login' && from.name !== 'Authenticate') {
    await getAuth();
  }
  return next();
});

router.afterEach((to) => {
  const pageTitle = to.meta.pageTitle as string | undefined;
  document.title = pageTitle ?? 'エラー';
  if (!to.meta.noScrollReset) window.scrollTo(0, 0);
});
export default router;
