import {City} from "@/domain/city.entity";
import {EstateOrder} from "@/services/http/estate.donetwork.http";
import {computed, onMounted, ref, watch} from "vue";
import {Town} from "@/domain/town.entity";
import {useHttp, useHttpList} from "@/composable/service/useHttp";
import addressHttp from "@/services/http/address.http";
import userHttp, {EstateTypes} from "@/services/http/user.http";

export enum ParkingOption {
  None = 0,
  Exist = 1,
  Near = 2,
}

export enum AreaType {
  Area = 0,
  Station = 1,
  Line = 2
}

export interface SearchPreferenceForm {
  searchForTypes: {
    land: boolean,
    newHouse: boolean,
    usedHouse: boolean,
    newApartment: boolean,
    usedApartment: boolean,
    business: boolean,
  },
  minPrice: undefined | number,
  maxPrice: undefined | number,
  minPriceFormatted?: undefined | string,
  maxPriceFormatted?: undefined | string,
  minPriceUnit?: undefined | string,
  maxPriceUnit?: undefined | string,
  prefIds: number[],
  cities: City['name'][],
  towns: Town['name'][],
  stations: Array<{ id: number, railId: number }>,
  stationDistance: undefined | number,
  quantityOfLivingDiningKitchen: Array<number>,
  landMinArea: undefined | number,
  landMaxArea: undefined | number,
  buildingMinArea: undefined | number,
  buildingMaxArea: undefined | number,
  propertyConstructMinArea: undefined | number,
  propertyConstructMaxArea: undefined | number,
  years: undefined | number,
  parking: null | ParkingOption,
  order: EstateOrder,
  isRentable: undefined | boolean,
  areaType: AreaType,

  //Todo fix this
  displayOrder: number,
}

interface SaveCurrentPreferences {
  searchForTypes: {
    land: boolean,
    newHouse: boolean,
    usedHouse: boolean,
    newApartment: boolean,
    usedApartment: boolean,
    business: boolean,
  },
  minPrice: undefined | number,
  maxPrice: undefined | number,
  minPriceFormatted?: undefined | string,
  maxPriceFormatted?: undefined | string,
  minPriceUnit?: undefined | string,
  maxPriceUnit?: undefined | string,
  prefIds: number[],
  cities: City[],
  towns: Town[],
  stations: Array<{ id: number, railId: number }>,
  stationDistance: undefined | number,
  quantityOfLivingDiningKitchen: Array<number>,
  landMinArea: undefined | number,
  landMaxArea: undefined | number,
  buildingMinArea: undefined | number,
  buildingMaxArea: undefined | number,
  propertyConstructMinArea: undefined | number,
  propertyConstructMaxArea: undefined | number,
  years: undefined | number,
  parking: undefined | ParkingOption,
  order: EstateOrder,
  isRentable: undefined | boolean,
  areaType: AreaType,
}

export default function useUserEstateListSearchPreferences() {

  const searchPreferenceList = ref<SearchPreferenceForm[]>([]);

  const loading = ref(false);

  async function savePreference(searchPreference: SaveCurrentPreferences, spliceIndex: number) {
    const http = useHttp(userHttp.setCustomerPreferences);
    const searchForTypes = [];
    if (searchPreference.searchForTypes.land) searchForTypes.push(EstateTypes.land);
    if (searchPreference.searchForTypes.newHouse) searchForTypes.push(EstateTypes.newHouse);
    if (searchPreference.searchForTypes.usedHouse) searchForTypes.push(EstateTypes.usedHouse);
    if (searchPreference.searchForTypes.newApartment) searchForTypes.push(EstateTypes.newApartment);
    if (searchPreference.searchForTypes.usedApartment) searchForTypes.push(EstateTypes.usedApartment);
    if (searchPreference.searchForTypes.business) searchForTypes.push(EstateTypes.business);
    loading.value = true;
    searchPreferenceList.value = await http.request({
      preference: {
        searchForTypes,
        quantityOfLivingDiningKitchen: searchPreference.quantityOfLivingDiningKitchen,
        prefectures: searchPreference.prefIds,
        cities: searchPreference.cities,
        towns: searchPreference.towns,
        stations: searchPreference.stations,
        stationDistance: searchPreference.stationDistance,
        landArea: {min: searchPreference.landMinArea, max: searchPreference.landMaxArea},
        buildingArea: {
          min: searchPreference.buildingMinArea,
          max: searchPreference.buildingMaxArea
        },
        propertyConstructArea: {
          min: searchPreference.propertyConstructMinArea,
          max: searchPreference.propertyConstructMaxArea
        },
        price: {min: searchPreference.minPrice, max: searchPreference.maxPrice},
        years: searchPreference.years,
        parking: searchPreference.parking !== undefined ? searchPreference.parking : null,
        order: searchPreference.order,
        isRentable: searchPreference.isRentable !== undefined ? searchPreference.isRentable : undefined,
        areaType: searchPreference.areaType,
      },
      index: spliceIndex
    });
    loading.value = false;
  }

  async function getPreferenceList() {
    const http = useHttp(userHttp.getCustomerPreferences);
    loading.value = true;
    searchPreferenceList.value = await http.request({})
    loading.value = false;
  }

  async function removePreference(indexToRemove: number) {
    const http = useHttp(userHttp.removeCustomerPreferences);
    loading.value = true;
    searchPreferenceList.value = await http.request({idx: indexToRemove});
    loading.value = false;
  }

  onMounted(async () => {
    await getPreferenceList();
  })
  return {
    searchPreferenceList: searchPreferenceList,
    savePreference,
    getPreferenceList,
    loading: computed(() => loading.value),
    removePreference
  };
}
