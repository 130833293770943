import axios from 'axios';
import {
  Access,
  BuildingRestriction,
  ConstructionCompletionType,
  Estate,
  EstateDetailImageType,
  EstateMapTypes,
  EstateOwnership,
  EstateStatus,
  EstateType,
  GarageAvailability,
  LandAreaType,
  MeasureType,
  StateOfUse, TradeTransactionType,
  UrbanPlanning
} from '@/domain/estate.entity';
import { Pref } from '@/domain/pref.entity';
import { City } from '@/domain/city.entity';
import { Town } from '@/domain/town.entity';
import { Station } from '@/domain/station.entity';
import { Rail } from '@/domain/rail.entity';
import { EstateDetailImage, NearbPlace } from '@/services/http/estate.donetwork.http';

// eslint-disable-next-line no-shadow
export enum EstateOrder {
  new = 'new_order',
  priceAsc = 'price_asc',
  priceDesc = 'price_desc',
  completionAsc = 'completion_asc',
  completionDesc = 'completion_desc',
  floorMansionAsc = 'floor_mansion_asc',
  floorMansionDesc = 'floor_mansion_desc',
  floorTotalAsc = 'floor_total_asc',
  floorTotalDesc = 'floor_total_asc',
  landSpaceAsc = 'land_space_asc',
  landSpaceDesc = 'land_space_desc',
}

export interface PrefCity {
  prefId: number;
  cityId: number;
}

type KanjiEstateType = 'マンション' | '一戸建て' | '収益物件' | '事業用物件' | '土地';

export type RoomTypes = '2K' | '2LDK' | '3K' | '3LDK' | '4K' | '4LDK' | '5K' | '5LDK';

export type ListEstateType =
  'favoriteEstate'
  | 'newEstate'
  | 'openHouseEstate'
  | 'openHouseEventEstate'
  | 'shinyabinEstate'
  | 'mypageEstate';

export type UrbanPlanningType = '市街化区域' | '調整区域' | '非線引区域' | '区域外' | '準都市区域'

export interface ListDoNetworkEstateForm {
  estateIds?: number[],
  estateType?: EstateType,
  condition?: 'new' | 'used'
  prefCities?: PrefCity[]
  order?: EstateOrder
  openHouseId?: number
  ohEventId?: number
  ohEventTypeId?: number
  typeId?: ListEstateType
  fcShopId?: number
  sendDate?: Date
  limit?: number
  page?: number
  onlyMe?: boolean
  roomTypes?: (RoomTypes)[]
  minPrice?: number
  maxPrice?: number
  stationDistance?: number
  landMinArea?: number
  landMaxArea?: number
  squareMeterPrice?: number
  buildingMinArea?: number
  buildingMaxArea?: number
  propertyConstructMinArea?: number
  propertyConstructMaxArea?: number
  floors?: number
  years?: number
  searchForTypes?: {
    land: boolean,
    newHouse: boolean,
    usedHouse: boolean,
    newApartment: boolean,
    usedApartment: boolean,
    business: boolean,
  }
  railId?: number
  stationId?: number | number[]
  parking?: number
}

interface GetEstateDetailImageForm {
  estateId: number
}

export interface LandLotDetails {
  estateId: number,
  shop: {
    id: number,
  },
  newUsedTypeId: number,
  estateTypeId: number,
  packagePartTypeId: number,
  businessFlag: boolean,
  estateTypeDisp: string,
  icon: string,
  roomLayoutImage: string,
  exteriorImage1: string,
  estatePriceFormatted: string,
  estatePrice: string,
  formattedPrice: string,
  formattedPriceUnit: string,
  roomLayout: string,
  floorSpaceTotal: string,
  landSpaceM2Public: string,
  floorSpaceMansion: string,
}

export interface OhEventEstateDetail {
  estateId: number,
  ohEventId: number,
  ohEventTypeId: number,
  ohEventType: string,
  ohEventTitle: string,
  ohEventStatus: string,
  holidayPgFlag: number,
  eventImage1: string,
  mapImage: string,
  ohAddress: string,
  ohDate: string,
  ohTime: string,
  ohEventApproach: string,
  sortNo: string,
  pr: string,
  biko: string
}

export interface PropertyLayoutDetails {
  propertyLayout: string,
  floorSpace: string,
  roomType: string | null,
  outputOrder: string,
}

export interface EstateDetailOuterImage {
  imageServiceId: number,
  path: string,
  tag: string,
  imageServiceTypeId: number,
  divisionId: number,
  divisionName: string,
  divisionOrder: string,
  pr: string,
  outputOrder: string,
}

export type EstateListNewsResponse = {
  fcId: number,
  shop: {
    name: string,
  },
  estateId: number,
  status: EstateStatus,
  estateNameWeb: string,
  roomLayoutImage: string,
  exteriorImage1: string,
  access: {
    railName: string,
    stationName: string,
    stationAccess: string,
  }[],
  priceFormatted: string,
  price: number,
  floorSpaceTotal: string,
  floorSpaceMansion: string,
  landSpaceM2Public: string,
  elementarySchool: string,
  juniorHighSchool: string,
}

export interface GetEstateCountForm {
  prefId: string | number
  cityId1?: string | number | null
  cityId2?: string | number | null
  cityId3?: string | number | null
}

export interface EstateCount {
  shopId: number,
  shopName: string,
  prefId: number,
  prefName: string,
  address: string,
  cityId: number,
  cityName: string,
  landCount: number,
  newEstateCount: number,
  newMansionCount: number,
  oldEstateCount: number,
  oldMansionCount: number,
  businessCount: number,
}

export interface EstateDetailForm {
  estateId: number,
  urlType: string
}

export interface FavoriteEstateForm {
  id: number,
}

export interface UnfavoriteEstateForm {
  id: number,
}

export interface ListDoNetworkEstateResponse {
  FC_ID: string,
  FC_SHOP_ID: string,
  ESTATE_ID: string,
  PHOTO_FLAG: string,
  PRICE_DOWN_FLAG: string,
  PRICE_DOWN_REGIST_DATETIME: string | null,
  CHECK_PARKING_FLAG: string,
  OH_FLAG: string,
  PLAN_FLAG: string,
  REFORM_EXECUTION_ID: string | null,
  RENOVATION_FLAG: string,
  PREF_ID: string,
  PREF_NAME: string,
  PREF_ALPHABET: string,
  CITY_ID: string,
  CITY_NAME: string,
  TOWN_ID: string,
  TOWN_NAME: string,
  ESTATE_ADDRESS: string,
  NEW_USED_TYPE_NAME: string | null,
  NEW_USED_TYPE_ID: StateOfUse | null,
  ESTATE_TYPE_NAME: KanjiEstateType,
  ESTATE_TYPE_ID: string,
  ESTATE_TYPE_ID1: string,
  ESTATE_NAME: string,
  ESTATE_NAME_WEB: string, // TODO Remove this field
  SHUEKI_FLAG: string,
  ESTATE_USEA: string,
  ESTATE_USEB: string,
  YEARLY_INCOME: string | null,
  YIELD_RATE: string | null,
  ELEMENTARYSCHOOL_NAME: string,
  ELEMENTARYSCHOOL_DISTANCE: string,
  JUNIORHIGHSCHOOL_NAME: string,
  structureName: string,
  ESTATE_PRICE1: string,
  ESTATE_PRICE: string,
  HOUSE_ESTATE_PRICE_FORMAT: string,
  HOUSE_ESTATE_PRICE_FORMAT_UNIT: string,
  mr1rn: string | null,
  ms1sn: string | null,
  BUS_MINUTES1: string | null,
  BUS_STOP_NAME1: string | null,
  WALK_MINUTES1: string | null,
  WALK_MINUTES: string,
  mr2rn: string | null,
  ms2sn: string | null,
  BUS_MINUTES2: string | null,
  BUS_STOP_NAME2: string | null,
  WALK_MINUTES2: string | null,
  mr3rn: string | null,
  ms3sn: string | null,
  BUS_MINUTES3: string | null,
  BUS_STOP_NAME3: string | null,
  WALK_MINUTES3: string | null,
  TRAFFIC_ETC: string,
  WALK_MINUTES_ETC: string | null,
  YUSEN_KOTSU_KBN_1: string | null,
  YUSEN_KOTSU_KBN_2: string | null,
  YUSEN_KOTSU_KBN_3: string | null,
  BUS_MINUTES: string | null,
  FLYER_CATCH_COPY1: string,
  FLYER_CATCH_COPY2: string,
  FLYER_CATCH_COPY3: string,
  FLYER_CATCH_COPY4: string,
  ROOM_LAYOUT_IMAGE_NAME: string,
  EXTERIOR_IMAGE_NAME1: string,
  EXTERIOR_IMAGE_NAME2: string,
  EXTERIOR_IMAGE_NAME3: string,
  EXTERIOR_IMAGE_NAME4: string,
  EXTERIOR_IMAGE_NAME5: string,
  EXTERIOR_IMAGE_NAME6: string,
  EXTERIOR_IMAGE_NAME7: string,
  EXTERIOR_IMAGE_NAME8: string,
  EXTERIOR_IMAGE_NAME9: string,
  EXTERIOR_IMAGE_NAME10: string,
  FLOOR_SPACE_MANSION: string,
  ROOM_QUANTITY: null | string,
  ROOM_LAYOUT_TYPE_NAME: string,
  FLOOR_ABOVE_QUANTITY: `${number}階` | `${number}`,
  FLOOR: string | null,
  COMPLETION_TYPE_NAME: string | null,
  COMPLETION_YEAR: string,
  COMPLETION_MONTH: string,
  CHIKUNENGETSU: string,
  CHIKUNENGETSU1: string,
  FLOOR_SPACE_TOTAL: string,
  LAND_SPACE_M2_PUBLIC: string,
  TSUBO: string,
  BUILDING_COVERAGE: string,
  PLOT_RATIO: string,
  TSUBO_TANKA: string,
  REGIST_DATETIME: string,
  REFORM_EXECUTION_YEAR: string | null,
  REFORM_EXECUTION_MONTH: string | null,
  USE_DISTRICT_NAME: string,
  BUILDING_RESTRICT_TYPE_NAME: string,
  LAND_RIGHT_TYPE_NAME: string,
  SHINCHAKU: string,
  PACKAGE_PART_TYPE_ID: string | null,
  REAL_ESTATE_TYPE_NAME: string,
  OUTER_MOVIE_URL: string | null,
  CATCH: string,
  MV_IMAGE_ID: string,
  PANORAMA_IMAGE_ID: string,
  VR_PREVIEW_FLAG: string,
  OH_EVENT_ID: string | null,
  OH_EVENT_TYPE_ID: string | null,
  OH_EVENT_TYPE_DETAIL_ID: string | null,
  OH_EVENT_TITLE: string | null,
  OH_EVENT_PERIOD_ID: string | null,
  START_DATE: string | null,
  END_DATE: string | null,
  START_TIME: string | null,
  END_TIME: string | null,
  OH_EVENT_PERIOD_NAME: string | null,
  FRAME_NO: string | null,
  LOW_MODIFY_DATETIME: string,
  MODIFY_DATETIME: string,
  PARKING_FLAG: string,
  PARKING_2_FLAG: string,
  PARKING_3_FLAG: string,
  PARKING_MINI_FLAG: string,
  GARAGE_TYPE_ID: string | null,
  OPEN_PARKING_FLAG: string,
  PARKING_LOT_FLAG: string,
  BOX_1_PARKING_FLAG: string,
  PLAN_ROOM_LAYOUT_IMAGE_NAME: string,
  PLAN_ESTATE_PRICE: null | string,
  PLAN_ROOM_LAYOUT: string,
  USER_PR_HP: string,
  USER_ID: string,
  USER_NAME: string,
  PROFILE_IMAGE: string,
  ESTATE_STATUS_ID: string,
  HP_FLAG: string,
  PORTAL_ESTATE_NAME: string,
  MYPAGE_FLAG: string,
  latitude: string,
  longitude: string,
  STATION_ACCESS1: string | null,
  STATION_ACCESS2: string | null,
  STATION_ACCESS3: string | null,

  // TODO so adicionei para fazer o deploy
  ESTATE_TYPE_DETAIL: string
  BALCONY: string
  MAIN_LIGHTING: string
  SPACE_DETAIL: string
  STRUCTURE: string
  FLOOR_QUANTITY: string
  building: {
    completionType: ConstructionCompletionType | null,
    builtAt: string | null,
  },

  land: {
    area: number,
    publicAreaType: LandAreaType | null,
    restriction: {
      type: BuildingRestriction | null,
      other: string,
      suumo: string, // Suumo it is a site that sell houses in Japan
    } | null,
    wallMeasureMethod: MeasureType | null,
  },
  garages: {
    car: {
      availability: GarageAvailability,
      price: number | { min: number, max: number } | null,
      distance: number,
      quantity: { normal: number, mini: number } | null,
    } | null,
  },

  surroundings: {
    trainStations: Array<{
      id: number,
      name: string,
      rail: { id: number; name: string; } | null,
      access: Access | null,
    } | {
      name: string,
      rail: { name: string } | null,
      access: Access | null,
    }>
  }

  images: {
    layout: string[],
    exterior: string[]
  }

  areas: {
    floor: {
      area: { value: number, measureType: MeasureType | null },
      buildAreaType: LandAreaType | null,
    }
  }

  rights: {
    ownership: EstateOwnership | null,
  }
}

type RomajiEstateType = 'mansion' | 'ikkodate' | 'shueki' | 'tochi';

const romajiEstateTypeMap: Record<EstateType, RomajiEstateType | ''> = {
  [EstateType.apartment]: 'mansion',
  [EstateType.house]: 'ikkodate',
  [EstateType.business]: 'shueki',
  [EstateType.land]: 'tochi',
};

const urbanPlanningMap: Record<UrbanPlanningType, UrbanPlanning> = {
  市街化区域: UrbanPlanning.urbanArea,
  調整区域: UrbanPlanning.regulatedArea,
  非線引区域: UrbanPlanning.unplannedArea,
  区域外: UrbanPlanning.outsideTheArea,
  準都市区域: UrbanPlanning.semiUrbanArea,
};

const kanjiToEstateTypeMap: Record<KanjiEstateType, EstateType> = {
  マンション: EstateType.apartment,
  一戸建て: EstateType.house,
  収益物件: EstateType.business,
  事業用物件: EstateType.business,
  土地: EstateType.land,
};

const stringToMeasureMethod: Record<string, MeasureType> = {
  '01': MeasureType.BetweenCenterWall,
  '02': MeasureType.BetweenSurfaceWall,
};

const stringToEstateOwnership: Record<string, EstateOwnership> = {
  FULL: EstateOwnership.Full,
  PARTIAL: EstateOwnership.Partial,
};

export interface GetEstatesListAreasResult {
  estateCounts: {
    homepage: number,
    myPage: number,
  },
  prefectures: Pref[],
  cities: City[],
  towns: Town[],
  estatePrice: { min: number, max: number },
  landArea: { min: number, max: number },
  floorArea: { min: number, max: number },
  floorAreaApartment: { min: number, max: number },
}

interface GetEstatesListStationsResult {
  rails: Rail[],
  stations: Station[],
}

export interface ListEstateForm {
  estateIds?: number[],
  estateType?: EstateType,
  condition?: 'new' | 'used'
  prefCities?: PrefCity[]
  order?: EstateOrder
  openHouseId?: number
  ohEventId?: number
  ohEventTypeId?: number
  typeId?: ListEstateType
  fcShopId?: number
  sendDate?: Date
  limit?: number
  page?: number
  onlyMe?: boolean
  roomTypes?: (RoomTypes)[]
  minPrice?: number
  maxPrice?: number
  stationDistance?: number
  landMinArea?: number
  landMaxArea?: number
  squareMeterPrice?: number
  buildingMinArea?: number
  buildingMaxArea?: number
  propertyConstructMinArea?: number
  propertyConstructMaxArea?: number
  floors?: number
  years?: number
  searchForTypes?: {
    land: boolean,
    newHouse: boolean,
    usedHouse: boolean,
    newApartment: boolean,
    usedApartment: boolean,
    business: boolean,
  }
  stations?: Array<{ id: number, railId: number | undefined }>,
  parking?: number
}

function convertListDoNetworkEstateResponse(estate: ListDoNetworkEstateResponse): Estate {
  const buildAtStr = estate.building.builtAt ? estate.building.builtAt.split('-') : [];
  return {
    id: Number(estate.ESTATE_ID),
    catch: estate.CATCH,
    type: kanjiToEstateTypeMap[estate.ESTATE_TYPE_NAME],
    elementarySchool: estate.ELEMENTARYSCHOOL_NAME
      ? { distance: Number(estate.ELEMENTARYSCHOOL_DISTANCE), name: estate.ELEMENTARYSCHOOL_NAME }
      : undefined,
    juniorHighSchool: { name: estate.JUNIORHIGHSCHOOL_NAME },
    landPublicM2Space: estate.LAND_SPACE_M2_PUBLIC,
    name: estate.ESTATE_NAME,
    portalName: estate.PORTAL_ESTATE_NAME,
    price: Number(estate.ESTATE_PRICE),
    formattedPrice: estate.HOUSE_ESTATE_PRICE_FORMAT,
    formattedPriceUnit: estate.HOUSE_ESTATE_PRICE_FORMAT_UNIT,
    priceDownFlag: estate.PRICE_DOWN_FLAG === '1',
    room: { layoutName: estate.ROOM_LAYOUT_TYPE_NAME, quantity: Number(estate.ROOM_QUANTITY) },
    statusId: estate.ESTATE_STATUS_ID as EstateStatus,
    realTypeName: estate.REAL_ESTATE_TYPE_NAME,
    stateOfUse: estate.NEW_USED_TYPE_ID ? estate.NEW_USED_TYPE_ID : null,
    ad: {
      name: estate.ESTATE_NAME,
      web: estate.ESTATE_NAME_WEB, // TODO Remove this field
    },
    structureName: estate.structureName,
    floor: Number(estate.FLOOR),
    buildingTotalFloor: estate.FLOOR_ABOVE_QUANTITY.includes('階')
      ? Number(estate.FLOOR_ABOVE_QUANTITY.replace('階', ''))
      : Number(estate.FLOOR_ABOVE_QUANTITY),
    rentFlag: estate.SHUEKI_FLAG === '1',
    trafficEtc: estate.TRAFFIC_ETC,
    walkMinutesEtc: estate.WALK_MINUTES_ETC || '',
    address: estate.ESTATE_ADDRESS,
    floorString: estate.FLOOR ? estate.FLOOR : '',
    totalFloorSpace: estate.FLOOR_SPACE_TOTAL,
    totalMansionSpace: estate.FLOOR_SPACE_MANSION,
    totalPublicLandSpace: estate.LAND_SPACE_M2_PUBLIC,
    totalSpace: Number(estate.FLOOR_SPACE_TOTAL),
    floorSpaceMansion: Number(estate.FLOOR_SPACE_MANSION),
    tsubo: Number(estate.TSUBO),
    tsuboTanka: Number(estate.TSUBO_TANKA),
    estatesUses: [estate.ESTATE_USEA, estate.ESTATE_USEB].filter((use) => !!use),
    buildingRestrictTypeName: estate.BUILDING_RESTRICT_TYPE_NAME,
    businessTypeName: estate.REAL_ESTATE_TYPE_NAME,
    yieldRate: Number(estate.YIELD_RATE),
    buildingAreaRadio: estate.BUILDING_COVERAGE,
    utilAreaRadio: estate.PLOT_RATIO,
    district: {
      name: estate.USE_DISTRICT_NAME || '',
      date: estate.CHIKUNENGETSU ? new Date(...estate.CHIKUNENGETSU.split('/') as ConstructorParameters<typeof Date>) : undefined,
    },
    isSold: estate.ESTATE_STATUS_ID === EstateStatus.soldThirdParty || estate.ESTATE_STATUS_ID === EstateStatus.sold,
    lowModifyAt: new Date(estate.LOW_MODIFY_DATETIME),
    completionYear: Number(estate.COMPLETION_YEAR),
    completionMonth: Number(estate.COMPLETION_MONTH),
    isInHomepage: Boolean(Number(estate.HP_FLAG)),

    createdAt: new Date(estate.REGIST_DATETIME),

    town: { name: estate.TOWN_NAME },
    user: { name: estate.USER_NAME },
    fcShop: { id: Number(estate.FC_SHOP_ID) },
    pref: { id: Number(estate.PREF_ID), name: estate.PREF_NAME },
    city: { id: Number(estate.CITY_ID), name: estate.CITY_NAME },
    access: [{
      stationName: (estate.ms1sn || '').replace('駅', ''),
      railName: (estate.mr1rn || ''),
      busMinutes: estate.BUS_MINUTES1 ? Number(estate.BUS_MINUTES1.match(/\d+/)) : 0,
      busStopName: estate.BUS_STOP_NAME1 || '',
      walkMinutes: estate.WALK_MINUTES1 ? Number(estate.WALK_MINUTES1.match(/\d+/)) : 0,
      priority: estate.YUSEN_KOTSU_KBN_1 ? Number(estate.YUSEN_KOTSU_KBN_1) : null,
      stationAccess: estate.STATION_ACCESS1 ? estate.STATION_ACCESS1 : '',
    }, {
      stationName: (estate.ms2sn || '').replace('駅', ''),
      railName: (estate.mr2rn || ''),
      busMinutes: estate.BUS_MINUTES2 ? Number(estate.BUS_MINUTES2.match(/\d+/)) : 0,
      busStopName: estate.BUS_STOP_NAME2 || '',
      walkMinutes: estate.WALK_MINUTES2 ? Number(estate.WALK_MINUTES2.match(/\d+/)) : 0,
      priority: estate.YUSEN_KOTSU_KBN_2 ? Number(estate.YUSEN_KOTSU_KBN_2) : null,
      stationAccess: estate.STATION_ACCESS2 ? estate.STATION_ACCESS2 : '',
    }, {
      stationName: (estate.ms3sn || '').replace('駅', ''),
      railName: (estate.mr3rn || ''),
      busMinutes: estate.BUS_MINUTES3 ? Number(estate.BUS_MINUTES3.match(/\d+/)) : 0,
      busStopName: estate.BUS_STOP_NAME3 || '',
      walkMinutes: estate.WALK_MINUTES3 ? Number(estate.WALK_MINUTES3.match(/\d+/)) : 0,
      priority: estate.YUSEN_KOTSU_KBN_3 ? Number(estate.YUSEN_KOTSU_KBN_3) : null,
      stationAccess: estate.STATION_ACCESS3 ? estate.STATION_ACCESS3 : '',
    }].filter((item) => item.railName && item.stationName),
    accessEtc: {
      walkMinutes: Number.isNaN(Number(estate.WALK_MINUTES_ETC)) ? 0 : Number(estate.WALK_MINUTES_ETC),
      traffic: estate.TRAFFIC_ETC,
    },
    salesman: estate.USER_ID || estate.USER_NAME || estate.USER_PR_HP ? {
      id: Number(estate.USER_ID) || 0,
      name: estate.USER_NAME || '',
      prHp: estate.USER_PR_HP || '',
      profileImage: estate.PROFILE_IMAGE || '',
    } : undefined,

    // TODO
    estateTypeDetail: estate.ESTATE_TYPE_DETAIL,
    balcony: estate.BALCONY,
    mainLighting: estate.MAIN_LIGHTING,
    spaceDetail: estate.SPACE_DETAIL,
    structure: estate.STRUCTURE,
    floorQuantityString: estate.FLOOR_QUANTITY ? estate.FLOOR_QUANTITY : '',
    building: {
      completionType: estate.building?.completionType ? estate.building.completionType : null,
      builtAt: buildAtStr.length ? new Date(Number(buildAtStr[0]), Number(buildAtStr[1]) - 1) : null,
    },

    land: {
      area: estate.land.area,
      publicAreaType: estate.land.publicAreaType ? estate.land.publicAreaType : null,
      restriction: estate.land?.restriction ? {
        type: estate.land.restriction.type,
        other: estate.land?.restriction.other || '',
        suumo: estate.land?.restriction.other || '', // Suumo it is a site that sell houses in Japan
      } : null,
    },
    garages: {
      car: estate.garages.car ? {
        availability: estate.garages.car.availability,
        price: estate.garages.car.price,
        distance: estate.garages.car.distance,
        quantity: estate.garages.car.quantity,
      } : null,
    },
    images: {
      layout: estate.images.layout,
      exterior: estate.images.exterior,
    },
    surroundings: estate.surroundings,
    areas: estate.areas,
    rights: {
      ownership: estate.rights.ownership ? stringToEstateOwnership[estate.rights.ownership] : null,
    },
    sellContract: {
      sellerId: null,
      sellerPassword: null,
      sellEstateId: null,
      transactionTypeId: null,
      sellerRegisterDate: null,
      mediateContractRegisterDate: null,
      finalMediationContractDate: null,
      renewalMediationContractDate: null,
    },
    newUsedTypeName: estate.NEW_USED_TYPE_ID ? String(estate.NEW_USED_TYPE_ID) : '',
    fc: {
      id: estate.FC_ID ? Number(estate.FC_ID) : 0,
    }
  };
}

interface findNearbyImagesByEstateIdsHttpResponse {
  id: number;
  estateId: number;
  path: `/${string}`;
  title: string,
  commentary: string;
  type: string;

  locationFromEstate: {
    distance: {
      min: number | null;
      max: number | null;
    },
    walkTime: {
      min: number | null;
      max: number | null;
    }
  }
}

export default {
  async listEstatesByIds(form: { ids: number[] }): Promise<Estate[]> {
    const response = await axios.get('/estate/findByIds', {
      params: { ids: form.ids.join(',') },
    });
    const res = response.data as { estates: ListDoNetworkEstateResponse[] };
    return res.estates.map((item) => convertListDoNetworkEstateResponse(item));
  },

  async findNearbyImagesByLandLotId(form: { landLotId: number }): Promise<NearbPlace[]> {
    const response = await axios.get<findNearbyImagesByEstateIdsHttpResponse[]>('/estate/nearby-images/', {
      params: { landLotId: form.landLotId },
    });
    return response.data.map((item) => {
      return {
        title: item.title,
        referencePoint: {
          walkingMinutes: { min: item.locationFromEstate.walkTime.min || 0, max: item.locationFromEstate.walkTime.max || 0 },
          distanceMeters: { min: item.locationFromEstate.distance.min || 0, max: item.locationFromEstate.distance.max || 0 },
        },
      };
    });
  },

  async checkImageExists(url: string) {
    let result: boolean;
    try {
      const response = await fetch(url, { method: 'HEAD', mode: 'cors' });
      response.ok ? result = true : result = false;
    } catch {
      result = false;
    }
    return result;
  }

};
