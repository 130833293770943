import CryptoJS from 'crypto-js';

function stringToKey(token: string): CryptoJS.lib.WordArray{
    return CryptoJS.enc.Hex.parse(CryptoJS.SHA256(token).toString());
}

export function encryptMessage(message: string, token: string): string {
    const key = stringToKey(token);
    const iv = CryptoJS.lib.WordArray.random(128 / 8);
    const encrypted = CryptoJS.AES.encrypt(message, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return iv.toString(CryptoJS.enc.Hex) + ':' + encrypted.ciphertext.toString(CryptoJS.enc.Hex);
}

export function decryptMessage(encryptedData: string, token: string): string {
    const [ivHex, encryptedHex] = encryptedData.split(':');
    const iv = CryptoJS.enc.Hex.parse(ivHex);
    const encrypted = CryptoJS.enc.Hex.parse(encryptedHex);

    const key = stringToKey(token);

    const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: encrypted } as CryptoJS.lib.CipherParams,
        key,
        {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }
    );

    return decrypted.toString(CryptoJS.enc.Utf8);
}
