import { DateTime, Duration } from 'luxon';

type TimeOptions = {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
}

export type WeekdayNumbers = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type ISOTime = `${number}:${number}:${number}`;

export class Time {
  private luxon: Duration;

  constructor(readonly props: TimeOptions) {
    this.luxon = Duration.fromObject(props);
  }

  plus(addTime: TimeOptions): Time {
    const luxonTime = this.luxon.plus({ minute: addTime.minutes, hour: addTime.hours, second: addTime.seconds });
    return new Time({
      hours: luxonTime.hours,
      milliseconds: luxonTime.milliseconds,
      minutes: luxonTime.minutes,
      seconds: luxonTime.seconds,
    });
  }

  get iso(): ISOTime {
    return this.luxon.toISOTime({ suppressSeconds: true }) as ISOTime;
  }

  valueOf() {
    return this.luxon.valueOf();
  }

  toString() {
    return this.iso;
  }
}

export function useFormatDate() {
  return {
    formatDateTime(date: Date | undefined | null): string {
      if (!date) return '';
      return DateTime.fromJSDate(date).setLocale('ja').toLocaleString(DateTime.DATETIME_SHORT);
    },
    formatDate(date: Date | undefined | null): string {
      if (!date) return '';
      return DateTime.fromJSDate(date).setLocale('ja').toLocaleString(DateTime.DATE_SHORT);
    },
    newDate(): Date {
      return DateTime.now().toJSDate();
    },
    newDateTimeFrom(dateTime: string): Date {
      return DateTime.fromFormat(dateTime, 'yyyy/MM/dd HH:mm').toJSDate();
    },
    yearNumber(): number {
      return DateTime.now().year;
    },
    getWeekDayNumber(date: Date): WeekdayNumbers {
      const dateTime = DateTime.fromJSDate(date);
      return dateTime.weekday === 7 ? 0 : dateTime.weekday;
    },
    createTime(timeOps: TimeOptions) {
      return new Time(timeOps);
    },
    plusTime(duration: string, time: TimeOptions): string {
      return Duration.fromISOTime(duration).plus(time).toISOTime({ suppressSeconds: true });
    },
    toDate(date: string): Date {
      return DateTime.fromISO(date).toJSDate();
    },
    toDateTime(date: string | Date): DateTime {
      const type = typeof date;
      if (type === 'string') return DateTime.fromISO(<string>date);
      return DateTime.fromJSDate(<Date>date);
    },
    formatDateYearAndMonth(date: Date | undefined | null): string {
      if (!date) return '';
      return DateTime.fromJSDate(date).toFormat('YYYY/mm');
    },
    formatDateString(date: Date | undefined | null, format: string): string {
      if (!date) return '';
      return DateTime.fromJSDate(date).toFormat(format);
    },
    nowDate(): DateTime {
      return DateTime.now();
    },
    toFormat(date: string | Date): string {
      const dateTime = date instanceof Date ? DateTime.fromJSDate(date) : DateTime.fromISO(date);
      return dateTime.setLocale('ja').toFormat("yyyy'年'MM'月'dd'日'");
    },
    weekNumber(date: string): number {
      return DateTime.fromISO(date).weekday;
    },
    subtract(date: string | undefined | null, quantity: object): string | DateTime {
      if (!date) return '';
      return DateTime.fromISO(date).minus(quantity);
    },
    add(date: string | undefined | null, quantity: object): string | DateTime {
      if (!date) return '';
      return DateTime.fromISO(date).plus(quantity);
    },
    daysOfMonth(year: number, month: number): number {
      return DateTime.local(year, month).daysInMonth;
    },
    formatDateWithWeekDay(date: Date) {
      return DateTime.fromJSDate(date).setLocale("ja-jp").toFormat("yyyy年M月d日 (ccc)");
    }
  };
}
