// import router from "../router";
import axios, { AxiosError, AxiosResponse } from 'axios';

import { useAuthStore } from '@/store/auth.store';
import { readonly, Ref, ref } from 'vue';
import { useRouter } from 'vue-router';
import ApiError from '@/domain/errors/ApiError';
import NotFoundError from '@/domain/errors/NotFoundError';
import router from "@/router";

interface HttpComposable<Form, Response> {
  (form: Form) : Promise<Response>;
  loading: Ref<boolean>;
  data: Ref<Response | null>;
  error: Ref<ApiError | null>;
  request: (form: Form) => Promise<Response>;
  [key: string]: unknown;
}

interface HttpComposableList<Form, Response> {
  (form: Form) : Promise<Response>;
  loading: Ref<boolean>;
  data: Ref<Response>;
  error: Ref<ApiError | null>;
  request: (form: Form) => Promise<Response>;
  [key: string]: unknown;
}

interface UseHttpOptions {
  singleton?: boolean,
}

type HttpFn<Form, Response> = (form: Form)=> Promise<AxiosResponse<Response>> | Promise<Response>

const singletons = new Map<HttpFn<unknown, unknown>, Promise<unknown>>();

export function useHttp<Response, Form>(api: HttpFn<Form, Response>, opts?: UseHttpOptions): HttpComposable<Form, Response> {
  const options = opts || { singleton: false };
  const loading = ref(false);
  const error = ref<Error | null>(null);
  const data = ref<Response | null>(null);
  const authStore = useAuthStore();

  const request = async (form: Form): Promise<Response> => {
    loading.value = true;
    try {
      if (options.singleton) {
        const singleton = singletons.get(api as HttpFn<unknown, unknown>);
        if (singleton) return singleton as Response;
      }

      if (authStore.token) axios.defaults.headers.authorization = authStore.token;
      else if (axios.defaults.headers.authorization) delete axios.defaults.headers.authorization;
      const promiseRes = api(form);
      if (opts?.singleton) singletons.set(api as HttpFn<unknown, unknown>, promiseRes as Promise<unknown>);
      const res = await promiseRes;
      if (opts?.singleton) singletons.delete(api as HttpFn<unknown, unknown>);
      const axiosRes = res as AxiosResponse<Response>;
      if (axiosRes?.headers && axiosRes?.config && axiosRes?.request) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data.value = axiosRes.data;
        if (axiosRes.headers.token) authStore.setToken(axiosRes.headers.token);
        // if (res.data.token) authStore.setToken(res.headers.token);
        return axiosRes.data;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data.value = axiosRes as unknown as Response;
      return res as Response;
    } catch (e) {
      if (e && typeof e === 'object' && (('data' in e && e.data === 'UnauthorizedError') || ('message' in e && (e.message === 'CUSTOMER_NOT_FOUND' || e.message === 'CUSTOMERTOKEN_NOT_FOUND')))) {
        const authStore = useAuthStore();
        authStore.removeUser();
        authStore.removeToken();
        localStorage.clear();
        router.push({ name: 'Login' });
      }
      error.value = (e as Error);
      throw error.value;
    } finally {
      loading.value = false;
    }
  };
  request.loading = readonly(loading);
  request.error = readonly(error);
  request.data = readonly(data);
  request.request = request;

  return request as unknown as HttpComposable<Form, Response>;
}
export function useHttpList<Response, Form>(api:HttpFn<Form, Response>, opts?: UseHttpOptions): HttpComposableList<Form, Response> {
  const options = opts || { singleton: false };
  const router = useRouter();
  const loading = ref(false);
  const error = ref<Error | null>(null);
  const data = ref<Response>([] as unknown as Response);
  const authStore = useAuthStore();

  const request = async (form: Form): Promise<Response> => {
    loading.value = true;
    try {
      if (options.singleton) {
        const singleton = singletons.get(api as HttpFn<unknown, unknown>);
        if (singleton) return singleton as Response;
      }

      if (authStore.token) axios.defaults.headers.authorization = authStore.token;
      const promiseRes = api(form);
      if (opts?.singleton) singletons.set(api as HttpFn<unknown, unknown>, promiseRes as Promise<unknown>);
      const res = await promiseRes;
      if (opts?.singleton) singletons.delete(api as HttpFn<unknown, unknown>);
      const axiosRes = res as AxiosResponse<Response>;
      if (axiosRes.headers && axiosRes.config && axiosRes.request) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data.value = axiosRes.data;
        if (axiosRes.headers.token) authStore.setToken(axiosRes.headers.token);
        // if (res.data.token) authStore.setToken(res.headers.token);
        return axiosRes.data;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data.value = axiosRes as unknown as Response;
      return res as Response;
    } catch (e) {
      error.value = (e as Error);
      if (e && typeof e === 'object' && (('data' in e && e.data === 'UnauthorizedError') || ('message' in e && (e.message === 'CUSTOMER_NOT_FOUND' || e.message === 'CUSTOMERTOKEN_NOT_FOUND')))) {
        const authStore = useAuthStore();
        authStore.removeUser();
        authStore.removeToken();
        localStorage.clear();
        router.push({ name: 'Login' });
      }
      throw error.value;
    } finally {
      loading.value = false;
    }
  };

  request.loading = readonly(loading);
  request.error = readonly(error);
  request.data = readonly(data);
  request.request = request;

  return request as unknown as HttpComposableList<Form, Response>;
}
