import { useHttp } from '@/composable/service/useHttp';
import userHttp, { DeleteMyselfForm, UpdateUserForm } from '@/services/http/user.http';
import { useAuthStore } from '@/store/auth.store';

export function useGetAuth() {
  const http = useHttp(userHttp.verifyCustomerAuth);
  const authStore = useAuthStore();
  const user = authStore.currentUser;

  const getAuth = async () => {
    await http.request({id: user?.id});
  };
  return {
    authLoading: http.loading,
    authError: http.error,
    getAuth,
    auth: http.data,
  };
}


export function useGetMe() {
  const http = useHttp(userHttp.getMe);
  const authStore = useAuthStore();
  const user = authStore.currentUser;

  const getMe = async () => {
    await http.request({});
    if (http.data.value && authStore.currentUser) {
      authStore.setUser({
        ...authStore.currentUser,
        firstName: http.data.value.firstName,
        lastName: http.data.value.lastName,
        firstNameKana: http.data.value.firstNameKana,
        lastNameKana: http.data.value.lastNameKana,
        age: http.data.value.age,
        zipCode: http.data.value.zipCode,
        prefId: http.data.value.prefId,
        cityId: http.data.value.cityId,
        address: http.data.value.address,
        tel: http.data.value.tel,
        fax: http.data.value.fax,
        email: http.data.value.email,
        twoStepVerificationFlag: http.data.value.twoStepVerificationFlag,
        contactFaxFlag: http.data.value.contactFaxFlag,
        contactMailFlag: http.data.value.contactMailFlag,
        contactTelFlag: http.data.value.contactTelFlag,
        contactPostFlag: http.data.value.contactPostFlag,
      });
    }
  };
  return {
    meLoading: http.loading,
    meError: http.error,
    getMe,
    me: http.data,
  };
}

export function useUpdateMyself() {
  const http = useHttp(userHttp.update);
  const authStore = useAuthStore();
  return {
    updateMyselfLoading: http.loading,
    updateMyselfError: http.error,
    updateMyself: async (form: UpdateUserForm) => {
      if (!authStore.currentUser) return;
      const user = authStore.currentUser;
      await http.request({ ...form, id: user.id });
      authStore.setUser({
        ...authStore.currentUser,
        firstName: form.firstName !== undefined ? form.firstName : user.firstName,
        lastName: form.lastName !== undefined ? form.lastName : user.lastName,
        firstNameKana: form.firstNameKana !== undefined ? form.firstNameKana : user.firstNameKana,
        lastNameKana: form.lastNameKana !== undefined ? form.lastNameKana : user.lastNameKana,
        age: form.age !== undefined ? form.age : user.age,
        zipCode: form.zipCode !== undefined ? form.zipCode : user.zipCode,
        prefId: form.prefId !== undefined ? form.prefId : user.prefId,
        cityId: form.cityId !== undefined ? form.cityId : user.cityId,
        address: form.address !== undefined ? form.address : user.address,
        tel: form.tel !== undefined ? form.tel : user.tel,
        fax: form.fax !== undefined ? form.fax : user.fax,
        email: form.email !== undefined ? form.email : user.email,
        twoStepVerificationFlag: form.twoStepVerificationFlag !== undefined ? form.twoStepVerificationFlag : user.twoStepVerificationFlag,
        contactFaxFlag: form.contactFaxFlag !== undefined ? form.contactFaxFlag : user.contactFaxFlag,
        contactMailFlag: form.contactMailFlag !== undefined ? form.contactMailFlag : user.contactMailFlag,
        contactTelFlag: form.contactTelFlag !== undefined ? form.contactTelFlag : user.contactTelFlag,
        contactPostFlag: form.contactPostFlag !== undefined ? form.contactPostFlag : user.contactPostFlag,
      });
    },
  };
}

export function useVerifyCodeAndChangeEmail() {
  const http = useHttp(userHttp.verifyCodeAndChangeEmail);
  const authStore = useAuthStore();
  return {
    verifyCodeAndChangeEmailLoading: http.loading,
    verifyCodeAndChangeEmailError: http.error,
    verifyCodeAndChangeEmail: async (form: UpdateUserForm, code: string, token: string) => {
      if (!authStore.currentUser) return;
      const user = authStore.currentUser;
      await http.request({ ...form, id: user.id, code, token });
      authStore.setUser({
        ...authStore.currentUser,
        firstName: form.firstName !== undefined ? form.firstName : user.firstName,
        lastName: form.lastName !== undefined ? form.lastName : user.lastName,
        firstNameKana: form.firstNameKana !== undefined ? form.firstNameKana : user.firstNameKana,
        lastNameKana: form.lastNameKana !== undefined ? form.lastNameKana : user.lastNameKana,
        age: form.age !== undefined ? form.age : user.age,
        zipCode: form.zipCode !== undefined ? form.zipCode : user.zipCode,
        prefId: form.prefId !== undefined ? form.prefId : user.prefId,
        cityId: form.cityId !== undefined ? form.cityId : user.cityId,
        address: form.address !== undefined ? form.address : user.address,
        tel: form.tel !== undefined ? form.tel : user.tel,
        fax: form.fax !== undefined ? form.fax : user.fax,
        email: form.email !== undefined ? form.email : user.email,
        twoStepVerificationFlag: form.twoStepVerificationFlag !== undefined ? form.twoStepVerificationFlag : user.twoStepVerificationFlag,
        contactFaxFlag: form.contactFaxFlag !== undefined ? form.contactFaxFlag : user.contactFaxFlag,
        contactMailFlag: form.contactMailFlag !== undefined ? form.contactMailFlag : user.contactMailFlag,
        contactTelFlag: form.contactTelFlag !== undefined ? form.contactTelFlag : user.contactTelFlag,
        contactPostFlag: form.contactPostFlag !== undefined ? form.contactPostFlag : user.contactPostFlag,
      });
    },
  };
}

export function useDeleteMyself() {
  const http = useHttp(userHttp.deleteMyself);
  const authStore = useAuthStore();
  const { updateMyself, updateMyselfLoading, updateMyselfError } = useUpdateMyself();
  return {
    deleteMyselfLoading: http.loading || updateMyselfLoading,
    deleteMyselfError: http.error || updateMyselfError,
    deleteMyself: async (form: DeleteMyselfForm, updateForm?: UpdateUserForm) => {
      if (updateForm) {
        await updateMyself({ ...updateForm, willBeDeleted: true });
      }
      const res = await http.request(form);
      authStore.removeToken();
      authStore.removeUser();
      return res;
    },
  };
}


