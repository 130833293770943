import {
  AccessPriority,
  BuildingRestriction,
  ConstructionCompletionType,
  Estate,
  EstateDetail,
  EstateStatus,
  EstateType,
  GarageType,
  LandAreaType,
  LocomotionType,
  MeasureType,
  Ownership, RoadType,
  StateOfUse,
  UrbanPlanning
} from '@/domain/estate.entity';
import estateDonetworkHttp, {EstateDetailImage} from '@/services/http/estate.donetwork.http';
import { DateTime } from 'luxon';
import { useFormatDate } from '@/composable/ui/useDate';
import { computed, Ref, unref } from 'vue';

type BuildingRestrictionString = '建築条件付' | '更地渡し(建築条件なし)' | '現況渡し(建築条件なし)' | '相談' | 'オーナーチェンジ' | '建築条件なし';
const buildingRestrictionString: Record<BuildingRestriction, BuildingRestrictionString> = {
  [BuildingRestriction.BuildingType]: '建築条件付',
  [BuildingRestriction.CleaningLand]: '更地渡し(建築条件なし)',
  [BuildingRestriction.StateTransfer]: '現況渡し(建築条件なし)',
  [BuildingRestriction.Consultation]: '相談',
  [BuildingRestriction.OwnerChange]: 'オーナーチェンジ',
};

const wallMeasureMethodToString: Record<MeasureType, string> = {
  [MeasureType.BetweenCenterWall]: '壁芯',
  [MeasureType.BetweenSurfaceWall]: '内法',
};

const publicAreaToString: Record<LandAreaType, '公'| '実'> = {
  [LandAreaType.Public]: '公',
  [LandAreaType.Actual]: '実',
};

const newStateOfUseToString: Record<StateOfUse, string> = {
  [StateOfUse.Used]: '中古',
  [StateOfUse.New]: '新築',
}
export default function useEstateTextFormatter(estateRef?: Estate | Ref<Estate>) {
  const { formatDateYearAndMonth, newDate, newDateTimeFrom, formatDateString } = useFormatDate();

  function convertTypeName(estate: Estate) {
    type KanjiEstateType = 'マンション' | '一戸建て'| '収益物件'| '事業用物件'| '土地';
    const estateTypeToKanjiMap: Record<EstateType, KanjiEstateType> = {
      [EstateType.apartment]: 'マンション',
      [EstateType.house]: '一戸建て',
      [EstateType.business]: '事業用物件',
      [EstateType.land]: '土地',
    };
    return estateTypeToKanjiMap[estate.type];
  }

  function getTitle(estate: Estate) {
    let title = '';
    if (estate.portalName) {
      title += `${estate.portalName}`;

    } else if ([EstateType.apartment, EstateType.business].includes(estate.type)) {
        title += `${estate.name} `;
    } else {

      title += estate.city.name;
      title += estate.town.name !== '　' ? `${estate.town.name}` : '';
      if ([EstateType.house, EstateType.apartment].includes(estate.type)) title += estate.stateOfUse ? newStateOfUseToString[estate.stateOfUse] : '';

      if (estate.type === EstateType.land) {title += convertTypeName(estate);}
      else if ([EstateType.business].includes(estate.type)) {title += `${convertTypeName(estate)}(${estate.realTypeName})`;}
      else {title += estate.realTypeName;}
    }

    return title;
  }

  function isPriceDown(estate: Estate) {
    return estate.priceDownFlag;
  }

  function isNew(estate: Estate) {
    if (!estate.createdAt) return false;
    const registerDate = DateTime.fromJSDate(estate.createdAt);
    const before30days = DateTime.now().minus({ days: 30 });
    return registerDate > before30days;
  }

  function getNewOrPriceDownStr(estate: Estate): string {
    const isNewEstate = isNew(estate);
    if (isNewEstate && estate.priceDownFlag) return '';
    if (isNewEstate) return '';
    if (estate.priceDownFlag) return '値下げ';
    return '';
  }

  function formatModifyDatetime(estate: Estate) {
    const modifyDate = DateTime.fromJSDate(estate.lowModifyAt);
    const checkDate = DateTime.now().minus({ days: 14 });
    if (modifyDate < checkDate) {
      const diff = 7 - modifyDate.weekYear + DateTime.now().weekYear;
      const ret = DateTime.now().minus({ days: diff });
      return ret.toFormat('y年M月d日');
    }
    return modifyDate.toFormat('y年M月d日');
  }

  function formatCompletionDate(estate: Estate) {
    let completionStr = '';
    if (estate.completionYear) completionStr = `${estate.completionYear}年`;
    if (estate.completionMonth) completionStr += `${estate.completionMonth}月`;
    if (!completionStr) return formatDateYearAndMonth(estate.district?.date);
    return completionStr;
  }

  function formatSenyoSpaceMansion(estate: Estate) {
    let spaceMansion = '';
    // TODO retirei a formatacao aqui para trazer direto do SQL
    if (estate.floorSpaceMansion) spaceMansion = `${estate.floorSpaceMansion}`;
    if (estate.floorSpaceMansion && estate.tsubo) spaceMansion += `/${estate.tsubo}`;
    return spaceMansion;
  }

  function formatTransport(transport: Estate['access'][number]) {
    const {
      railName, stationName, walkMinutes, busMinutes, busStopName, priority,
    } = transport;
    if (!railName && !stationName && !walkMinutes) return '';
    if (priority === AccessPriority.WalkingToTrainStation) return `${railName} 『${stationName}駅』まで徒歩${walkMinutes}分`;
    if (priority === AccessPriority.BusToTrainStation) return `${railName} 『${stationName}駅』まで車${busMinutes}分`;
    if (priority === AccessPriority.BusToTrainStationAndWalkToBusStation) {
      return `${railName}『${stationName}駅』バス乗り場${busMinutes}分 バス停『${busStopName}』まで徒歩${walkMinutes}分`;
    }
    return '';
  }

  function formatAccess(access: Estate['access'][number]) {
    return formatTransport(access);
  }

  function formatTrainStation(station: Estate['surroundings']['trainStations'][number]) {
    let res = `${station.rail?.name || ''} 『${station.name}駅』`;
    if (!station.access) return res;
    const { access } = station;
    if (access.type === LocomotionType.Car) res += ` まで車${access.time}分${access.distance ? ` （約${access.distance}km）` : ''}`;
    if (access.type === LocomotionType.Walk) res += ` まで徒歩${access.time}分${access.distance ? ` （約${access.distance}m）` : ''}`;
    if (access.type === LocomotionType.Bus) {
      res += ` までバス乗車${access.time}分 ${access.busLineName} ${access.busStopName} 停 徒歩 ${access.walkTime}分`;
      if (access.distance) res += ` （約${access.distance}m）`;
    }

    return res;
  }

  function formatAccessEtc(accessEtc: Estate['accessEtc']) {
    const { walkMinutes, traffic } = accessEtc;
    let result = '';
    if (traffic) result += traffic;
    if (walkMinutes) result += `${walkMinutes}分`;
    return result;
  }

  function getOutLineTitle(estate: Estate) {
    if (estate.portalName) return estate.portalName;
    if (estate.rentFlag) {
      return estate.city.name + (estate.town.name !== '　' ? estate.town.name : '');
    }
    if (estate.type === EstateType.apartment) return estate.name;
    return estate.city.name + (estate.town.name !== '　' ? estate.town.name : '');
  }

  function getProfileImage(profileImage: string) {
    if (!profileImage) return '';
    return `${import.meta.env.VITE_APP_DO_IMAGE_URL}/img/ieurikai/user/${profileImage.slice(0, 8)}/${profileImage}`;
  }

  function getProfileImageFromPath(profileImage: string) {
    if (!profileImage) return '';
    return `${import.meta.env.VITE_APP_DO_IMAGE_URL}${profileImage}`;
  }

  async function getDetailImage(estateId: number, getType = 'all') {
    const data = await estateDonetworkHttp.getEstateDetailImage({ estateId });

    const exterior = data.find((item) => item.path.includes('exterior'));
    const roomLayout = data.find((item) => item.path.includes('roomlayout'));

    const isExteriorType = ['all', 'exterior'].includes(getType);
    const isRoomType = ['all', 'exterior'].includes(getType);

    let path = '';
    if (exterior && isExteriorType) path = exterior.path;
    else if (roomLayout && isRoomType) path = roomLayout.path;
    else return '/img/noimg_l.jpg';

    return `${import.meta.env.VITE_APP_DO_IMAGE_URL}${path}`;
  }

  function getLandLotImage(path: string) {
    if (path) {
      return `${import.meta.env.VITE_APP_DO_IMAGE_URL}${path.replace('estate', 'kawa')}`;
    }
    return 'noimg_l.jpg';
  }
  function getOnSaleImage(path: string) {
    if (path) {
      return `${import.meta.env.VITE_APP_DO_IMAGE_URL}${path}`;
    }
    return 'noimg_l.jpg';
  }
  function getOnSaleImageL(path: string) {
    const lastIndex = path.lastIndexOf('.');
    let modifiedPath = path.slice(0, lastIndex) + 'L' + path.slice(lastIndex);
    if (path) {
      return `${import.meta.env.VITE_APP_DO_IMAGE_URL}${modifiedPath}`;
    }
    return 'noimg_l.jpg';
  }

  function formatFloor(estate: Estate) {
    if (estate.type === EstateType.apartment) {
      return estate.buildingTotalFloor ? `${estate.buildingTotalFloor}階建て${estate.floor}階` : ' - ';
    }
    return estate.buildingTotalFloor ? `${estate.buildingTotalFloor}階` : '';
  }

  function formatFloorForDetails(estate: EstateDetail) {
    if (estate.type === EstateType.apartment || (estate.type === EstateType.business && estate.ownership === Ownership.Partial)) {
      return estate.buildingTotalFloor ? `${estate.buildingTotalFloor}階建て${estate.floor}階` : ' - ';
    }
    return estate.buildingTotalFloor ? `${estate.buildingTotalFloor}階` : '';
  }

  function formatLandSpaceM2Public(estate: Estate) {
    return estate.landPublicM2Space ? `${estate.landPublicM2Space}m²` : ' - ';
  }

  function formatYearlyIncome(estate: Estate) {
    if (!estate.yieldRate) return ' - ';
    if (estate.price && estate.yieldRate) return `${estate.yieldRate * estate.price * 100}円`;
    return ' - ';
  }

  function formatConstructionDate(estate: Estate) {
    let result = '';
    if (estate.completionYear) result += `${estate.completionYear}年`;
    if (estate.completionMonth) result += `${estate.completionMonth}月`;
    if (!result) return formatDateYearAndMonth(estate.district?.date);
    return result;
  }

  function formatElementarySchool(elementarySchool: Estate['elementarySchool']) {
    if (!elementarySchool || !elementarySchool.distance) return '';
    return `${elementarySchool.name}(約${elementarySchool.distance}m)`;
  }

  function isSold(estate: Estate) {
    return estate.statusId === EstateStatus.soldThirdParty || estate.statusId === EstateStatus.sold;
  }

  function formatBuildingRate(estate: Estate) {
    return (estate.buildingAreaRadio ? `${estate.buildingAreaRadio}% /` : '')
      + (estate.buildingAreaRadio && estate.utilAreaRadio ? `${estate.utilAreaRadio}%` : '');
  }

  function createEstateImgUrl(imageFileName: string, lMode: boolean) {
    let lastImage = '';
    if (lMode) {
      const arrayImg = imageFileName.split('.');
      if (arrayImg.length === 2) {
        lastImage = `${arrayImg[0]}L.${arrayImg[1]}`;
      }
    }
    if (lastImage.includes('neighborhood')) {
      lastImage = lastImage.substr(8, imageFileName.length);
    }
    let tempUrl = '/img/';
    tempUrl += `ieurikai/estate/${imageFileName.substr(0, 4)}`;
    tempUrl += `/${imageFileName.substr(0, 8)}/${lastImage}`;
    return import.meta.env.VITE_APP_DO_IMAGE_URL + tempUrl;
  }

  function createPRBannerURL(imageName: string) {
    let lastImage = '';
    let tempUrl = '/img/';
    tempUrl += `ieurikai/estate/${imageName.substr(0, 4)}`;
    tempUrl += `/${imageName.substr(0, 8)}/${lastImage}`;
    return import.meta.env.VITE_APP_DO_IMAGE_URL + tempUrl + imageName;
  }

  async function checkImageExists(url: string) {
    let result: boolean;
    try {
      const response = await fetch(url, { method: 'HEAD' });
      response.ok ? result = true : result = false;
    } catch {
      result = false;
    }
    return result;
  }

  async function createLargeFcShopImageURL(imageName: string) {
      const imageUrl = (
        `${import.meta.env.VITE_APP_DO_IMAGE_URL
        }/img/ieurikai/fcshop/${
          imageName.substr(0, 8)
        }/${
          imageName}`
      );
      const largeUrl = imageUrl.replace(/(\.[^.]+)$/, 'L$1');
      const validLUrl = await checkImageExists(largeUrl)
      if(validLUrl) return largeUrl;
      const validUrl = await checkImageExists(imageUrl)
      return validUrl ? imageUrl : 'noimg_l.jpg';
  }

  function createFcShopImageURL(imageName: string) {
    if (imageName) {
      return (
        `${import.meta.env.VITE_APP_DO_IMAGE_URL
        }/img/ieurikai/fcshop/${
          imageName.substr(0, 8)
        }/${
          imageName}`
      );
    }
    return 'noimg_l.jpg';
  }

  function isNewEstateForEstateDetail(item: EstateDetail) {
    const now = DateTime.now();
    let estateDate = DateTime.now();
    if (item.realRegisterDatetime) {
      estateDate = DateTime.fromSQL(item.realRegisterDatetime);
    }
    return now.diff(estateDate, 'days').as('days') <= 30;
  }

  function verifyShinyabinEstate(estate: string) {
    return estate ? estate.includes('-') : false;
  }

  function convertHouseDoName(data: string) {
    let dataFormatted = '';
    if (data) {
      dataFormatted = data
        .replace('Housedo！', 'Housedo')
        .replace('Housedo!', 'Housedo');
      dataFormatted = data
        .replace('ハウスドゥ！', 'ハウスドゥ')
        .replace('ハウスドゥ!', 'ハウスドゥ');
    }
    return dataFormatted;
  }

  function otherSpaceFormat(item: EstateDetail) {
    const otherSpace = [];
    let balconyDirection = '';
    if (item.balconyDirectionId !== '' && item.balconySpace && item.balconySpaceAroundFlag) {
      balconyDirection = `${item.balconyDirectionName}向きバルコニー:${item.balconySpace}m²(おおよその面積)`;
    } else if (item.balconyDirectionId !== '' && item.balconySpace && !item.balconySpaceAroundFlag) {
      balconyDirection = `${item.balconyDirectionName}向きバルコニー:${item.balconySpace}m²`;
    } else if (item.balconyDirectionId === '' && item.balconySpace && item.balconySpaceAroundFlag) {
      balconyDirection = `バルコニー:${item.balconySpace}m²(おおよその面積)`;
    } else if (item.balconyDirectionId === '' && item.balconySpace && !item.balconySpaceAroundFlag) {
      balconyDirection = `バルコニー:${item.balconySpace}m²`;
    }
    if (balconyDirection) otherSpace.push(balconyDirection);
    let roofBalcony = '';
    if (item.roofBalconyFlag) {
      roofBalcony = 'ルーフバルコニー:';
      if (item.roofBalconySpace && item.roofBalconySpace !== '') {
        roofBalcony += `${item.roofBalconySpace}m² `;
      } else {
        roofBalcony += '－m²';
      }
      if (item.roofBalconyPrice === 0) {
        roofBalcony += '(使用料無)';
      } else if (item.roofBalconyPrice) {
        roofBalcony += `(使用料:${new Intl.NumberFormat('ja-JP').format(item.roofBalconyPrice)}円/月)`;
      } else {
        roofBalcony += '';
      }
    }
    if (roofBalcony) otherSpace.push(roofBalcony);
    let onlyGarden = '';
    if (item.onlyGardenFlag) {
      onlyGarden = '専用庭:';
      if (item.onlyGardenSpace !== '') {
        onlyGarden += `${item.onlyGardenSpace}m²`;
      } else {
        onlyGarden += '－m²';
      }
      if (item.onlyGardenPrice === 0) {
        onlyGarden += '(使用料無)';
      } else if (item.onlyGardenPrice) {
        onlyGarden += `(使用料:${new Intl.NumberFormat('ja-JP').format(item.onlyGardenPrice)}円/月)`;
      } else {
        onlyGarden += '';
      }
    }
    if (onlyGarden) otherSpace.push(onlyGarden);
    let terrace = '';
    if (item.terraceFlag) {
      terrace = 'テラス:';
      if (item.terraceSpace) {
        terrace += `${item.terraceSpace}m²`;
      } else {
        terrace += '－m²';
      }
      if (item.terracePrice === 0) {
        terrace += '(使用料無)';
      } else if (item.terracePrice) {
        terrace += `(使用料:${new Intl.NumberFormat('ja-JP').format(item.terracePrice)}円/月)`;
      } else {
        terrace += '';
      }
    }
    if (terrace) otherSpace.push(terrace);
    return (
      otherSpace.join('\n')
      + item.wideBalcony
      + item.balcony2
      + item.balcony3
      + item.woodDeck
    ).replace('、', '\n');
  }
  function parkingStrTypeMansion(item: EstateDetail) {
    let type = '';
    const flagStr = [];
    if (item.garageTypeId === GarageType.hasVacancy) {
      type += '空有';
    } else if (item.garageTypeId === GarageType.noVacancy) {
      type += '空無';
    } else if (item.garageTypeId === GarageType.hasNearVacancy) {
      type += '近隣確保';
    } else if (item.garageTypeId === GarageType.noGarage) {
      type += '無し';
    } else if (item.garageTypeId === null) {
      type += '無し';
    }
    if (item.parking2Flag) {
      flagStr.push('駐車場2台分');
    }
    if (item.openParkingFlag) {
      flagStr.push('自走式駐車場');
    }
    if (item.parkingLotFlag) {
      flagStr.push('平面駐車場');
    }
    return type + (type && flagStr.length > 0 ? '/' : '') + flagStr.join('/');
  }

  function parkingStr(item: EstateDetail) {
    let ret = '';
    if (item.type === EstateType.apartment) {
      return parkingStrTypeMansion(item);
    }
    if (item.parkingFlag) {
      ret += `${item.parkingText}`;
    }
    if (item.parkingQuantity) {
      ret += `${item.parkingQuantity} `;
    }
    if (item.garageFlag) {
      ret += 'シャッター車庫有 ';
    }
    if (!ret) {
      ret = '無し';
    }
    return ret;
  }

  function formatCompletionStr(item: EstateDetail) {
    return (item.completionYear ? `${item.completionYear}年` : '') + (item.completionMonth ? `${item.completionMonth}月` : '');
  }

  function formatDwelling(item: EstateDetail) {
    return item.dwellingQuantity ? `${item.dwellingQuantity}戸 ${item.dwellingQuantityBiko}` : '';
  }
  function formatFloorSpaceMansion(item: EstateDetail) {
    return item.floorSpaceMansion ? `${item.floorSpaceMansion}m² ～  ${item.floorSpaceMansionUpper}m²` : '';
  }

  function getCostsStr(item: EstateDetail) {
    let costs = '';
    if (item.managementModeTypeName) {
      costs += item.managementModeTypeName;
      if ((item.costResidenceFlag && item.costResidenceText)
        || (item.costDayShiftFlag && item.costDayShiftText)
        || (item.costRoutineFlag && item.costRoutineText)) {
        costs += ' / ';
      }
    }
    if (item.costResidenceFlag) {
      costs += item.costResidenceText;
    }
    if (item.costDayShiftFlag) {
      costs += item.costDayShiftText;
    }
    if (item.costRoutineFlag) {
      costs += item.costRoutineText;
    }
    return costs;
  }

  function reformDataStr(item: EstateDetail) {
    const strArray = [];

    if (item.renovationYear && item.renovationMonth) {
      strArray.push(
        `${item.renovationYear
        }年${
          item.renovationMonth
        }月`
        + `【リノベーション${
          newDate()
        >= newDateTimeFrom(`${item.renovationYear}/${item.renovationMonth}/01`)
            ? '済み'
            : '予定'
        }】`,
      );
    }

    if (item.reformExecutionWaterYear && item.reformExecutionWaterMonth) {
      const water = [];
      if (item.reformExecutionKitchen) {
        water.push(item.reformExecutionKitchen);
      }
      if (item.reformExecutionBath) {
        water.push(item.reformExecutionBath);
      }
      if (item.reformExecutionToilet) {
        water.push(item.reformExecutionToilet);
      }
      if (item.reformExecutionWashstand) {
        water.push(item.reformExecutionWashstand);
      }
      if (item.reformExecutionHotWaterDispenser) {
        water.push(item.reformExecutionHotWaterDispenser);
      }
      if (item.reformExecutionPlumbing) {
        water.push(item.reformExecutionPlumbing);
      }
      if (item.reformExecutionWaterOther) {
        water.push(item.reformExecutionWaterOther);
      }
      strArray.push(
        `${item.reformExecutionWaterYear
        }年${
          item.reformExecutionWaterMonth
        }月`
        + `【水回りリフォーム${
          newDate()
        >= newDateTimeFrom(
          `${item.reformExecutionWaterYear}/${item.reformExecutionWaterMonth}/01`,
        )
            ? '済み'
            : '予定'
        }】${
          water.length > 0 ? water.join('・') : ''}`,
      );
    }

    if (item.reformExecutionInYear && item.reformExecutionInMonth) {
      const start = [];
      if (item.reformExecutionWall) {
        start.push(item.reformExecutionWall);
      }
      if (item.reformExecutionFloor) {
        start.push(item.reformExecutionFloor);
      }
      if (item.reformExecutionWhole) {
        start.push(item.reformExecutionWhole);
      }
      if (item.reformExecutionFitting) {
        start.push(item.reformExecutionFitting);
      }
      if (item.reformExecutionSash) {
        start.push(item.reformExecutionSash);
      }
      if (item.reformExecutionOther) {
        start.push(item.reformExecutionOther);
      }
      strArray.push(
        `${item.reformExecutionInYear
        }年${
          item.reformExecutionInMonth
        }月`
        + `【内装リフォーム${
          newDate()
        >= newDateTimeFrom(
          `${item.reformExecutionInYear}/${item.reformExecutionInMonth}/01`,
        )
            ? '済み'
            : '予定'
        }】${
          start.length > 0 ? start.join('・') : ''}`,
      );
    }

    if (item.reformExecutionOutYear && item.reformExecutionOutMonth) {
      const out = [];
      if (item.reformExecutionOutWall) {
        out.push(item.reformExecutionOutWall);
      }
      if (item.reformExecutionOutRoof) {
        out.push(item.reformExecutionOutRoof);
      }
      if (item.reformExecutionOutLarge) {
        out.push(item.reformExecutionOutLarge);
      }
      if (item.reformExecutionOutOther) {
        out.push(item.reformExecutionOutOther);
      }
      strArray.push(
        `${item.reformExecutionOutYear
        }年${
          item.reformExecutionOutMonth
        }月`
        + `【外装リフォーム${
          newDate()
        >= newDateTimeFrom(
          `${item.reformExecutionOutYear}/${item.reformExecutionOutMonth}/01`,
        )
            ? '済み'
            : '予定'
        }】${
          out.length > 0 ? out.join('・') : ''}`,
      );
    }
    if (item.reformExecutionBiko) {
      strArray.push(`【備考】${item.reformExecutionBiko}`);
    }
    return strArray.join('\n');
  }

  function formatBuildingRestriction(restriction: Estate['land']['restriction']): BuildingRestrictionString {
    if (!restriction || !restriction.type) return '建築条件なし';
    return buildingRestrictionString[restriction.type];
  }

  function getElementarySchoolAccessStr(item: EstateDetail) {
    let result = '';
    if (item.elementarySchool) {
      const distance = item.elementarySchool.distance || 0;
      const eSchoolMinutes = Math.ceil(distance / 80);
      result += item.elementarySchool.name;
      if (distance > 0) result += ` (約${distance}m/徒歩${eSchoolMinutes}分)`;
    }
    return result;
  }

  function getJuniorHighSchoolAccessStr(item: EstateDetail) {
    let jSchoolDist = '';
    const jSchoolMinutes = Math.ceil(item.juniorHighSchoolDistance / 80);
    if (item.juniorHighSchoolName) {
      jSchoolDist += item.juniorHighSchoolName;
    }
    if (item.juniorHighSchoolDistance > 0 && jSchoolMinutes > 0) {
      jSchoolDist
        += ` (約${
          item.juniorHighSchoolDistance
        }m/徒歩${
          jSchoolMinutes
        }分)`;
    }
    return jSchoolDist;
  }
  function getYearlyIncomeStrCalc(item: EstateDetail) {
    if (!item.yieldRate) return '-';
    if (item.price && item.yieldRate) return `${Math.floor(item.yieldRate * item.price * 100)}円`;
    return '-';
  }

  function getLandSpaceM2PublicStr(item: EstateDetail) {
    return `${item.landPublicM2Space}${item.tsubo && item.landPublicM2Space ? '/' : ''}${item.tsubo ? `${item.tsubo}坪` : ''}`;
  }

  function getFloorSpaceStr(item: EstateDetail) {
    const floorSpace = [];
    if (item.floorSpace1F) {
      floorSpace.push(`1F ${item.floorSpace1F}m²`);
    }
    if (item.floorSpace2F) {
      floorSpace.push(`2F ${item.floorSpace2F}m²`);
    }
    if (item.floorSpace3F) {
      floorSpace.push(`3F ${item.floorSpace3F}m²`);
    }
    if (item.floorSpaceETC) {
      floorSpace.push(`その他 ${item.floorSpaceETC}m²`);
    }
    if (floorSpace.length > 0) {
      return `(${floorSpace.join('/')})`;
    }
    return '';
  }

  function getPrivateRoadStr(item: EstateDetail) {
    let privateRoad = '';
    let privateRoadRate = '';
    if (item.privateRoadTypeId === 1) {
      privateRoad = 'なし';
    } else if (item.privateRoadSpace) {
      privateRoad += `${item.privateRoadSpace}m²`;
      if (item.privateRoadTypeName) {
        privateRoad += ` (${item.privateRoadTypeName})`;
      }
      if (item.privateRoadRateText) {
        privateRoadRate += item.privateRoadRateText;
      }
      if (item.privateRoadRateSpace) {
        privateRoadRate += ` (${item.privateRoadRateSpace}m²) `;
      }
      privateRoad += privateRoadRate ? ` 持分 ${privateRoadRate}` : '';
    }
    return privateRoad;
  }

  function getRoadStr(item: EstateDetail) {
    let road = '';
    const directionName = item.roadDirectionNames;
    const typeName = item.roadTypeNames;
    const span = item.roadSpans;
    const frontSpan = item.roadFrontSpans;
    for (let i = 0; i <= 2; i += 1) {
      if (directionName[i]) {
        road += `${directionName[i]}側`;
        if (typeName[i]) {
          road += ` (${typeName[i]}) `;
        } else {
          road += ' ';
        }
        if (span[i]) {
          road += `${span[i]}m `;
          if (frontSpan[i]) {
            road += `間口 ${frontSpan[i]}m `;
          }
        }
      }
    }
    return road;
  }

  // TODO fix me
  // pegas as logicas de acordo com o novo get estate Detail
  // por enquanto vou deixar voltando o que veio da API
  function getSetBackStr(item: EstateDetail) {
    return item.setback;
    // let setback = '';
    // if (item.setbackStatus === 1) {
    //   setback = 'セットバック要';
    // } else if (item.setbackStatus === 2) {
    //   setback = 'なし';
    // }
    // if (item.setbackSpineSQM) {
    //   setback += ` ${item.setbackSpineSQM}m²`;
    // }
    // if (item.setbackSpineSQM && item.setbackAlreadyFlag) {
    //   setback += '/';
    // }
    // if (item.setbackAlreadyFlag) {
    //   setback += 'セットバック済み';
    // }
    // if ((item.setbackStatus !== 0 || item.setbackAlreadyFlag) && item.splittableFlag) {
    //   setback += '/';
    // }
    // if (item.splittableFlag) {
    //   setback += '分割可';
    // }
    // return setback;
  }
  function vacatePeriodFormat(item: EstateDetail) {
    let response = '';
    if (!item.vacatePeriod || item.vacatePeriod === '190001') {
      response = formatDateString(newDate(), 'Y年M月');
    } else {
      const str1 = item.vacatePeriod.slice(0, 4);
      const str2 = item.vacatePeriod.slice(-2);
      response = `${str1}年${str2}月`;
    }
    return response;
  }

  function convertJapaneseCalendar(year: number) {
    const data = { gg: '', yy: '' };
    if (year < 1912) {
      data.gg = '明治';
      data.yy = String(year - 1867);
    } else if (year >= 1912 && year < 1926) {
      data.gg = '大正';
      data.yy = String(year - 1911);
    } else if (year >= 1926 && year < 1989) {
      data.gg = '昭和';
      data.yy = String(year - 1925);
    } else if (year >= 1989) {
      data.gg = '平成';
      data.yy = year - 1988 === 1 ? '元' : String(year - 1988);
    }
    return data;
  }

  function getPrepareStr(item: EstateDetail) {
    const data = convertJapaneseCalendar(item.prepareYear);
    let str = '';
    if (item.prepareYear && item.prepareMonth) {
      str = `${item.prepareYear
      }年（${
        data.gg
      }${data.yy
      }年）${
        item.prepareMonth
      }月`;
    }
    if (item.prepareYear && item.prepareMonth) {
      str = `${item.prepareYear
      }年（${
        data.gg
      }${data.yy
      }年）${
        item.prepareYear
      }月`;
    }
    return str;
  }

  function formatBuildCondition(item: EstateDetail) {
    let buildingConditions = '';
    if (item.buildingRestrict) {
      buildingConditions = item.buildingRestrict;
    }
    if (item.notBuild === '1' && item.type === 3) {
      buildingConditions += '\n建築不可';
    }
    return buildingConditions;
  }

  function formatNextModifyDatetime(estate: Estate) {
    const modifyDate = DateTime.fromJSDate(estate.lowModifyAt);
    const checkDate = DateTime.now().minus({ days: 14 });
    if (modifyDate < checkDate) {
      const diff = 7 - modifyDate.weekYear + DateTime.now().weekYear;
      const ret = DateTime.now().minus({ days: diff }).plus({ days: 14 });
      return ret.toFormat('y年M月d日');
    }
    return modifyDate.plus({ days: 14 }).toFormat('y年M月d日');
  }

  function splitEstateId(estateIdFull: string) {
    if (verifyShinyabinEstate(estateIdFull)) {
      const estateIdShinyabin = estateIdFull.split('-');
      return estateIdShinyabin[0];
    }
    return estateIdFull;
  }

  function getNewUsedTypeName(item: Estate) {
    if (item.type === EstateType.land) return '';
    if (item.type === EstateType.business) return '';
    if (item.stateOfUse === StateOfUse.Used) return '中古';
    return '新築';
  }

  function getUrbanPlanning(item: EstateDetail) {
    type UrbanPlanningType = '市街化区域' | '調整区域' | '非線引区域' | '区域外' | '準都市区域'
    const UrbanPlanningToKanji: Record<UrbanPlanning, UrbanPlanningType> = {
      [UrbanPlanning.urbanArea]: '市街化区域',
      [UrbanPlanning.regulatedArea]: '調整区域',
      [UrbanPlanning.unplannedArea]: '非線引区域',
      [UrbanPlanning.outsideTheArea]: '区域外',
      [UrbanPlanning.semiUrbanArea]: '準都市区域',
    };
    return item.city?.urbanPlanning ? UrbanPlanningToKanji[item.city.urbanPlanning] : '';
  }

  function getBuildCoverage(item: EstateDetail) {
    return `${item.buildingCoverage
      ? `${item.buildingCoverage}` : ''}${item.buildingCoverage && item.plotRatio ? '/' : ''}${item.plotRatio ? `${item.plotRatio}` : ''}`;
  }

  function getEstateNameWeb(estate: Estate) {
    if (estate.portalName) {
      return estate.portalName;
    }
    if (estate.type === EstateType.land) {
      return `${estate.city.name ? estate.city.name : ''}${estate.town.name ? estate.town.name : ''}の土地`;
    }
    if (estate.type === EstateType.house && estate.stateOfUse === StateOfUse.New) {
      return `${estate.city.name ? estate.city.name : ''}${estate.town.name ? estate.town.name : ''}の新築一戸建て`;
    }
    if (estate.type === EstateType.house && estate.stateOfUse === StateOfUse.Used) {
      return `${estate.city.name ? estate.city.name : ''}${estate.town.name ? estate.town.name : ''}の中古一戸建て`;
    }
    if (estate.type === EstateType.apartment && estate.stateOfUse === StateOfUse.New) {
      return `${estate.ad.name ? estate.ad.name : ''} ${estate.city.name ? estate.city.name : ''}${estate.town.name ? estate.town.name : ''}の新築マンション`;
    }
    if (estate.type === EstateType.apartment && estate.stateOfUse === StateOfUse.Used) {
      return `${estate.ad.name ? estate.ad.name : ''} ${estate.city.name ? estate.city.name : ''}${estate.town.name ? estate.town.name : ''}の中古マンション`;
    }
    if (estate.type === EstateType.business && !estate.rentFlag) {
      return `${estate.city.name ? estate.city.name : ''}${estate.town.name ? estate.town.name : ''}の事業用物件`;
    }
    if (estate.type === EstateType.business && estate.rentFlag) {
      return `${estate.city.name ? estate.city.name : ''}${estate.town.name ? estate.town.name : ''}の収益物件`;
    }
    return estate.name;
  }

  function formatToJapaneseCompletionYearMonthWithEmperorYear(estate: Estate) {
    if (estate.building.completionType === ConstructionCompletionType.CompletionDateUnknown) {
      return '完成年月不詳';
    }

    const completionMonth = estate.building.builtAt ? String(estate.building.builtAt.getMonth() + 1).padStart(2, '0') : undefined;
    const completionYear = estate.building.builtAt ? estate.building.builtAt.getFullYear() : undefined;
    if (estate.building.builtAt && completionMonth && completionYear) {
      if (estate.building.builtAt >= new Date('2019-05-01')) {
        return `${completionYear}年(令和${completionYear - 2018}年)${completionMonth
        }月${estate.building.completionType === ConstructionCompletionType.ScheduledCompletion ? '予定' : '築'}`;
      }

      if (estate.building.builtAt >= new Date('1989-01-08')) {
        return `${completionYear}年(平成${completionYear - 1988}年)${completionMonth
        }月${estate.building.completionType === ConstructionCompletionType.ScheduledCompletion ? '予定' : '築'}`;
      }

      if (estate.building.builtAt >= new Date('1926-12-25')) {
        return `${completionYear}年(昭和${completionYear - 1925}年)${completionMonth
        }月${estate.building.completionType === ConstructionCompletionType.ScheduledCompletion ? '予定' : '築'}`;
      }

      if (estate.building.builtAt >= new Date('1912-07-30')) {
        return `${completionYear}年(大正${completionYear - 1911}年)${completionMonth
        }月${estate.building.completionType === ConstructionCompletionType.ScheduledCompletion ? '予定' : '築'}`;
      }

      if (estate.building.builtAt >= new Date('1868-01-25')) {
        return `${completionYear}年(明治${completionYear - 1867}年)${completionMonth
        }月${estate.building.completionType === ConstructionCompletionType.ScheduledCompletion ? '予定' : '築'}`;
      }

      if (estate.building.builtAt >= new Date('1865-04-07')) {
        return `${completionYear}年(慶応${completionYear - 1864}年)${completionMonth
        }月${estate.building.completionType === ConstructionCompletionType.ScheduledCompletion ? '予定' : '築'}`;
      }
    }

    return '';
  }

  function formatToJapaneseCompletionYearMonth(estate: Estate) {
    if (estate.building.completionType === ConstructionCompletionType.CompletionDateUnknown) {
      return '完成年月不詳';
    }
    const completionMonth = estate.building.builtAt ? String(estate.building.builtAt.getMonth() + 1).padStart(2, '0') : undefined;
    const completionYear = estate.building.builtAt ? estate.building.builtAt.getFullYear() : undefined;
    if (estate.building.builtAt && completionMonth && completionYear) {
      return `${completionYear}年${completionMonth}月${estate.building.completionType === ConstructionCompletionType.ScheduledCompletion ? '予定' : '築'}`;
    }
    return '';
  }

  function calculateFloorSpaceMansion(estate: Estate) {
    const floorSpaceMansion = estate.floorSpaceMansion || 0;

    if (floorSpaceMansion) {
      return `${estate.areas.floor.area.measureType
        ? `${wallMeasureMethodToString[estate.areas.floor.area.measureType]} ` : ''}${floorSpaceMansion}㎡`;
    }
    return '';
  }

  function truncate(num: number, places: number) {
    return Math.trunc(num * 10 ** places) / 10 ** places;
  }

  function ceil(num: number, places: number) {
    return Math.ceil(num * 10 ** places) / 10 ** places;
  }

  function calculateLandSpaceM2Public(estate: Estate) {
    if (!estate.land.area) return '';
    const landSpaceTypeName = estate.land.publicAreaType ? publicAreaToString[estate.land.publicAreaType] : '';
    const floorSpaceTotal = `${truncate(estate.land.area, 2).toFixed(2)}㎡(${truncate((estate.land.area * 0.3025), 2).toFixed(2)}坪)`;
    return landSpaceTypeName + floorSpaceTotal;
  }

  function addThousandsSeparators(n: number | string): string {
    return new Intl.NumberFormat("ja-JP").format(Number(n));
  }

  function convertPrice(value: number) {
    return `${addThousandsSeparators(Math.floor(value / 10000))}`;
  }

  function formatCondominiumReferencePointText(estateDetailImage: EstateDetailImage, condominiumReferencePoint: { distanceMeters: { min: number, max: number }, walkingMinutes: { min: number, max: number } }) {
    let referencePointImage = "";
    if (estateDetailImage.title) {
      referencePointImage = referencePointImage.concat(estateDetailImage.title);
      referencePointImage = referencePointImage.concat(' 徒歩');
      if (condominiumReferencePoint.walkingMinutes.min || condominiumReferencePoint.walkingMinutes.max) {
        referencePointImage = referencePointImage.concat(`${condominiumReferencePoint.walkingMinutes.min}分`);

        if (condominiumReferencePoint.walkingMinutes.min !== condominiumReferencePoint.walkingMinutes.max) {
          referencePointImage = referencePointImage.concat('～');
          referencePointImage = referencePointImage.concat(`${condominiumReferencePoint.walkingMinutes.max}分`);
        }
      }

      if (condominiumReferencePoint.distanceMeters.min || condominiumReferencePoint.distanceMeters.max) {
        referencePointImage = referencePointImage.concat(' (約');
        referencePointImage = referencePointImage.concat(`${condominiumReferencePoint.distanceMeters.min}m`);
        if (condominiumReferencePoint.distanceMeters.max !== condominiumReferencePoint.distanceMeters.min) {
          referencePointImage = referencePointImage.concat('～');
          referencePointImage = referencePointImage.concat(`${condominiumReferencePoint.distanceMeters.max}m`);
        }
        referencePointImage = referencePointImage.concat(')')
      }
    }
    return referencePointImage;
  }

  const distributionNameObjectMap: Record<number, string> = {
    1: '公益財団法人東日本不動産流通機構',
    2: '公益財団法人東日本不動産流通機構',
    3: '公益財団法人東日本不動産流通機構',
    4: '公益財団法人東日本不動産流通機構',
    5: '公益財団法人東日本不動産流通機構',
    6: '公益財団法人東日本不動産流通機構',
    7: '公益財団法人東日本不動産流通機構',
    8: '公益財団法人東日本不動産流通機構',
    9: '公益財団法人東日本不動産流通機構',
    10: '公益財団法人東日本不動産流通機構',
    11: '公益財団法人東日本不動産流通機構',
    12: '公益財団法人東日本不動産流通機構',
    13: '公益財団法人東日本不動産流通機構',
    14: '公益財団法人東日本不動産流通機構',
    15: '公益財団法人東日本不動産流通機構',
    16: '公益社団法人中部圏不動産流通機構',
    17: '公益社団法人中部圏不動産流通機構',
    18: '公益社団法人中部圏不動産流通機構',
    19: '公益財団法人東日本不動産流通機構',
    20: '公益財団法人東日本不動産流通機構',
    21: '公益社団法人中部圏不動産流通機構',
    22: '公益社団法人中部圏不動産流通機構',
    23: '公益社団法人中部圏不動産流通機構',
    24: '公益社団法人中部圏不動産流通機構',
    25: '公益社団法人近畿圏不動産流通機構',
    26: '公益社団法人近畿圏不動産流通機構',
    27: '公益社団法人近畿圏不動産流通機構',
    28: '公益社団法人近畿圏不動産流通機構',
    29: '公益社団法人近畿圏不動産流通機構',
    30: '公益社団法人近畿圏不動産流通機構',
    31: '公益社団法人西日本不動産流通機構',
    32: '公益社団法人西日本不動産流通機構',
    33: '公益社団法人西日本不動産流通機構',
    34: '公益社団法人西日本不動産流通機構',
    35: '公益社団法人西日本不動産流通機構',
    36: '公益社団法人西日本不動産流通機構',
    37: '公益社団法人西日本不動産流通機構',
    38: '公益社団法人西日本不動産流通機構',
    39: '公益社団法人西日本不動産流通機構',
    40: '公益社団法人西日本不動産流通機構',
    41: '公益社団法人西日本不動産流通機構',
    42: '公益社団法人西日本不動産流通機構',
    43: '公益社団法人西日本不動産流通機構',
    44: '公益社団法人西日本不動産流通機構',
    45: '公益社団法人西日本不動産流通機構',
    46: '公益社団法人西日本不動産流通機構',
    47: '公益社団法人西日本不動産流通機構'
  }

  return {
    title: computed(() => (estateRef ? getTitle(unref(estateRef)) : '')),
    outlineTitle: computed(() => (estateRef ? getOutLineTitle(unref(estateRef)) : '')),
    constructionDate: computed(() => (estateRef ? formatConstructionDate(unref(estateRef)) : '')),
    completionDate: computed(() => (estateRef ? formatCompletionDate(unref(estateRef)) : '')),
    floor: computed(() => (estateRef ? formatFloor(unref(estateRef)) : '')),
    landSpaceM2Public: computed(() => (estateRef ? formatLandSpaceM2Public(unref(estateRef)) : '')),
    yearlyIncome: computed(() => (estateRef ? formatYearlyIncome(unref(estateRef)) : '')),
    buildingRate: computed(() => (estateRef ? formatBuildingRate(unref(estateRef)) : '')),
    access: computed(() => (estateRef ? formatAccess(unref(estateRef).access[0]) : '')),
    accessEtc: computed(() => (estateRef ? formatAccessEtc(unref(estateRef).accessEtc) : '')),
    elementarySchool: computed(() => (estateRef ? formatElementarySchool(unref(estateRef).elementarySchool) : '')),
    detailImage: computed(() => (estateRef ? getDetailImage(unref(estateRef).id) : '')),

    formatTransport,
    formatAccess,
    getNewOrPriceDownStr,
    formatSenyoSpaceMansion,
    formatModifyDatetime,
    getOutLineTitle,
    formatCompletionDate,
    formatLandSpaceM2Public,
    getProfileImage,
    getProfileImageFromPath,
    getTitle,
    formatAccessEtc,
    getDetailImage,
    formatFloor,
    formatYearlyIncome,
    isSold,
    isNew,
    isPriceDown,
    formatConstructionDate,
    formatElementarySchool,
    formatBuildingRate,
    createEstateImgUrl,
    createFcShopImageURL,
    createLargeFcShopImageURL,
    isNewEstateForEstateDetail,
    verifyShinyabinEstate,
    convertHouseDoName,
    otherSpaceFormat,
    parkingStr,
    convertTypeName,
    formatCompletionStr,
    formatDwelling,
    formatFloorSpaceMansion,
    getCostsStr,
    reformDataStr,
    getElementarySchoolAccessStr,
    getJuniorHighSchoolAccessStr,
    getYearlyIncomeStrCalc,
    getLandSpaceM2PublicStr,
    getFloorSpaceStr,
    getPrivateRoadStr,
    getRoadStr,
    getSetBackStr,
    vacatePeriodFormat,
    parkingStrTypeMansion,
    getPrepareStr,
    formatBuildCondition,
    formatNextModifyDatetime,
    splitEstateId,
    getNewUsedTypeName,
    getUrbanPlanning,
    getBuildCoverage,
    formatFloorForDetails,
    getLandLotImage,
    getOnSaleImage,
    getOnSaleImageL,
    formatTrainStation,
    getEstateNameWeb,
    formatBuildingRestriction,
    formatToJapaneseCompletionYearMonth,
    calculateFloorSpaceMansion,
    calculateLandSpaceM2Public,
    truncate,
    formatToJapaneseCompletionYearMonthWithEmperorYear,
    ceil,
    convertPrice,
    formatCondominiumReferencePointText,
    distributionNameObjectMap,
    createPRBannerURL
  };
}
