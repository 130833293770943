// eslint-disable-next-line max-classes-per-file
import { Shop } from '@/domain/fcShop.entity';
import { City } from '@/domain/city.entity';
import { Pref } from '@/domain/pref.entity';

type NullString = string | null;


// eslint-disable-next-line no-shadow
export enum EstateType {
  land = 1,
  house = 2,
  apartment = 3,
  business = 4
}

// eslint-disable-next-line no-shadow
export enum EstateStatus {
  published = '01',
  negotiatingThirdParty = '02',
  negotiation = '03',
  reserved = '04',
  soldThirdParty = '05',
  sold = '06',
  canceled = '07',
  reviewing = '08',
}

// eslint-disable-next-line no-shadow
export enum SetbackStatus {
  requiredSetback = 1, // Todo provisional name(Need to review the meaning of this status)
  notRequiredSetback = 2, // Todo provisional name(Need to review the meaning of this status)
  uninformed = 0, // Todo provisional name(Need to review the meaning of this status)
}

// eslint-disable-next-line no-shadow
export enum AccessPriority {
  WalkingToTrainStation = 1,
  BusToTrainStationAndWalkToBusStation = 2,
  BusToTrainStation = 3,
}

// eslint-disable-next-line no-shadow
export enum GarageAvailability {
  Available = 'AVAILABLE', // 空有
  NoVacancy = 'NO_VACANCY', // 空無
  HasNearVacancy = 'HAS_NEAR_VACANCY', // 近有
}

// eslint-disable-next-line no-shadow
export enum GarageType {
  hasVacancy = '01',
  noVacancy = '02',
  hasNearVacancy = '03',
  noGarage = '04',
}

// eslint-disable-next-line no-shadow
export enum VacateType {
  noRegistered = '01', // Todo provisional name(Need to review the meaning of this status)
  canRegisterImmediate = '02', // Todo provisional name(Need to review the meaning of this status)
  needConsultation = '03', // Todo provisional name(Need to review the meaning of this status)
  needRegisterDate = '04', // Todo provisional name(Need to review the meaning of this status)
}

// eslint-disable-next-line no-shadow
export enum UrbanPlanning {
  urbanArea = '01',
  regulatedArea = '02',
  unplannedArea = '03',
  outsideTheArea = '04',
  semiUrbanArea = '05'
}

// eslint-disable-next-line no-shadow
export enum Ownership { // 一括・一部種別
  Full = '01', // 一括（建物一括所有）
  Partial = '02', // 一部（区分所有）
}

// eslint-disable-next-line no-shadow
export enum StateOfUse { // 新築・中古種別
  New = '01', // 新築
  Used = '02', // 中古
}

// eslint-disable-next-line no-shadow
export enum BuildingRestriction { // 建築条件種別ID
  BuildingType = 'BUILDING_TYPE', // 建築条件付
  CleaningLand = 'CLEANING_LAND', // 更地渡し(建築条件なし)
  StateTransfer = 'STATE_TRANSFER', // 現況渡し(建築条件なし)
  Consultation = 'CONSULTATION', // 相談
  OwnerChange = 'OWNER_CHANGE'// オーナーチェンジ
}

// eslint-disable-next-line no-shadow
export enum EstateDetailImageType { // Estate Detail Images / Division Group Id
  LayoutImage = '00', // 間取り
  External = '01', // 外観
  Internal = '02', // 内観
  EquipmentsAndFacilities = '03', // 設備
  NearbyPlaces = '04', // 周辺環境
  BuildingPlanExamples = '05', // 建物プラン例
  Structure = '06', // 構造
  Access = '07' // アクセス
}

// eslint-disable-next-line no-shadow
export enum EstateMapTypes { // Division Id
  LocalGuide = '6000', // 現地案内図
  TransportationAccess = '6010', // 交通アクセス図
  RouteMap = '6020', // 路線図
  EnergySaving = '9950' // 省エネ
}

// eslint-disable-next-line no-shadow
export enum EventType {
  OpenHouse = 1,
  Event = 2,
}

// eslint-disable-next-line no-shadow
export enum StructureType {
  Wood = 1,
  Blocks = 2,
  Steel = 3,
  ReinforcedConcrete = 4,
  SteelReinforcedConcrete = 5,
  LightWeightSteel = 6,
}

// eslint-disable-next-line no-shadow
export enum LocomotionType {
  Walk = 'WALK',
  Car = 'CAR',
  Bus = 'BUS',
}

export type Access = {
  type: LocomotionType.Bus;
  distance: number | null;
  time: number | null;
  busStopName: string;
  busLineName: string;
  walkTime: number | null;
} | {
  type: LocomotionType.Car | LocomotionType.Walk;
  distance: number | null;
  time: number | null;
}

// eslint-disable-next-line no-shadow
export enum ConstructionCompletionType { // 建物種別
  ScheduledCompletion = 'SCHEDULED_COMPLETION', // 完成予定
  ConstructionCompleted = 'CONSTRUCTION_COMPLETED', // 築
  CompletionDateUnknown = 'COMPLETION_DATE_UNKNOWN', // 完成年月不詳
}

// eslint-disable-next-line no-shadow
export enum MeasureType {
  BetweenCenterWall = '01',
  BetweenSurfaceWall = '02',
}

// eslint-disable-next-line no-shadow
export enum LandAreaType { // 土地面積種別ID
  Public = 'PUBLIC',
  Actual = 'ACTUAL',
}

// eslint-disable-next-line no-shadow
export enum EstateOwnership { // 一括・一部種別
  Full = 'FULL', // 一括（建物一括所有）
  Partial = 'PARTIAL', // 一部（区分所有）
}

// eslint-disable-next-line no-shadow
export enum Direction { // 方角
  North = 'NORTH', // 北
  South = 'SOUTH', // 南
  East = 'EAST', // 東
  West = 'WEST', // 西
  Northwest = 'NORTHWEST', // 北西
  Northeast = 'NORTHEAST', // 北東
  Southwest = 'SOUTHWEST', // 南西
  Southeast = 'SOUTHEAST' // 南東
}

// eslint-disable-next-line no-shadow
export enum LandShape {
  Square = 1,
  AlmostSquare = 2,
  Rectangle = 3,
  AlmostRectangle = 4,
  Trapezoid = 5,
  AlmostTrapezoid = 6,
  Irregular = 7,
  AlmostSymmetrical = 8,
  Other = 9,
}

// eslint-disable-next-line no-shadow
export enum RoadType {
  Public = 1,
  Private = 2,
}


// eslint-disable-next-line no-shadow
export enum TradeTransactionType {
  Seller= 'SELLER', // 売主
  Agent= 'AGENT', // 代理
  Exclusive='EXCLUSIVE', // 専任
  General= 'GENERAL', // 一般
  ExclusiveSale= 'EXCLUSIVE_SALE', // 専属専任
  Mediation= 'MEDIATION' // 媒介
}

// eslint-disable-next-line no-shadow
export enum RoomLayoutType {
  'DK' = 'DK',
  'LDK' = 'LDK',
  'R' = 'R',
  'K' = 'K',
  'K+S' = 'K+S',
  'DK+S' = 'DK+S',
  'LK' = 'LK',
  'LK+S' = 'LK+S',
  'LDK+S' = 'LDK+S',
}

// eslint-disable-next-line no-shadow
export enum ManagementModeType {
  SELF_MANAGEMENT = "01",
  PARTIAL_MANAGEMENT = "02",
  FULL_DELEGATION = "03"
}

// eslint-disable-next-line no-shadow
export enum PrivateRoadType {
  NONE = "01",
  SEPARATE = "02",
  INCLUDED = "03"
}

// eslint-disable-next-line no-shadow
export enum EstateTypeLiteral {
  LAND_FOR_SALE = "0101",
  LAND_LEASEHOLD_TRANSFER = "0102",
  LAND_SUB_LEASEHOLD_TRANSFER = "0103",
  DETACHED_HOUSE = "0201",
  TERRACED_HOUSE = "0202",
  CONDOMINIUM = "0301",
  PUBLIC_HOUSING = "0302",
  PUBLIC_CORPORATION = "0303",
  TOWNHOUSE = "0304",
  RESORT_CONDOMINIUM = "0305",
  STORE = "0401",
  STORE_WITH_RESIDENCE = "0402",
  RESIDENCE_WITH_STORE = "0403",
  OFFICE = "0404",
  STORE_AND_OFFICE = "0405",
  BUILDING = "0406",
  FACTORY = "0407",
  MANSION = "0408",
  WAREHOUSE = "0409",
  APARTMENT = "0410",
  DORMITORY = "0411",
  INN = "0412",
  HOTEL = "0413",
  VILLA = "0414",
  RESORT_MANSION = "0415",
  MOTEL = "0416",
  CLINIC = "0417",
  GAS_STATION = "0418",
  SPECIAL_BATHHOUSE = "0419",
  SAUNA = "0420",
  RECREATION_FACILITY = "0421",
  WORKSHOP = "0422",
  PARKING_LOT = "0423",
  OTHER = "0424"
}


export interface Estate {
  id: number,
  name: string,
  portalName: string,
  statusId: EstateStatus,
  type: EstateType,
  realTypeName: string,
  catch: string,
  structureName: string,
  price: number,
  priceInTenThousand?: string,
  formattedPrice?: string,
  formattedPriceUnit?: string,
  room: {
    layoutName: string,
    quantity: number | string,
  }
  priceDownFlag: boolean,
  floor: number,
  buildingTotalFloor: number,
  newUsedTypeName: string,
  rentFlag: boolean,
  stateOfUse: StateOfUse | null,
  ad: {
    name: string,
    web: string,
  }
  totalSpace: number,
  totalFloorSpace: string,
  totalMansionSpace: string,
  totalPublicLandSpace: string,
  landPublicM2Space: number | string,
  floorSpaceMansion: number | string,
  tsubo: number | string,
  tsuboTanka: number | string,
  buildingAreaRadio: number | string,
  utilAreaRadio: string,
  buildingRestrictTypeName?: string,
  estatesUses?: string[],
  businessTypeName: string,
  isInHomepage: boolean,

  completionYear: number,
  completionMonth: number,

  address: string,
  fcShop: Pick<Shop, 'id'>,
  yieldRate: number,

  createdAt: Date,
  lowModifyAt: Date,

  walkMinutesEtc: string,
  trafficEtc: string,
  isSold: boolean,

  district?: {
    name: string,
    date?: Date,
  },

  access: {
    stationName: string,
    railName: string,
    busMinutes: number,
    busStopName: string,
    walkMinutes: number,
    stationAccess?: string,
    priority: AccessPriority | null
  }[]
  accessEtc: {
    traffic: string,
    walkMinutes: number,
    priority?: string
  }

  user: {
    name: string,
  }
  pref: {
    id: number,
    name: string
  }
  city: {
    id: number,
    name: string,
    urbanPlanning?: UrbanPlanning
  }
  town: {
    name: string
  }

  juniorHighSchool: {
    name: string,
    nameWithDistance?: string
  }
  elementarySchool?: {
    name: string,
    distance: number,
    nameWithDistance?: string
  }
  fc: {
    id: number
  },
  salesman?: {
    id: number,
    name: string,
    prHp: string,
    profileImage: string,
  },

  // TODO so adicionei para fazer o deploy
  estateTypeDetail?: string,
  setback?: string,
  roofbalcony?: string,
  floorString: string,
  spaceDetail: string,
  structure: string,
  floorQuantityString: string,

  building: {
    completionType: ConstructionCompletionType | null,
    builtAt: Date | null,
  },

  // TODO a data ja esta vindo formatada
  adModifyDate?: string,

  adNextModifyDate?: string,
  balcony?: string,
  mainLighting?: string,

  land: {
    area: number,
    publicAreaType: LandAreaType | null,
    restriction: {
      type: BuildingRestriction | null,
      other: string,
      suumo: string, // Suumo it is a site that sell houses in Japan
    } | null,
  },

  garages: {
    car: {
      availability: GarageAvailability,
      price: number | { min: number, max: number } | null,
      distance: number,
      quantity: { normal: number, mini: number } | null,
    } | null,
  },

  surroundings: {
    trainStations: Array<{
      id: number,
      name: string,
      rail: { id: number; name: string; } | null,
      access: Access | null,
    } | {
      name: string,
      rail: { name: string } | null,
      access: Access | null,
    }>
  }
  images: {
    layout: string[],
    exterior: string[]
  }

  areas: {
    floor: {
      area: { value: number, measureType: MeasureType | null },
      buildAreaType: LandAreaType | null,
    }
  }

  rights: {
    ownership: EstateOwnership | null,
  }

  // Todo verify how to implement this correctly
  sellContract: {
    sellerId: number | null,
    sellerPassword: string | null,
    sellEstateId: number | null,
    transactionTypeId: TradeTransactionType | null,
    sellerRegisterDate: Date | null,
    mediateContractRegisterDate: Date | null,
    finalMediationContractDate: Date | null,
    renewalMediationContractDate: Date | null,
  },
}

export enum AdditionalPRType  {
  Banner = 0,
  RicohTheta= 1,
  YouTube = 2,
}

export interface EstateDetail extends Estate {
  myPageFlag?: boolean

  fcShopTel?: string
  fcShop: {
    id: number,
    name: string,
    tel?: string,
  },
  ohEventId?: number

  realRegisterDatetime?: string
  landLotId: number,

  exteriorImages?: string[]

  latitude: number
  longitude: number

  portalMapPinFlag: boolean
  mapAddress: string
  reformFlag: boolean
  roofBalconyFlag: boolean
  renovationFlag: boolean
  lowModifyDatetime: string
  cableBroadcasting: string
  packagePartTypeId?: string
  businessFlag: boolean
  floorAboveQuantity: string
  typeId: string
  dwellingQuantity: number
  dwellingQuantityBiko: number
  floorSpaceMansionUpper: number
  balconyDirectionName: string
  situationName: string
  optimalDistrictName: string
  balconyDirectionId?: string
  balconySpace?: string
  balconySpaceAroundFlag: boolean
  roofBalconySpace?: string
  roofBalconyPrice: number
  onlyGardenFlag: boolean
  onlyGardenSpace?: string
  onlyGardenPrice: number
  terraceFlag: boolean
  terraceSpace?: string
  terracePrice: number
  wideBalcony: string
  balcony2: string
  balcony3: string
  woodDeck: string
  garageTypeId: string | null
  parking2Flag: boolean
  openParkingFlag: boolean
  parkingLotFlag: boolean
  parkingFlag: boolean
  parkingText: string
  parkingQuantity: string
  garageFlag: boolean
  garagePriceLower: number
  garagePriceUpper: number
  garagePrice: number
  totalEstateQuantity: number | string
  repairCost: number | null
  managementModeTypeName?: string
  costResidenceFlag: boolean
  costResidenceText: string
  costDayShiftFlag: boolean
  costDayShiftText: string
  costRoutineFlag: boolean
  costRoutineText: string
  landRightTypeName: string
  vacateTypeId: string
  buildingCertificationCode: string
  renovationYear: string
  renovationMonth: string
  reformExecutionWaterYear: string
  reformExecutionWaterMonth: string
  reformExecutionKitchen: string
  reformExecutionBath: string
  reformExecutionToilet: string
  reformExecutionWashstand: string
  reformExecutionHotWaterDispenser: string
  reformExecutionPlumbing: string
  reformExecutionWaterOther: string
  reformExecutionInYear: string
  reformExecutionInMonth: string
  reformExecutionWall: string
  reformExecutionFloor: string
  reformExecutionWhole: string
  reformExecutionFitting: string
  reformExecutionSash: string
  reformExecutionOther: string
  reformExecutionOutYear: string
  reformExecutionOutMonth: string
  reformExecutionOutWall: string
  reformExecutionOutRoof: string
  reformExecutionOutLarge: string
  reformExecutionOutOther: string
  reformExecutionBiko: string
  juniorHighSchoolDistance: number
  juniorHighSchoolName: string
  tradeTransactionTypeName: string
  floorSpaceTotal: number | string
  floorSpace1F: number | string
  floorSpace2F: number | string
  floorSpace3F: number | string
  floorSpaceETC: number | string
  buildingCoverage: number | string
  plotRatio: number | string
  useDistrictName: string
  landCategoryName?: string
  privateRoadTypeId: number
  privateRoadSpace: number | string
  privateRoadTypeName?: string
  privateRoadRateText?: string
  privateRoadRateSpace: number
  roadDirectionNames: string[]
  roadTypeNames: string[]
  roadSpans: string[]
  roadFrontSpans: string[]
  setbackStatus: SetbackStatus
  setbackSpineSQM: number
  setbackAlreadyFlag: boolean
  splittableFlag: boolean
  vacateTypeName: string
  vacatePeriod: string
  controlCost: number | null
  biko: string
  pr: string,
  additionalPR: {
    type: AdditionalPRType,
    url?: string,
    bannerName: string | null,
  },
  prepareYear: number
  prepareMonth: number
  notBuild: string
  mainLighting: string
  balcony: string
  road: string[],
  licenseNumber: string,
  vacate: string
  managementModeType: string
  structure: string
  landRight: string
  managementCompany: string
  executionCompany: string
  buildingRestrict: string
  constructionMethod: string
  topography: string
  rebuild: string
  lowsNote: string
  tradeTransactionType: string
  situation: string
  landCategory: string
  reformExecutionOtherContentDetail: string
  siteArea: string
  ownership: Ownership | null

  // TODO
  setback: string
  // Todo Fix the following fields to the right format and name
  estateAddressDisp: string
  typeDisplay: string
  typeDetail: string
  parking: string
  specialFeatures: string | null
  constructionCompleteDate: string
  couponYield: string
  assumptionCouponYield: string
  spaceTsubo: string
  spaceDetail: string
  completionYearMonthLocal: string
  floorQuantity: string
  onlyGarden: string
  terrace: string
  insurance: {
    earthquake: string,
    earthquakeBiko: string,
    policy: string,
    policyCover: string,
    policyCoverDetail: string,
    warranty: string,
    warrantyCover: string,
    warrantyCoverDetail: string,
    invoice: string,
  }
  stateOfUse: StateOfUse
  reform: {
    executionWaterYearMonth: string,
    executionWaterContents: string,
    executionWaterContentsDetail: string,
    executionOutYearMonth: string,
    executionOutContents: string,
    executionOutContentsDetails: string,
    executionInYearMonth: string,
    executionInContents: string,
    executionInContentsDetails: string,
    executionCommonYearMonth: string,
    executionCommonContentDetail: string,
    executionOtherYearMonth: string
    executionOtherContentDetail: string
  }
  landLot: {
    totalSalesHouse: string,
    minMaxPrice: string,
    minMaxPriceFormatted: string,
    majorityPrice: string,
    majorityPriceFormatted: string,
    minMaxArea: string,
    minMaxFloorSpaceTotal: string,
    minMaxControlCost: string,
    minMaxRepairCost: string
  }
  landTsubo: string
  landInsurance: string
  landNarrowPathway: string
  landKoatsusen: string
  landEquity: string
  setBack: string
  realPropertyRight: string
  rentedGround: string
  termLeasehold: string
  termLeaseholdRegistration: string
  termLeaseholdRentedPrice: string
  buildingPermitReason: string
  landPlanning: string
  licenceNumber: string
  conversionLicenseNumber: string
  build: string
  oneTimePayment: string
  runningCost: string
  neighborhoodAssociation: string
  hotSpring: string
  hotSpringDetail: string
  internetConnection: string
  cableTv: string
  burdenFee: string
  burdenFeeRemark: string
  imageTitle: string
  localWalkDistance: string
  floorSpaceApartment: string
  floorMansion: string
  costs: string
  organizationManagement: string
  parkingApartment: string
  parkingStructure: string
  parkingMotorcycles: string
  parkingBicycles: string
  parkingCondominium: string
  parkingCondominiumFund: string
  optimalDistrict: string
  buildingId: number

  equipment: {
    bath: string
    kitchen: string
    wash: string
    lifeLine: string
    security: string
    other: string
    room: string
  }
  constructionAndStructureMethod: string

  floorSpace: {
    firstFloor: string
    secondFloor: string
    thirdFloor: string
    floorEtc: string
  }
  addressMap: string,
  hasVr: boolean,
  has360Vr: boolean,
}

export interface ApartmentDetails {
  buildingId: number | null,
  estateStatus: string,
  estateZipCode: string,
  estateAddress: string,
  adModifyDate: string,
  adNextModifyDate: string,
  tradeTermExpireDate: string,
  access: {
    railName: string,
    stationName: string,
    stationAccess: string,
  }[],
  exteriorImage: string,
  biko: string,
  latitude: number,
  longitude: number,
  totalSalesHouse: string,
  totalEstateQuantity: string,
  apartmentPriceRange: string,
  apartmentPriceRangeFormatted: string,
  minEstatePrice: string,
  maxEstatePrice: string,
  apartmentMajorityPrice: string,
  apartmentMajorityPriceFormatted: string,
  apartmentRangeArea: string,
  managementTaxRange: string,
  repairCostRange: string,
  burdenTax: string,
  burdenTaxDetail: string,
  cityPlanning: string,
  buildingPermitReason: string,
  useDistrict: string,
  buildingCoverage: string,
  plotRatio: string,
  topography: string,
  licenceNumber: string,
  conversionLicenseNumber: string,
  elementarySchool: string,
  juniorHighSchool: string,
  lowsNote: string,
  tradeTransaction: string,
  shop: {
    fcName: string,
    name: string,
    mainTel: string,
    zipCode: string,
    address: string,
    businessHour: string,
    dayOff: string,
    licenseNumber: string,
    image: string,
    mapImage: string,
  }
}

export interface HouseAndLandDetails {
  landLot: {
    id: number,
    name: string,
    priceRange: string,
    priceRangeFormatted: string,
    majorityPrice: string,
    majorityPriceFormatted: string,
    areaRange: string,
    floorSpace: string,
  },
  estate: {
    status: string,
    zipCode: string,
    addressList: string,
    addressDisplay: string,
    addressMap: string,
    minPrice: string,
    maxPrice: string,
  },
  adModifyDate: string,
  adNextModifyDate: string,
  tradeTermExpireDate: string,
  access: {
    railName: string,
    stationName: string,
    stationAccess: string,
  }[],
  landLotImage: string,
  mapImage: string,
  prImage: string,
  biko: string,
  hasPinFlag: boolean,
  latitude: number,
  longitude: number,
  totalSalesHouse: string,
  totalEstateQuantity: string,
  buildingRestrictions: string,
  burdenCharge: string,
  burdenChargeRemake: string,
  cityPlanning: string,
  buildingPermitReason: string,
  useDistrict: string,
  buildingCoverage: string,
  plotRatio: string,
  optimalDistrictName: string,
  topography: string,
  licenceNumber: string,
  conversionLicenseNumber: string,
  landPlanning: string,
  elementarySchool: string,
  juniorHighSchool: string,
  lowsNote: string,
  equipment: string,
  note: string,
  tradeTransaction: string,
  shop: {
    fcName: string,
    name: string,
    mainTel: string,
    hasFreeDialIcon: boolean,
    zipCode: string,
    address: string,
    tel: string,
    fax: string,
    mail: string,
    access: string[],
    parking: string,
    businessHour: string,
    holiday: string,
    manager: string,
    licenseNumber: string,
    joinGroup: string[],
    image: string[],
    mapImage: string,
    latitude: number,
    longitude: number,
  },
  restrictEstateCountTotal: number,
  restrictEstateCountSales: number,
  restrictMinEstatePrice: number,
  restrictMaxEstatePrice: number,
  restrictLandLotPriceRange: string,
  restrictLandLotPriceRangeFormatted: string,
  restrictLandLotAreaRange: string,
  restrictLandLotTsubo: string,
  nonRestrictEstateCountTotal: number,
  nonRestrictEstateCountSales: number,
  nonRestrictMinEstatePrice: number,
  nonRestrictMaxEstatePrice: number,
  nonRestrictLandLotPriceRange: string,
  nonRestrictLandLotPriceRangeFormatted: string,
  nonRestrictLandLotAreaRange: string,
  nonRestrictLandLotTsubo: string,
  buildingEstateTotalCount: number,
  buildingEstateSalesCount: number,
  buildingEstateMinPrice: number,
  buildingEstateMaxPrice: number,
  buildingLandLotPriceRange: string,
  buildingLandLotPriceRangeFormatted: string,
  buildingLandLotAreaRange: string,
  buildingLandLotTsuboRange: string,
  buildingLandLotFloorSpace: string,
  buildingLandLotTsubo: string,
}

export interface EstateEquipments {
  estateId: number,
  equipment: {
    room: string,
    bath: string,
    kitchen: string,
    wash: string,
    lifeline: string,
    security: string,
    other: string,
  },
  special: string,
  lifelineDetails: string,
  equipmentDetails: string,
}

export interface ILandLotEstate {
  id: number,
  name: string,
  statusId: EstateStatus,
  landLotNumber: number | null;
  price: number,
  totalFloorArea: number
  landArea: number
  type: EstateType,
  fcShop: {
    id: number,
    name: string,
  }
}

export class LandLotEstate implements ILandLotEstate {
  id!: number;

  landLotNumber!: number | null;

  name!: string;

  statusId!: EstateStatus;

  price!: number;

  type!: EstateType;

  totalFloorArea!: number;

  landArea!: number;

  fcShop!: {
    id: number,
    name: string,
  };

  constructor(props: ILandLotEstate) {
    Object.assign(this, props);
  }

  get isSold() {
    return [EstateStatus.sold, EstateStatus.soldThirdParty].includes(this.statusId);
  }

  get isInNegotiation() {
    return [EstateStatus.negotiation, EstateStatus.negotiatingThirdParty].includes(this.statusId);
  }

  get totalFloorAreaTsubo() {
    return Math.floor(this.totalFloorArea * 30.25) / 100;
  }

  get landAreaTsubo() {
    return Math.floor(this.landArea * 30.25) / 100;
  }
}

export interface ILandLot {
  id: number,
  imageUrl: string,
  isSold: boolean;
  city: Pick<City, 'id' | 'name'>,
  pref: Pick<Pref, 'id' | 'name'>,
  estates: LandLotEstate[];
}

export class LandLot implements ILandLot {
  city!: Pick<City, 'id' | 'name'>;

  estates!: LandLotEstate[];

  id!: number;

  imageUrl!: string;

  isSold!: boolean;

  pref!: Pick<Pref, 'id' | 'name'>;

  constructor(props: ILandLot) {
    Object.assign(this, props);
  }
}
